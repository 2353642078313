import React from 'react';

import Modal from 'lib/src/components/modal/Modal';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import ActionButton from 'lib/src/components/button/ActionButton';

const ErrorModal = ({ error, closeModal }) => (
    <Modal title="There was an error" closeModal={closeModal}>
        <div className="wysiwyg">
            <p>{error}</p>
        </div>

        <ButtonRow alignment="right">
            <ActionButton color="grey" type="button" onClick={closeModal}>
                Close
            </ActionButton>
        </ButtonRow>
    </Modal>
);

export default ErrorModal;
