import useWindowSize from 'src/hooks/useWindowSize';
import mediaQueries from 'src/constants/mediaQueries';

import ourTeam from '@constants/ourTeam';

import TeamCarouselItem from './TeamCarouselItem';
import CarouselContainer from '@components/carousel/CarouselContainer';
import { useEffect, useState } from 'react';

const OurTeamCarousel = () => {
    const { width } = useWindowSize();
    const [visibleSlides, setVisibleSlides] = useState(4);

    useEffect(() => {
        if (width <= mediaQueries.ptTablet) {
            setVisibleSlides(1);
        } else if (width <= mediaQueries.lsTablet) {
            setVisibleSlides(2);
        } else {
            setVisibleSlides(4);
        }
    }, [width]);

    const renderItem = ({ item }) => {
        return <TeamCarouselItem teamMember={item} />;
    };

    return (
        <CarouselContainer
            items={ourTeam}
            renderItem={renderItem}
            extraContainerClass="our-team-carousel"
            extraSliderClass="our-team-slider"
            isIntrinsicHeight
            visibleSlides={visibleSlides}
        />
    );
};

export default OurTeamCarousel;
