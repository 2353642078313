import { useParams } from 'react-router-dom';

import { RAW_S3_STORAGE_URL } from 'src/config';

import useSingleWebinarFetch from './hooks/useSingleWebinarFetch';

import Banner from '@components/banner/Banner';
import DataCheck from '@components/dataCheck/DataCheck';

import WebinarSingleDetails from './WebinarSingleDetails';

const WebinarSingle = () => {
    const { id } = useParams();

    const [webinar, isFetching, error] = useSingleWebinarFetch(id);

    return (
        <>
            <DataCheck data={webinar} isFetching={isFetching} error={error}>
                <div className="container">
                    <Banner
                        title="Webinars"
                        image={`${RAW_S3_STORAGE_URL}/${
                            webinar ? webinar.headerOneImageS3Key : ''
                        }`}
                    />
                </div>

                <WebinarSingleDetails webinar={webinar} />
            </DataCheck>
        </>
    );
};

export default WebinarSingle;
