import { createReducer } from '@reduxjs/toolkit';
import { convertArrToObj } from 'lib/src/utils/generic';

import {
    fetchWebsiteCaseStudiesListRequest,
    fetchWebsiteCaseStudiesListSuccess,
    fetchWebsiteCaseStudiesListFailure,
    fetchSingleWebsiteCaseStudyFailure,
    fetchSingleWebsiteCaseStudySuccess,
    fetchSingleWebsiteCaseStudyRequest,
    fetchSolutionCaseStudiesListRequest,
    fetchSolutionCaseStudiesListSuccess,
    fetchSolutionCaseStudiesListFailure,
    fetchSingleSolutionCaseStudyRequest,
    fetchSingleSolutionCaseStudySuccess,
    fetchSingleSolutionCaseStudyFailure,
    fetchProblemCaseStudiesListRequest,
    fetchProblemCaseStudiesListSuccess,
    fetchProblemCaseStudiesListFailure,
    fetchSingleProblemCaseStudyRequest,
    fetchSingleProblemCaseStudySuccess,
    fetchSingleProblemCaseStudyFailure,
    createSolutionCaseStudyRequest,
    createSolutionCaseStudySuccess,
    createSolutionCaseStudyFailure,
    createProblemCaseStudyRequest,
    createProblemCaseStudySuccess,
    createProblemCaseStudyFailure,
    fetchUsersSolutionCaseStudiesRequest,
    fetchUsersSolutionCaseStudiesSuccess,
    fetchUsersSolutionCaseStudiesFailure,
    fetchUsersProblemCaseStudiesRequest,
    fetchUsersProblemCaseStudiesSuccess,
    fetchUsersProblemCaseStudiesFailure,
    editProblemCaseStudyRequest,
    editProblemCaseStudySuccess,
    editProblemCaseStudyFailure,
} from '@actions/caseStudies/index';
import {
    postUpdateSolutionCaseStudyFailure,
    postUpdateSolutionCaseStudyRequest,
    postUpdateSolutionCaseStudySuccess,
} from '@actions/caseStudies/postUpdateSolutionCaseStudy';

const initialState = {
    isFetching: false,
    isPosting: false,
    error: null,
    postError: null,
    postSuccess: false,
    websiteCaseStudies: {},
    solutionCaseStudies: {},
    problemCaseStudies: {},
    recentlyCreatedID: null,
    usersSolutionCaseStudies: {},
    usersProblemCaseStudies: {},
};

export default createReducer(initialState, {
    [fetchWebsiteCaseStudiesListRequest]: handleFetchRequest,
    [fetchWebsiteCaseStudiesListFailure]: handleFetchFailure,
    [fetchWebsiteCaseStudiesListSuccess]: handleFetchAllWebsiteCaseStudiesSuccess,
    [fetchSingleWebsiteCaseStudyRequest]: handleFetchRequest,
    [fetchSingleWebsiteCaseStudyFailure]: handleFetchFailure,
    [fetchSingleWebsiteCaseStudySuccess]: handleFetchSingleWebsiteCaseStudySuccess,
    [fetchSolutionCaseStudiesListRequest]: handleFetchRequest,
    [fetchSolutionCaseStudiesListSuccess]: handleFetchAllSolutionCaseStudiesSuccess,
    [fetchSolutionCaseStudiesListFailure]: handleFetchFailure,
    [fetchSingleSolutionCaseStudyRequest]: handleFetchRequest,
    [fetchSingleSolutionCaseStudySuccess]: handleFetchSingleSolutionCaseStudySuccess,
    [fetchSingleSolutionCaseStudyFailure]: handleFetchFailure,
    [fetchProblemCaseStudiesListRequest]: handleFetchRequest,
    [fetchProblemCaseStudiesListSuccess]: handleFetchAllProblemCaseStudiesSuccess,
    [fetchProblemCaseStudiesListFailure]: handleFetchFailure,
    [fetchSingleProblemCaseStudyRequest]: handleFetchRequest,
    [fetchSingleProblemCaseStudySuccess]: handleFetchSingleProblemCaseStudySuccess,
    [fetchSingleProblemCaseStudyFailure]: handleFetchFailure,
    [createSolutionCaseStudyRequest]: handlePostRequest,
    [createSolutionCaseStudySuccess]: handlePostSolutionCaseStudySuccess,
    [createSolutionCaseStudyFailure]: handlePostFailure,
    [createProblemCaseStudyRequest]: handlePostRequest,
    [createProblemCaseStudySuccess]: handlePostProblemCaseStudySuccess,
    [createProblemCaseStudyFailure]: handlePostFailure,
    [fetchUsersSolutionCaseStudiesRequest]: handleFetchRequest,
    [fetchUsersSolutionCaseStudiesSuccess]: handleFetchUsersSolutionSuccess,
    [fetchUsersSolutionCaseStudiesFailure]: handleFetchFailure,
    [fetchUsersProblemCaseStudiesRequest]: handleFetchRequest,
    [fetchUsersProblemCaseStudiesSuccess]: handleFetchUsersProblemSuccess,
    [fetchUsersProblemCaseStudiesFailure]: handleFetchFailure,
    [postUpdateSolutionCaseStudyRequest]: handlePostRequest,
    [postUpdateSolutionCaseStudySuccess]: handlePostUpdateSolutionSuccess,
    [postUpdateSolutionCaseStudyFailure]: handlePostFailure,
    [editProblemCaseStudyRequest]: handlePostRequest,
    [editProblemCaseStudySuccess]: handleEditProblemSuccess,
    [editProblemCaseStudyFailure]: handlePostFailure,
});

function handleFetchRequest(state) {
    state.isFetching = true;
    state.error = null;
}

function handleFetchFailure(state, action) {
    state.isFetching = false;
    state.error = action.payload;
}

function handlePostRequest(state) {
    state.isPosting = true;
    state.postError = null;
    state.postSuccess = false;
}

function handlePostFailure(state, action) {
    state.isPosting = false;
    state.postError = action.payload;
}

function handleFetchAllWebsiteCaseStudiesSuccess(state, action) {
    state.isFetching = false;
    state.websiteCaseStudies = convertArrToObj(action.payload);
}

function handleFetchAllSolutionCaseStudiesSuccess(state, action) {
    state.isFetching = false;
    state.solutionCaseStudies = convertArrToObj(action.payload);
}

function handleFetchAllProblemCaseStudiesSuccess(state, action) {
    state.isFetching = false;
    state.problemCaseStudies = convertArrToObj(action.payload);
}

function handleFetchSingleWebsiteCaseStudySuccess(state, action) {
    state.isFetching = false;
    state.websiteCaseStudies[action.payload.id] = action.payload;
}

function handleFetchSingleSolutionCaseStudySuccess(state, action) {
    state.isFetching = false;
    state.solutionCaseStudies[action.payload.id] = action.payload;
}

function handleFetchSingleProblemCaseStudySuccess(state, action) {
    state.isFetching = false;
    state.problemCaseStudies[action.payload.id] = action.payload;
}

function handlePostSolutionCaseStudySuccess(state, action) {
    state.isPosting = false;
    state.postSuccess = true;
    state.solutionCaseStudies[action.payload.id] = action.payload;
    state.usersSolutionCaseStudies[action.payload.id] = action.payload;
    state.recentlyCreatedID = action.payload.id;
}

function handlePostProblemCaseStudySuccess(state, action) {
    state.isPosting = false;
    state.postSuccess = true;
    state.problemCaseStudies[action.payload.id] = action.payload;
    state.usersProblemCaseStudies[action.payload.id] = action.payload;
    state.recentlyCreatedID = action.payload.id;
}

function handleFetchUsersSolutionSuccess(state, action) {
    state.usersSolutionCaseStudies = convertArrToObj(action.payload);
    state.isFetching = false;
}

function handleFetchUsersProblemSuccess(state, action) {
    state.usersProblemCaseStudies = convertArrToObj(action.payload);
    state.isFetching = false;
}

function handlePostUpdateSolutionSuccess(state, action) {
    state.isPosting = false;
    state.postSuccess = true;
    state.usersSolutionCaseStudies[action.payload.id] = action.payload;
}

function handleEditProblemSuccess(state, action) {
    state.isPosting = false;
    state.postSuccess = true;
    state.usersProblemCaseStudies[action.payload.id] = action.payload;
}
