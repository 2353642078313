import { useState } from 'react';

const useShowMoreItems = (initialAmount, incrementAmount) => {
    const [amountToShow, setAmountToShow] = useState(initialAmount);

    function showMoreItems() {
        setAmountToShow(amountToShow + incrementAmount);
    }

    return [amountToShow, showMoreItems];
};

export default useShowMoreItems;
