import { useState } from 'react';

const useShowModal = () => {
    const [modal, setModal] = useState({
        show: false,
        data: {},
        type: '',
    });

    const handleModal = (state = false, data = {}, type = '') => {
        if (!state) {
            setModal({ ...modal, show: false, data: {} });
        } else {
            setModal({ ...modal, show: true, data });
        }
    };

    const closeModal = () => handleModal();

    return [modal.show, modal.data, handleModal, closeModal];
};

export default useShowModal;
