import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';

export const fetchReportsListRequest = createAction('fetchReportsListRequest');
export const fetchReportsListSuccess = createAction('fetchReportsListSuccess');
export const fetchReportsListFailure = createAction('fetchReportsListFailure');

export const fetchReportsList = () => async dispatch => {
    dispatch(fetchReportsListRequest());

    try {
        const { data } = await api.get('reports');
        return dispatch(fetchReportsListSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchReportsListFailure, e);
    }
};
