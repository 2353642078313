import { useCallback } from 'react';

import useFieldValidation from '../../hooks/useFieldValidation';
import FormField from './FormField';

const TextInput = ({
    name,
    value,
    onChange,
    label = '',
    placeholder = '',
    type = 'text',
    required = false,
    validationRegExp,
    minLength,
    maxLength,
    minNumber,
    maxNumber,
    disabled,
    customValidate,
    extraClasses = '',
}) => {
    const memoizedValidate = useCallback(_validate, [
        minNumber,
        maxNumber,
        minLength,
        maxLength,
        validationRegExp,
        type,
    ]);
    const [error, showError] = useFieldValidation({
        name,
        required,
        value,
        customValidate,
        extendedValidate: memoizedValidate,
    });

    return (
        <FormField
            className={extraClasses}
            name={name}
            label={label}
            required={required}
            error={error}
        >
            <input
                type={type}
                className={`form-input text-area ${error ? 'error' : ''}`}
                placeholder={placeholder}
                name={name}
                id={name}
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={disabled}
            />
        </FormField>
    );

    function handleBlur() {
        showError();
    }

    function handleChange(e) {
        e.preventDefault();

        const newVal = type === 'number' ? parseInt(e.target.value) : e.target.value;
        onChange(name, newVal);
    }

    function _validate(val) {
        if (!val) return;

        if (type === 'email' && !validateEmail(val)) {
            return 'Please provide a valid email.';
        }
        if (type === 'number' && minNumber && val < minNumber) {
            return `Value cannot be less than ${minNumber},`;
        }
        if (type === 'number' && maxNumber && val > maxNumber) {
            return `Value cannot be greater than ${maxNumber}.`;
        }
        if (type !== 'number' && minLength && val.length < minLength) {
            return `Value must have at least ${minLength} characters.`;
        }
        if (type !== 'number' && maxLength && val.length > maxLength) {
            return `Value cannot have more than ${maxLength} characters.`;
        }
        if (type !== 'number' && validationRegExp && !RegExp(validationRegExp).test(val)) {
            return 'Invalid value provided.';
        }
    }
};

function validateEmail(val) {
    // eslint-disable-next-line
    const reg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return reg.test(val);
}

export default TextInput;
