import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchSolutionCaseStudiesList } from '@actions/caseStudies';
import {
    getCaseStudiesError,
    getCaseStudiesIsFetching,
    getSolutionCaseStudies,
} from '@selectors/caseStudies';

const useAllSolutionsFetch = () => {
    const dispatch = useDispatch();
    const solutions = useSelector(getSolutionCaseStudies);
    const isFetching = useSelector(getCaseStudiesIsFetching);
    const error = useSelector(getCaseStudiesError);

    useEffect(() => {
        dispatch(fetchSolutionCaseStudiesList());
    }, [dispatch]);

    return { solutions, isFetching, error };
};

export default useAllSolutionsFetch;
