import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchInitiatedConnections, fetchRequestedConnections } from '@actions/connectionRequests';
import {
    getInitiatedConnectionsIsFetching,
    getInitiatedConnectionsError,
    getInitiatedConnections,
    getRequestedConnectionsIsFetching,
    getRequestedConnectionsError,
    getRequestedConnections,
} from '@selectors/connectionRequests';

const useFetchConnectionRequests = () => {
    const dispatch = useDispatch();
    const initiatedConnections = useSelector(getInitiatedConnections);
    const isFetchingInitiated = useSelector(getInitiatedConnectionsIsFetching);
    const errorInitiated = useSelector(getInitiatedConnectionsError);
    const requestedConnections = useSelector(getRequestedConnections);
    const isFetchingRequested = useSelector(getRequestedConnectionsIsFetching);
    const errorRequested = useSelector(getRequestedConnectionsError);

    useEffect(() => {
        dispatch(fetchInitiatedConnections());
        dispatch(fetchRequestedConnections());
    }, [dispatch]);

    return [
        initiatedConnections,
        requestedConnections,
        isFetchingInitiated,
        isFetchingRequested,
        errorInitiated,
        errorRequested,
    ];
};

export default useFetchConnectionRequests;
