import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';

export const fetchFundingListRequest = createAction('fetchFundingListRequest');
export const fetchFundingListSuccess = createAction('fetchFundingListSuccess');
export const fetchFundingListFailure = createAction('fetchFundingListFailure');

export const fetchFundingList = () => async dispatch => {
    dispatch(fetchFundingListRequest());

    try {
        const { data } = await api.get('funding');
        return dispatch(fetchFundingListSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchFundingListFailure, e);
    }
};
