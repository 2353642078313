import { metaData } from 'src/constants/meta';

import Banner from '@components/banner/Banner';
import Meta from '@components/meta/Meta';
import BannerImage from '@content/images/banners/network-hub-banner.png';

import NetworkingHubPods from './NetworkingHubPods';
import NetworkingHubHowItWorks from './NetworkingHubHowItWorks';

const NetworkingHub = () => {
    return (
        <>
            <Meta data={metaData.networkingHub} />
            <div id="network-hub">
                <div className="container">
                    <Banner title="Networking Hub" image={BannerImage} />
                </div>

                <NetworkingHubHowItWorks />

                <NetworkingHubPods />
            </div>
        </>
    );
};

export default NetworkingHub;
