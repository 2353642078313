import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';

export const fetchSingleLoggedInUserRequest = createAction('fetchSingleLoggedInUserRequest');
export const fetchSingleLoggedInUserSuccess = createAction('fetchSingleLoggedInUserSuccess');
export const fetchSingleLoggedInUserFailure = createAction('fetchSingleLoggedInUserFailure');

export const fetchSingleLoggedInUser = () => async dispatch => {
    dispatch(fetchSingleLoggedInUserRequest());

    try {
        const { data } = await api.get(`user/profile`);

        return dispatch(fetchSingleLoggedInUserSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchSingleLoggedInUserFailure, e);
    }
};
