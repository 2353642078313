import { Fragment } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import mediaQueries from 'src/constants/mediaQueries';

import { networkingHubPods } from 'src/constants/pods';
import useWindowSize from 'src/hooks/useWindowSize';

import NetworkingHubListRoute from './NetworkingHubListRoute';

const routes = networkingHubPods.map(pod => pod.link);

const NetworkingHubPods = () => {
    const { id } = useParams();
    const { width } = useWindowSize();
    const location = useLocation();
    const splitUrl = location.pathname.split('/');
    const isAnyActive = splitUrl.some(item => routes.includes(item));
    const isMobile = width <= mediaQueries.lgMobile;

    return (
        <>
            <div className="networking-pods page-section container flex-row justify-between flex-column-lm page-bottom-margin">
                {networkingHubPods.map(pod => {
                    const isActive = splitUrl.includes(pod.link);

                    return (
                        <Fragment key={pod.title}>
                            <Link
                                className={`item flex-column justify-end flex-6 ${
                                    !isActive && isAnyActive ? 'inactive' : ''
                                }`}
                                to={
                                    isActive && !id
                                        ? '/networking-hub'
                                        : `/networking-hub/${pod.link}`
                                }
                            >
                                <img
                                    className="image"
                                    alt={pod.summaryImageAlt}
                                    src={pod.summaryImageSrc}
                                />
                                <h2 className="title">{pod.title}</h2>
                                <p className="summary">{pod.summary}</p>
                                {isActive ? (
                                    <i className="arrow fas fa-caret-down"></i>
                                ) : (
                                    <i className="arrow fas fa-caret-right"></i>
                                )}
                            </Link>

                            {isMobile && isActive && <NetworkingHubListRoute />}
                        </Fragment>
                    );
                })}
            </div>

            {!isMobile && <NetworkingHubListRoute />}
        </>
    );
};

export default NetworkingHubPods;
