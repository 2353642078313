import { useCallback, useState } from 'react';
import { metaData } from 'src/constants/meta';

import Banner from '@components/banner/Banner';
import Meta from '@components/meta/Meta';
import BannerImage from '@content/images/banners/map-banner.jpg';
import TableauReport from 'tableau-react';
import useIsLoggedIn from 'lib/src/hooks/useIsLoggedIn';

const BatterySystemsLandscapeMap = () => {
    const [mapWidth, setMapWidth] = useState(null);
    const isLoggedIn = useIsLoggedIn();

    const mapWrapperRef = useCallback(node => {
        if (node !== null) {
            setMapWidth(node.getBoundingClientRect().width);
        }
    }, []);

    const publicMapUrl =
        'https://public.tableau.com/views/CSBSlandscapemap-AugustUpdate/Navigation';
    const membersMapUrl =
        'https://public.tableau.com/views/CSBSlandscapetestrecyclemaps-AugustUpdate/Navigation';

    return (
        <>
            <Meta data={metaData.landscapeMap} />

            <div id="battery-systems-landscape-map" className="container page-bottom-margin">
                <Banner title="Cross-Sector Battery Systems Landscape Map" image={BannerImage} />
                <div className="page-section flex-column align-center">
                    <section className="flex-row page-bottom-margin justify-between flex-column-pt">
                        <div className="paragraph-wrapper wysiwyg">
                            <h3>
                                This landscape map is split into 3 areas: <br />
                                <br />
                                <ul>
                                    <li>
                                        <strong>The Network Map:</strong> featuring a wide range of
                                        organisations from across the UK battery supply chain. Use
                                        the{' '}
                                        <a
                                            href="https://info.ktn-uk.org/p/2VFU-7EA/battery-systems-sign-up"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            sign up form
                                        </a>{' '}
                                        to add your organisation to this map.
                                    </li>
                                    <li>
                                        <strong>The Battery Testing Map:</strong> showcasing
                                        facilities which are offering various third party commercial
                                        battery testing services.{' '}
                                        <a
                                            href="https://info.ktn-uk.org/p/2VFU-7EA/battery-systems-sign-up"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Contact us
                                        </a>{' '}
                                        to add your organisation to this map.
                                    </li>
                                    <li>
                                        <strong>The Battery Recycling Map:</strong> displaying UK
                                        publicly funded projects and EU facilities.{' '}
                                        <a
                                            href="https://info.ktn-uk.org/p/2VFU-7EA/battery-systems-sign-up"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Fill in this form
                                        </a>{' '}
                                        to add your organisation’s activities in this space.
                                    </li>
                                </ul>
                                <br />
                            </h3>
                        </div>
                    </section>

                    {/* Add '&:jsdebug=n' to a query to remove debug information */}

                    <div className="map-wrapper" ref={mapWrapperRef}>
                        {mapWidth && (
                            <TableauReport
                                url={isLoggedIn ? membersMapUrl : publicMapUrl}
                                query={
                                    isLoggedIn
                                        ? '?:language=en-GB&:display_count=n&:jsdebug=n&:showVizHome=no&:embed=true'
                                        : '?:publish=yes&:display_static_image=y&:bootstrapWhenNotified=true&:embed=true&:jsdebug=n&:language=en-GB&:embed=y&:showVizHome=n&:apiID=host0#navType=0&navSrc=Parse'
                                }
                                options={{
                                    width: mapWidth,
                                    height: 850,
                                }}
                            />
                        )}
                    </div>
                    {!isLoggedIn && (
                        <div className="sign-up-box flex-column align-center">
                            <p>Register now and become a UK Battery Networking Hub member to:</p>
                            <br />
                            <ul style={{ 'list-style-type': 'disc' }}>
                                <li>Access the full Landscape Map</li>
                                <li>Receive our monthly email newsletter</li>
                                <li> Collaborate with industry experts in the Networking Hub</li>
                            </ul>

                            <div className="flex-row justify-around" style={{ width: '100%' }}>
                                <a className="button" href="/auth/login" rel="noreferrer">
                                    Log in
                                </a>

                                <a
                                    className="button"
                                    href="https://info.ktn-uk.org/p/2VFU-7EA/battery-systems-sign-up"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Register
                                </a>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default BatterySystemsLandscapeMap;
