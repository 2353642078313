import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';

// Login
export const postForgotPasswordRequest = createAction('postForgotPasswordRequest');
export const postForgotPasswordSuccess = createAction('postForgotPasswordSuccess');
export const postForgotPasswordFailure = createAction('postForgotPasswordFailure');

export const postForgotPassword = postBody => async dispatch => {
    dispatch(postForgotPasswordRequest());
    try {
        const { data } = await api.post('auth/reset', postBody);

        return dispatch(postForgotPasswordSuccess(data.token));
    } catch (e) {
        handleApiErrors(dispatch, postForgotPasswordFailure, e);
    }
};
