import BreadCrumb from '@components/breadcrumb/BreadCrumb';
import dayjs from 'dayjs';

import { useHistory } from 'react-router-dom';

import { postedByDateTime } from 'src/constants/dateFormats';

import ActionButton from 'lib/src/components/button/ActionButton';
import ShareThis from '@components/shareThis/ShareThis';
import PageVideo from '@components/pageVideo/PageVideo';
import Meta from '@components/meta/Meta';

const SingleNewsDetails = ({ news }) => {
    const history = useHistory();

    const { title, summary, publishedOn, body, newsTags, vimeoLink, youTubeLink } = news;

    const breadcrumbArray = [
        { text: 'Home', link: '/' },
        { text: 'News', link: '/news' },
        { text: title, link: null },
    ];

    const metaData = {
        title,
        description: summary,
    };

    return (
        <>
            <Meta data={metaData} />

            <BreadCrumb breadcrumbArray={breadcrumbArray} />

            <div className="container">
                <div className="page-section">
                    <main className="post-content">
                        <p className="post-details">
                            Posted on&nbsp;
                            <strong>{dayjs(publishedOn).format(postedByDateTime)}</strong>
                        </p>

                        {!!newsTags.length && (
                            <div className="flex-row tag-wrapper">
                                {newsTags.map(({ tag }, index) => {
                                    return index < 3 && <div className="tag">{tag}</div>;
                                })}
                            </div>
                        )}

                        <div className="wysiwyg">
                            <h2>{title}</h2>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: body,
                                }}
                            />

                            <PageVideo vimeoLink={vimeoLink} youTubeLink={youTubeLink} />
                        </div>

                        <section className="flex-row align-center justify-between button-container large">
                            <ActionButton color="grey" onClick={() => history.push('/news')}>
                                Go Back
                            </ActionButton>
                            <ShareThis title={title} summary={summary} />
                        </section>
                    </main>
                </div>
            </div>
        </>
    );
};

export default SingleNewsDetails;
