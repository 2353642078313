import PageVideo from '@components/pageVideo/PageVideo';
import Meta from '@components/meta/Meta';
import BreadCrumb from '@components/breadcrumb/BreadCrumb';
import HashLinkButton from '@components/button/HashLinkButton';

const FundingSingleDetails = ({ funding }) => {
    const { title, summary, description, youTubeLink, vimeoLink } = funding;

    const breadcrumbArray = [
        { text: 'Home', link: '/' },
        { text: 'News', link: '/news' },
        { text: 'Funding', hashLink: '/news#funding' },
        { text: funding.title, link: null },
    ];

    const metaData = {
        title: title,
        description: summary,
    };

    return (
        <>
            <Meta data={metaData} />

            <BreadCrumb breadcrumbArray={breadcrumbArray} />

            <div className="networking-hub-single container page-margin-bottom">
                <div className="page-section">
                    <div className="wysiwyg">
                        <h2>{title}</h2>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: description,
                            }}
                        />

                        <PageVideo vimeoLink={vimeoLink} youTubeLink={youTubeLink} />
                    </div>

                    <section className="flex-row align-center justify-between button-container large">
                        <HashLinkButton color="grey" href="/news#funding">
                            Go Back
                        </HashLinkButton>
                    </section>
                </div>
            </div>
        </>
    );
};

export default FundingSingleDetails;
