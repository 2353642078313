import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import usePrevious from 'lib/src/hooks/usePrevious';

import {
    getCaseStudiesPostError,
    getCaseStudiesPostSuccess,
    getRecentlyCreatedCaseStudyID,
} from '@selectors/caseStudies';
import { fetchSingleProblemCaseStudy } from '@actions/caseStudies/fetchSingleProblemCaseStudy';
import { fetchSingleSolutionCaseStudy } from '@actions/caseStudies/fetchSingleSolutionCaseStudy';

const useCaseStudyOnSubmission = (closeModal, showError, urlPrefix) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const error = useSelector(getCaseStudiesPostError);
    const success = useSelector(getCaseStudiesPostSuccess);
    const recentlyCreatedID = useSelector(getRecentlyCreatedCaseStudyID);

    const prevProps = usePrevious({ success, error });

    useEffect(() => {
        if (success && !prevProps.success) {
            closeModal();
            if (urlPrefix === 'problems') dispatch(fetchSingleProblemCaseStudy(recentlyCreatedID));
            else dispatch(fetchSingleSolutionCaseStudy(recentlyCreatedID));
            history.push(`/networking-hub/${urlPrefix}/${recentlyCreatedID}`);
        }

        if (error && !prevProps.error) {
            closeModal();
            showError();
        }
    }, [
        success,
        prevProps.success,
        error,
        prevProps.error,
        closeModal,
        showError,
        history,
        urlPrefix,
        recentlyCreatedID,
        dispatch,
    ]);

    return { postError: error };
};

export default useCaseStudyOnSubmission;
