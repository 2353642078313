import React from 'react';

import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const BreadCrumb = ({ breadcrumbArray }) => {
    return (
        <div className="breadcrumb">
            <div className="container flex-row align-center">
                <nav>
                    {breadcrumbArray.map((item, i) =>
                        item.hashLink ? (
                            <React.Fragment key={item.hashLink}>
                                <HashLink key={item.hashLink} smooth to={item.hashLink}>
                                    <sub>{item.text}</sub>
                                </HashLink>
                                {i < breadcrumbArray.length - 1 ? ' / ' : ''}
                            </React.Fragment>
                        ) : item.link ? (
                            <React.Fragment key={item.link}>
                                <Link key={item.link} to={item.link}>
                                    <sub>{item.text}</sub>
                                </Link>
                                {i < breadcrumbArray.length - 1 ? ' / ' : ''}
                            </React.Fragment>
                        ) : (
                            <span key={item.text + i} className="highlighted">
                                {item.text}
                                {i < breadcrumbArray.length - 1 ? ' / ' : ''}
                            </span>
                        ),
                    )}
                </nav>
            </div>
        </div>
    );
};

export default BreadCrumb;
