import React from 'react';
import DataCheck from '@components/dataCheck/DataCheck';
import List from './List';

const ConnectionsList = ({ title, items, isFetching, error }) => {
    return (
        <div className="connection-list-container">
            <div className="list-header-container wysiwyg flex-row align-center justify-between">
                <h3>{title}</h3>
            </div>
            <DataCheck
                data={items}
                isFetching={isFetching}
                error={error}
                noDataMessage="No entries to display"
            >
                <List items={Object.values(items)} isRequested={title.includes('Incoming')} />
            </DataCheck>
        </div>
    );
};

export default ConnectionsList;
