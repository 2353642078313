import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';

// UpdateUserProfile
export const postUpdateUserProfileRequest = createAction('postUpdateUserProfileRequest');
export const postUpdateUserProfileSuccess = createAction('postUpdateUserProfileSuccess');
export const postUpdateUserProfileFailure = createAction('postUpdateUserProfileFailure');

export const postUpdateUserProfile = postBody => async dispatch => {
    dispatch(postUpdateUserProfileRequest());
    try {
        const { data } = await api.patch('user/profile', postBody);
        return dispatch(postUpdateUserProfileSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, postUpdateUserProfileFailure, e);
    }
};
