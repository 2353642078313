import { useSelector } from 'react-redux';

import { getNews } from '@selectors/news';

const useNewsToShow = (amountToShow, form) => {
    const news = useSelector(getNews);
    const newsToShow = getNewsToShow();

    function getNewsToShow() {
        const newsArray = Object.values(news);
        const pinnedItems = newsArray
            .filter(item => item.isPinned)
            .sort((a, b) => new Date(b.publishedOn) - new Date(a.publishedOn));
        const otherItems = newsArray
            .filter(item => !item.isPinned)
            .sort((a, b) => new Date(b.publishedOn) - new Date(a.publishedOn));

        const newArray = pinnedItems.concat(otherItems);

        const filteredArray = [...newArray]
            .filter(({ newsTags }) => {
                if (!form.selectedOptions.length) return true;

                const tags = newsTags.map(({ id }) => id);
                return tags.some(tag => form.selectedOptions.includes(tag));
            })
            .filter(({ title, summary }) => {
                if (!form.searchString) return true;

                const searchString = form.searchString.toLowerCase();
                const matchesTitle = title && title.toLowerCase().includes(searchString);
                const matchesSummary = summary && summary.toLowerCase().includes(searchString);

                return matchesTitle || matchesSummary;
            });

        return { total: filteredArray.length, items: filteredArray.slice(0, amountToShow) };
    }

    return [newsToShow];
};

export default useNewsToShow;
