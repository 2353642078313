export const getInitiatedConnectionsIsFetching = state =>
    state.connectionRequestsReducer.isFetchingInitiated;
export const getInitiatedConnectionsError = state => state.connectionRequestsReducer.errorInitiated;
export const getInitiatedConnections = state =>
    state.connectionRequestsReducer.initiatedConnections;

export const getRequestedConnectionsIsFetching = state =>
    state.connectionRequestsReducer.isFetchingRequested;
export const getRequestedConnectionsError = state => state.connectionRequestsReducer.errorRequested;
export const getRequestedConnections = state =>
    state.connectionRequestsReducer.requestedConnections;

export const getConnectionRequestsIsPosting = state => state.connectionRequestsReducer.isPosting;
export const getConnectionRequestsPostError = state => state.connectionRequestsReducer.postError;
export const getConnectionRequestsPostSuccess = state =>
    state.connectionRequestsReducer.postSuccess;
