import { useSelector } from 'react-redux';

import { getReports } from '@selectors/reports';
import { useMemo } from 'react';

const useReportsToShow = (amountToShow, searchString) => {
    const reports = useSelector(getReports);
    const reportsToShow = useMemo(() => {
        const reportsArray = Object.values(reports);

        const filteredArray = [...reportsArray]
            .sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn))
            .filter(({ title, summary }) => {
                if (!searchString) return true;

                const searchStringToLower = searchString.toLowerCase();
                const matchesTitle = title && title.toLowerCase().includes(searchStringToLower);
                const matchesSummary =
                    summary && summary.toLowerCase().includes(searchStringToLower);

                return matchesTitle || matchesSummary;
            });

        return { total: filteredArray.length, items: filteredArray.slice(0, amountToShow) };
    }, [reports, searchString, amountToShow]);

    return [reportsToShow];
};

export default useReportsToShow;
