import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';

// Register
export const postRegisterRequest = createAction('postRegisterRequest');
export const postRegisterSuccess = createAction('postRegisterSuccess');
export const postRegisterFailure = createAction('postRegisterFailure');

export const postRegister = postBody => async dispatch => {
    dispatch(postRegisterRequest());
    try {
        const { data } = await api.post('auth/register', postBody);
        localStorage.setItem('token', data.token);

        return dispatch(postRegisterSuccess(data.token));
    } catch (e) {
        handleApiErrors(dispatch, postRegisterFailure, e);
    }
};
