import ActionButton from 'lib/src/components/button/ActionButton';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import Form from 'lib/src/components/form/Form';
import FormRow from 'lib/src/components/form/FormRow';
import TextInput from 'lib/src/components/form/TextInput';
import Checkbox from 'lib/src/components/form/Checkbox';

import RegisterSuccessModal from './RegisterSuccessModal';

const RegisterForm = ({
    formState: {
        firstName,
        lastName,
        email,
        password,
        postcode,
        jobTitle,
        organisationName,
        organisationURL,
        agreedToDataProcessing,
    },
    handleChange,
    handleSubmit,
    isPosting,
    showSuccessModal,
}) => (
    <>
        <Form onSubmit={handleSubmit} isPosting={isPosting} omitButtons extraClasses="page-section">
            <h3 className="auth-supply-title">Please enter your details here</h3>
            <FormRow extraClasses="flex-column-lm">
                <TextInput
                    name="firstName"
                    value={firstName}
                    placeholder="First name*"
                    onChange={handleChange}
                    required
                    extraClasses="auth-form-field"
                />

                <TextInput
                    name="lastName"
                    value={lastName}
                    placeholder="Last name*"
                    onChange={handleChange}
                    required
                    extraClasses="auth-form-field"
                />
            </FormRow>
            <FormRow extraClasses="flex-column-lm">
                <TextInput
                    name="email"
                    type="email"
                    value={email}
                    placeholder="Email address*"
                    onChange={handleChange}
                    required
                    extraClasses="auth-form-field"
                />

                <TextInput
                    name="password"
                    value={password}
                    placeholder="Password*"
                    onChange={handleChange}
                    type="password"
                    required
                    extraClasses="auth-form-field"
                />
            </FormRow>
            <FormRow extraClasses="flex-column-lm">
                <TextInput
                    name="postcode"
                    value={postcode}
                    placeholder="Postcode*"
                    onChange={handleChange}
                    required
                    extraClasses="auth-form-field"
                />
                <TextInput
                    name="jobTitle"
                    value={jobTitle}
                    placeholder="Job Title*"
                    onChange={handleChange}
                    required
                    extraClasses="auth-form-field"
                />
            </FormRow>
            <FormRow extraClasses="flex-column-lm">
                <TextInput
                    name="organisationName"
                    value={organisationName}
                    placeholder="Organisation Name*"
                    onChange={handleChange}
                    required
                    extraClasses="auth-form-field"
                />
                <TextInput
                    name="organisationURL"
                    value={organisationURL}
                    placeholder="Organisation Website URL*"
                    onChange={handleChange}
                    required
                    extraClasses="auth-form-field"
                />
            </FormRow>

            <FormRow>
                <Checkbox
                    name="agreedToDataProcessing"
                    value={agreedToDataProcessing}
                    label="Click here to confirm KTN have your permission to process your data."
                    onChange={handleChange}
                    required
                    extraClasses="auth-checkbox flex-row flex-wrap align-center"
                />
            </FormRow>
            <ButtonRow alignment="left">
                <ActionButton color="grey" extraClasses="no-margin" isPosting={isPosting}>
                    Submit
                </ActionButton>
            </ButtonRow>
        </Form>
        {showSuccessModal && <RegisterSuccessModal />}
    </>
);
export default RegisterForm;
