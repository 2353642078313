import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

import { RAW_S3_STORAGE_URL } from 'src/config';
import { postedByDateTime } from 'src/constants/dateFormats';

import ActionButton from 'lib/src/components/button/ActionButton';
import BreadCrumb from '@components/breadcrumb/BreadCrumb';
import Meta from '@components/meta/Meta';

const ReportSingledetails = ({ report }) => {
    const history = useHistory();
    const { title, summary, createdOn, description, file, reportTags, externalURL } = report;

    const breadcrumbArray = [
        { text: 'Home', link: '/' },
        { text: 'Resources', link: '/resources' },
        { text: 'Reports', hashLink: '/resources#reports' },
        { text: title, link: null },
    ];

    const metaData = {
        title,
        description: summary,
    };

    return (
        <>
            <Meta data={metaData} />

            <BreadCrumb breadcrumbArray={breadcrumbArray} />

            <div className="container page-margin-bottom">
                <div className="page-section">
                    <main className="post-content">
                        <p className="post-details margined">
                            Posted on&nbsp;
                            <strong>{dayjs(createdOn).format(postedByDateTime)}</strong>
                        </p>

                        {!!reportTags.length && (
                            <div className="flex-row tag-wrapper">
                                {reportTags.map(({ tag }, index) => {
                                    return index < 3 && <div className="tag">{tag}</div>;
                                })}
                            </div>
                        )}

                        <div className="wysiwyg">
                            <h2>{title}</h2>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: description,
                                }}
                            />
                        </div>

                        {(file || externalURL) && (
                            <section className="flex-row align-center button-container large">
                                {file && (
                                    <a
                                        className="button yellow"
                                        href={`${RAW_S3_STORAGE_URL}/${file}`}
                                        target="_blank"
                                        rel="noreferrer"
                                        download
                                    >
                                        Download report
                                    </a>
                                )}

                                {externalURL && (
                                    <a
                                        className="button yellow"
                                        href={externalURL}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Read report
                                    </a>
                                )}
                            </section>
                        )}

                        <section className="flex-row align-center justify-between button-container large">
                            <ActionButton color="grey" onClick={() => history.push('/resources')}>
                                Go Back
                            </ActionButton>
                        </section>
                    </main>
                </div>
            </div>
        </>
    );
};

export default ReportSingledetails;
