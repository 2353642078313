import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lib/src/utils/generic';
import { fetchUsersProblemCaseStudies } from '@actions/caseStudies';
import {
    getUsersProblemCaseStudies,
    getCaseStudiesIsFetching,
    getCaseStudiesError,
} from '@selectors/caseStudies';
import { getSingleLoggedInUser } from '@selectors/auth';

const useFetchProblemCaseStudies = () => {
    const dispatch = useDispatch();
    const user = useSelector(getSingleLoggedInUser);
    const caseStudies = useSelector(getUsersProblemCaseStudies);
    const isFetchingCaseStudies = useSelector(getCaseStudiesIsFetching);
    const errorCaseStudies = useSelector(getCaseStudiesError);

    useEffect(() => {
        if (!isEmpty(user)) {
            dispatch(fetchUsersProblemCaseStudies(user.id));
        }
    }, [dispatch, user]);

    return [caseStudies, isFetchingCaseStudies, errorCaseStudies];
};

export default useFetchProblemCaseStudies;
