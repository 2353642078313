import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchReportsList } from '@actions/reports/index';

import { getReports, getReportsIsFetching, getReportsError } from '@selectors/reports';

const useAllReportsFetch = () => {
    const dispatch = useDispatch();
    const reports = useSelector(getReports);
    const isFetching = useSelector(getReportsIsFetching);
    const error = useSelector(getReportsError);

    useEffect(() => {
        dispatch(fetchReportsList());
    }, [dispatch]);

    return [reports, isFetching, error];
};

export default useAllReportsFetch;
