import { createReducer } from '@reduxjs/toolkit';

import {
    fetchSingleUserRequest,
    fetchSingleUserSuccess,
    fetchSingleUserFailure,
} from '@actions/users';

const initialState = {
    isFetching: false,
    error: null,
    user: {},
};

export default createReducer(initialState, {
    [fetchSingleUserRequest]: handleFetchRequest,
    [fetchSingleUserFailure]: handleFetchFailure,
    [fetchSingleUserSuccess]: handleFetchSingleSuccess,
});

function handleFetchRequest(state) {
    state.isFetching = true;
    state.error = null;
}

function handleFetchFailure(state, action) {
    state.isFetching = false;
    state.error = action.payload;
}

function handleFetchSingleSuccess(state, action) {
    state.isFetching = false;
    state.user = action.payload;
}
