export const getCaseStudiesIsFetching = state => state.caseStudiesReducer.isFetching;
export const getCaseStudiesError = state => state.caseStudiesReducer.error;

export const getCaseStudiesIsPosting = state => state.caseStudiesReducer.isPosting;
export const getCaseStudiesPostError = state => state.caseStudiesReducer.postError;
export const getCaseStudiesPostSuccess = state => state.caseStudiesReducer.postSuccess;

export const getWebsiteCaseStudies = state => state.caseStudiesReducer.websiteCaseStudies;
export const getSolutionCaseStudies = state => state.caseStudiesReducer.solutionCaseStudies;
export const getProblemCaseStudies = state => state.caseStudiesReducer.problemCaseStudies;

export const getUsersSolutionCaseStudies = state =>
    state.caseStudiesReducer.usersSolutionCaseStudies;

export const getSingleWebsiteCaseStudy = (state, id) =>
    state.caseStudiesReducer.websiteCaseStudies[id];

export const getSingleSolutionCaseStudy = (state, id) =>
    state.caseStudiesReducer.solutionCaseStudies[id];

export const getSingleProblemCaseStudy = (state, id) =>
    state.caseStudiesReducer.problemCaseStudies[id];

export const getRecentlyCreatedCaseStudyID = state => state.caseStudiesReducer.recentlyCreatedID;

export const getUsersProblemCaseStudies = state => state.caseStudiesReducer.usersProblemCaseStudies;
