import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import usePrevious from 'lib/src/hooks/usePrevious';

import { fetchSingleNews } from '@actions/news/index';
import { getNewsError, getNewsIsFetching, getSingleNews } from '@selectors/news';

const useSingleNewsFetch = newsID => {
    const dispatch = useDispatch();
    const history = useHistory();
    const news = useSelector(state => getSingleNews(state, newsID));
    const isFetching = useSelector(getNewsIsFetching);
    const error = useSelector(getNewsError);

    const prevProps = usePrevious({ error });

    useEffect(() => {
        dispatch(fetchSingleNews(newsID));
    }, [dispatch, newsID]);

    useEffect(() => {
        if (!prevProps.error && error) {
            history.push('/news');
        }
    }, [error, prevProps.error, history]);

    return [news, isFetching, error];
};

export default useSingleNewsFetch;
