// list & single
export const getNewsIsFetching = state => state.newsReducer.isFetching;
export const getNewsError = state => state.newsReducer.error;

// list
export const getNews = state => state.newsReducer.news;

// single
export const getSingleNews = (state, id) => state.newsReducer.news[id];

// similar news
export const getSimilarNewsIsFetching = state => state.newsReducer.isFetchingSimilarNews;
export const getSimilarNewsError = state => state.newsReducer.similarNewsError;
export const getSimilarNews = state => state.newsReducer.similarNews;

//news tags
export const getNewsTags = state => state.newsReducer.newsTags;
