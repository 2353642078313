import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';

// Login
export const fetchNewsListRequest = createAction('fetchNewsListRequest');
export const fetchNewsListSuccess = createAction('fetchNewsListSuccess');
export const fetchNewsListFailure = createAction('fetchNewsListFailure');

export const fetchNewsList = () => async dispatch => {
    dispatch(fetchNewsListRequest());

    try {
        const { data } = await api.get('news');
        return dispatch(fetchNewsListSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchNewsListFailure, e);
    }
};
