import { useEffect, useState } from 'react';

const OpportunityModal = ({ opportunity, index, closeModal }) => {
    const { subheading, body, headingColour, bodyColour, image } = opportunity;
    const [position, setPostion] = useState(null);

    useEffect(() => {
        calcPosition(index);
    }, [index]);

    return (
        <div
            className={`cso-modal-body flex-column justify-between ${bodyColour} ${
                position ? position : 'left'
            }`}
        >
            <div className={`cso-subheading flex-column justify-end ${headingColour}`}>
                <h3>{subheading}</h3>
            </div>
            <div className="cso-body">
                <div
                    className="wysiwyg"
                    dangerouslySetInnerHTML={{
                        __html: body,
                    }}
                />
            </div>

            <div className="cso-image large">
                <img className="image-fit" src={image} alt={subheading} />
            </div>
        </div>
    );

    function calcPosition(index) {
        if (index === 1 || index === 4 || index === 7) {
            setPostion('centre');
        }
        if (index === 2 || index === 5) {
            setPostion('right');
        }
    }
};

export default OpportunityModal;
