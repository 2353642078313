import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import usePrevious from 'lib/src/hooks/usePrevious';

import { getFundingError, getFundingIsFetching, getSingleFunding } from '@selectors/funding';
import { fetchSingleFunding } from '@actions/funding/index';

const useSingleFundingFetch = fundingID => {
    const dispatch = useDispatch();
    const history = useHistory();
    const funding = useSelector(state => getSingleFunding(state, fundingID));
    const isFetching = useSelector(getFundingIsFetching);
    const error = useSelector(getFundingError);

    const prevProps = usePrevious({ error });

    useEffect(() => {
        dispatch(fetchSingleFunding(fundingID));
    }, [dispatch, fundingID]);

    useEffect(() => {
        if (!prevProps.error && error) {
            history.push('/funding');
        }
    }, [error, prevProps.error, history]);

    return [funding, isFetching, error];
};

export default useSingleFundingFetch;
