import { metaData } from 'src/constants/meta';

import RegisterImage from '@content/images/auth/register/Battery_image.jpg';

import Banner from '@components/banner/Banner';
import ResetPasswordForm from './ResetPasswordForm';
import Meta from '@components/meta/Meta';

const ResetPassword = () => {
    return (
        <>
            <Meta data={metaData.resetPassword} disableIndex />
            <div className="container">
                <Banner image={RegisterImage} />
            </div>
            <section className="auth-subheading">
                <div className="container page-section">
                    <h2>Please enter your new password</h2>
                </div>
            </section>
            <div className="container flex-row justify-center page-section page-margin-bottom">
                <ResetPasswordForm />
            </div>
        </>
    );
};

export default ResetPassword;
