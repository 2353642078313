import { createReducer } from '@reduxjs/toolkit';

import {
    fetchReportsListRequest,
    fetchReportsListSuccess,
    fetchReportsListFailure,
    fetchReportSingleRequest,
    fetchReportSingleSuccess,
    fetchReportSingleFailure,
} from '@actions/reports';

const initialState = {
    isFetching: false,
    error: null,
    reports: [],
};

export default createReducer(initialState, {
    [fetchReportsListRequest]: handleFetchRequest,
    [fetchReportsListFailure]: handleFetchFailure,
    [fetchReportsListSuccess]: handleFetchAllSuccess,
    [fetchReportSingleRequest]: handleFetchRequest,
    [fetchReportSingleFailure]: handleFetchFailure,
    [fetchReportSingleSuccess]: handleFetchSingleSuccess,
});

function handleFetchRequest(state) {
    state.isFetching = true;
    state.error = null;
}

function handleFetchFailure(state, action) {
    state.isFetching = false;
    state.error = action.payload;
}

function handleFetchAllSuccess(state, action) {
    state.isFetching = false;
    state.reports = action.payload;
}

function handleFetchSingleSuccess(state, action) {
    state.isFetching = false;
    state.reports[action.payload.id] = action.payload;
}
