import { useHistory } from 'react-router-dom';

import ActionButton from 'lib/src/components/button/ActionButton';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import Form from 'lib/src/components/form/Form';
import FormRow from 'lib/src/components/form/FormRow';
import TextInput from 'lib/src/components/form/TextInput';
import Checkbox from 'lib/src/components/form/Checkbox';
import useUpdateProfile from './hooks/useUpdateProfile';
import UpdateProfileSuccessModal from './UpdateProfileSuccessModal';
import FormField from 'lib/src/components/form/FormField';

const ProfileForm = ({ user }) => {
    const history = useHistory();

    const {
        handleSubmit,
        handleChange,
        isPosting,
        form: {
            firstName,
            lastName,
            email,
            postcode,
            jobTitle,
            organisationName,
            organisationURL,
            agreedToDataProcessing,
        },
        showSuccessModal,
        handleModalClose,
    } = useUpdateProfile(user);

    return (
        <>
            <Form
                onSubmit={handleSubmit}
                isPosting={isPosting}
                omitButtons
                extraClasses="page-section"
            >
                <h2 className="auth-form-title">Registration details</h2>
                <h3 className="auth-supply-title">Please edit your details here</h3>
                <FormRow extraClasses="flex-column-lm">
                    <TextInput
                        name="firstName"
                        value={firstName}
                        label="First name"
                        onChange={handleChange}
                        required
                        extraClasses="auth-form-field"
                    />

                    <TextInput
                        name="lastName"
                        value={lastName}
                        label="Last name"
                        onChange={handleChange}
                        required
                        extraClasses="auth-form-field"
                    />
                </FormRow>
                <FormRow extraClasses="flex-column-lm">
                    <TextInput
                        name="email"
                        type="email"
                        value={email}
                        label="Email address"
                        onChange={handleChange}
                        required
                        extraClasses="auth-form-field"
                    />
                    <TextInput
                        name="postcode"
                        value={postcode}
                        label="Postcode"
                        onChange={handleChange}
                        required
                        extraClasses="auth-form-field"
                    />
                </FormRow>
                <FormRow extraClasses="flex-column-lm">
                    <TextInput
                        name="jobTitle"
                        value={jobTitle}
                        label="Job Title"
                        onChange={handleChange}
                        required
                        extraClasses="auth-form-field"
                    />
                    <TextInput
                        name="organisationName"
                        value={organisationName}
                        label="Organisation Name"
                        onChange={handleChange}
                        required
                        extraClasses="auth-form-field"
                    />
                </FormRow>
                <FormRow extraClasses="flex-column-lm">
                    <TextInput
                        name="organisationURL"
                        value={organisationURL}
                        label="Organisation Website URL"
                        onChange={handleChange}
                        required
                        extraClasses="auth-form-field"
                    />
                    <FormField />
                </FormRow>

                {!user.agreedToDataProcessing && (
                    <FormRow extraClasses="flex-column-lm">
                        <Checkbox
                            name="agreedToDataProcessing"
                            value={agreedToDataProcessing}
                            label="Click here to confirm KTN have your permission to process your data."
                            onChange={handleChange}
                            required
                            extraClasses="auth-checkbox flex-row flex-wrap align-center"
                        />
                    </FormRow>
                )}

                <ButtonRow alignment="left">
                    <ActionButton
                        color="bright-green"
                        extraClasses="no-margin"
                        isPosting={isPosting}
                    >
                        Update
                    </ActionButton>
                    <ActionButton
                        color="grey"
                        onClick={() => history.push('/dashboard')}
                        extraClasses="margin-left"
                    >
                        Cancel
                    </ActionButton>
                </ButtonRow>
            </Form>
            {showSuccessModal && <UpdateProfileSuccessModal handleClose={handleModalClose} />}
        </>
    );
};

export default ProfileForm;
