import AboutBannerBg from '@content/images/banners/about-us.png';

import { metaData } from 'src/constants/meta';

import Banner from '@components/banner/Banner';
import Meta from '@components/meta/Meta';

const PrivacyPolicy = () => (
    <>
        <Meta data={metaData.privacy} />

        <div id="privacy-page" className="container page-margin-bottom">
            <Banner title="Privacy Policy" image={AboutBannerBg} />

            <div className="page-section">
                <div className="wysiwyg">
                    <h2>
                        At Innovate UK Business Connect, we respect and protect the privacy of our
                        customers and our members, and those who use our website and services.
                    </h2>
                    <p>
                        Knowledge Transfer Network Ltd, (“we”, “us”, “our”, “KTN”) is a not for
                        profit organisation, legally established in September 2013 (no. 8705643).
                        Our registered office address is at KTN London, Unit 218, Business Design
                        Centre, Upper Street, Islington, London N1 0QH.
                    </p>

                    <p>
                        This privacy statement provides details about how your personal information
                        is collected and used. Personal information is any information that
                        identifies you or would enable someone to contact you, such as your name,
                        email address, phone number and other non-public information that is
                        associated with such information.
                    </p>

                    <p>
                        Please read this Policy carefully, as it sets out the basis on which any
                        personal data we collect from you, or that you provide to us (“data”), will
                        be processed by us, in compliance with the General Data Protection
                        Regulations (GDPR).
                    </p>

                    <h3>1. Your rights</h3>

                    <p>
                        The introduction of GDPR expands the rights of individuals to control how
                        your personal information is collected and processed, your rights are
                        detailed below:
                    </p>

                    <ul>
                        <li>Right to be informed</li>
                        <li>Right of access</li>
                        <li>Right to rectification</li>
                        <li>Right to erasure</li>
                        <li>Right to restrict processing</li>
                        <li>Right to data portability</li>
                        <li>Right to object</li>
                        <li>Rights related to automated decision making and profiling</li>
                    </ul>

                    <h3>2. Information we may collect from you</h3>

                    <p>
                        When you participate in, access or sign-up to any of our services (including
                        our newsletters, events, competitions, social media, message boards and
                        telephone discussions), create an account using our KTN platform or
                        otherwise correspond with us, we will receive data about you.
                    </p>

                    <p>
                        This data may consist of information such as your name, email address,
                        postal address, landline or mobile number. Depending on the activity we may
                        also obtain information about the name and type of your business, and its
                        address.
                    </p>

                    <p>
                        We also use cookies and collect IP addresses from visitors to our websites
                        (see Section 8 for further details).
                    </p>

                    <h3>3. What is the legal basis for doing so?</h3>

                    <p>
                        Article 6 (1) (a)–That the data subject has given consent to the processing
                        of their personal data for one or more specific reasons.
                    </p>

                    <p>
                        Article 6 (1) (b) – processing is necessary for the performance of a
                        contract to which the data subject is party or in order to take steps at the
                        request of the data subject prior to entering into a contract.
                    </p>

                    <p>
                        Article 6 (1) (f)– processing is necessary for the purposes of the
                        legitimate interests pursued by the Controller or by a third party, except
                        where such interests are overridden by the interests or fundamental rights
                        and freedoms of the data subject which require Protection of Data, in
                        particular where the data subject is a child.
                    </p>

                    <h3>4. How we use your data</h3>

                    <p>
                        We process personal information for certain legitimate business purposes
                        which include some or all of the following:
                    </p>

                    <ul>
                        <li>
                            Where the processing enables us to enhance, modify, personalise or
                            otherwise improve our services/communications for the benefit of our
                            members
                        </li>
                        <li>To meet our obligations to you</li>
                        <li>
                            To provide you with our services, activities or online content and to
                            deal with your requests and enquiries
                        </li>
                        <li>To identify and prevent fraud</li>
                        <li>To enhance the security of our network and information systems</li>
                        <li>To better understand how people interact with our website</li>
                        <li>
                            To provide e-communications which we think will be of interest to you
                        </li>
                        <li>To determine the effectiveness of our promotional campaigns</li>
                    </ul>

                    <p>
                        Whenever we process data for these purposes we will ensure that we always
                        take account of your individual rights. You have the right to object to this
                        processing at any time by contacting{' '}
                        <a href="mailto:dataprotection@ktn-uk.org">dataprotection@ktn-uk.org</a>.
                        Please bear in mind that if you object this may affect our ability to carry
                        out activities as above for your benefit.
                    </p>

                    <p>
                        Where we propose using your data for any purpose other than those listed
                        above, we will gain explicit consent from you in advance.
                    </p>

                    <h3>5. Promotional communications</h3>

                    <p>
                        Promotional and marketing communications may be sent to you by various
                        communication channels. In the case of electronic communications, these will
                        be sent to you if you have given express permission to allow KTN to use your
                        data for such purposes or where we think these communications are
                        highlighting services that we believe may be of benefit to you.
                    </p>

                    <p>
                        You can instruct us at any time not to process your personal data for the
                        purposes of marketing by changing your preferences on the KTN website, or by
                        opting out using the “unsubscribe” link provided on all our electronic
                        communications.
                    </p>

                    <h3>6. Your personal data</h3>

                    <p>
                        You have the right to request from us access to your personal data together
                        with details of the purpose for processing and who we share your data with.
                        You can request a copy of your data held by contacting
                        <a href="mailto:dataprotection@ktn-uk.org">dataprotection@ktn-uk.org</a>.
                    </p>

                    <p>
                        You have the right to data portability and we will provide you with a copy
                        of your automatically collected data, in a common format.
                    </p>

                    <p>
                        You have the right to request that any inaccurate data is rectified by us
                        and you can also update any details by logging in and amending your website
                        profile.
                    </p>

                    <p>
                        You have the right to request that your personal data is deleted from
                        records where your personal data is no longer necessary in relation to the
                        original purpose it was collected; where processing is for marketing
                        purposes or where your personal data has been unlawfully processed. There
                        are some exceptions which include, where we need to keep data for compliance
                        with legal obligations, in defence of legal claims.
                    </p>

                    <p>
                        You have the right to ask that we restrict the processing of your personal
                        data, where you contest the accuracy or legal basis for processing but do
                        not wish your data to be deleted. Please bear in mind that if you object
                        this may affect our ability to carry out activities as above for your
                        benefit.
                    </p>

                    <h3>7. Retention &amp; Deletion</h3>

                    <p>
                        We shall not keep data any longer than is necessary from the original
                        purpose of collection and we will delete data in line with our Data
                        Retention policy in compliance with our legal obligations.
                    </p>

                    <h3>8. IP address and cookies</h3>

                    <p>
                        An IP address is a number that can uniquely identify a specific computer or
                        other network device on the internet. We may collect information about your
                        computer, including your IP address, operating system and browser type, for
                        system administration. This is statistical data about our users’ browsing
                        actions and patterns, and does not identify any individual.
                    </p>

                    <p>
                        For the same reason, we may obtain information about your usage of our site
                        by using a cookie file which is stored on the hard drive of your computer.
                        Cookies contain information that is transferred to your computer’s hard
                        drive. They help us to improve our site and to deliver a better and more
                        personalised service.
                    </p>

                    <p>They enable us:</p>

                    <ul>
                        <li>To estimate our audience size and usage pattern</li>
                        <li>
                            To store information about your preferences, and so allow us to
                            customise our site according to your individual interests
                        </li>
                        <li>To speed up your searches, and</li>
                        <li>To recognise you when you return to our site.</li>
                    </ul>

                    <p>
                        You may refuse to accept cookies by activating the setting on your browser
                        which allows you to do so. However, if you select this setting you may be
                        unable to access certain parts of our site. Unless you have adjusted your
                        browser setting so that it will refuse cookies, our system will issue
                        cookies when you log-on to our site.
                    </p>

                    <h3>9. Disclosure of your data to third parties</h3>

                    <p>i) Major stakeholders &amp; UK Research and Innovation &amp; Innovate UK</p>

                    <p>
                        As KTN, we provide support to our major stakeholders in achieving their
                        objective to stimulate and support UK innovation, including Innovate UK. To
                        ensure that our members continue to benefit from our networking and
                        knowledge-sharing activities, we allow these organisations to use the data
                        held by us, in accordance with this Policy.
                    </p>

                    <p>ii) Other third parties</p>

                    <p>Only where necessary, we may otherwise disclose your data:</p>

                    <ul>
                        <li>
                            To contractors, agents and professional advisors where such entities
                            have agreed to provide services to us
                        </li>
                        <li>
                            If KTN or a substantial part of its assets are transferred to another
                            public body (for example, as a result of a change in the Innovate UK’s
                            reallocation of public funding), in which case personal data held by KTN
                            may be among the transferred assets
                        </li>
                        <li>
                            If we are under a duty to disclose or share your personal data in order
                            to comply with any legal obligation (including any reporting obligations
                            we may have to the UK Government), or in order to enforce or apply our
                            and other agreements, or
                        </li>
                        <li>
                            To protect the rights, property, or safety of others. This includes
                            exchanging information with other companies and organisations for the
                            purposes of fraud protection and credit risk reduction.
                        </li>
                    </ul>

                    <p>
                        Our website may, from time to time, contain links to and from other
                        websites. If you follow a link to any of these websites, please note that
                        they have their own privacy policies, and that we do not accept any
                        responsibility or liability for these policies. Please check these policies
                        before you submit any personal data to these websites.
                    </p>

                    <h3>10. Where we store your personal data</h3>

                    <p>
                        All personal data you provide to us is stored on secure servers, which are
                        operated in accordance with the UK Information Commissioner’s guidelines on
                        the storage of personal data.
                    </p>

                    <p>
                        We cannot guarantee the security of your data during transmission from you
                        to us, and you transmit such data at your own risk. Once your data has
                        reached our servers, we will use strict procedures and security features to
                        try and prevent unauthorised access.
                    </p>

                    <h3>11. International Transfer of Data (outside EEA)</h3>

                    <p>
                        We will only transfer data to countries outside of the EEA where they comply
                        with the appropriate safeguards for processing personal data as approved by
                        the European Commission in line with GDPR.
                    </p>

                    <h3>12. Changes to our Privacy Policy</h3>

                    <p>
                        Any changes we may make to this Policy will be posted on this page and,
                        where appropriate, notified to you by e-mail.
                    </p>

                    <h3>13. Data Protection Officer</h3>

                    <p>
                        Any questions or requests regarding this Policy and/or the processing of
                        your data are welcomed, and should be addressed to{' '}
                        <a href="mailto:dataprotection@ktn-uk.org">dataprotection@ktn-uk.org</a>
                    </p>
                </div>
            </div>
        </div>
    </>
);

export default PrivacyPolicy;
