import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchInitiatedConnections } from '@actions/connectionRequests';
import {
    getInitiatedConnections,
    getInitiatedConnectionsIsFetching,
} from '@selectors/connectionRequests';

import useIsLoggedIn from 'lib/src/hooks/useIsLoggedIn';

const useCheckIntroductionRequest = (id, caseStudyType) => {
    const isLoggedIn = useIsLoggedIn();
    const dispatch = useDispatch();
    const isFetchingConnections = useSelector(getInitiatedConnectionsIsFetching);
    const connections = useSelector(getInitiatedConnections);

    const connectionStatus = getConnectionStatus();

    useEffect(() => {
        if (isLoggedIn && dispatch(fetchInitiatedConnections()));
    }, [dispatch, isLoggedIn]);

    function getConnectionStatus() {
        const connection = Object.values(connections).find(
            connection =>
                connection.caseStudyID === id && connection.caseStudyType === caseStudyType,
        );

        if (!connection) return null;

        return connection.status;
    }

    return { isFetchingConnections, connectionStatus };
};

export default useCheckIntroductionRequest;
