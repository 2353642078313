import Modal from 'lib/src/components/modal/Modal';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import LinkButton from 'lib/src/components/button/LinkButton';

const ResetPasswordSuccessModal = () => {
    return (
        <Modal>
            <div className="wysiwyg text-center page-bottom-margin">
                <h2>Password reset successful</h2>
            </div>
            <ButtonRow>
                <LinkButton color="grey" href="/auth/login">
                    Go to login
                </LinkButton>
            </ButtonRow>
        </Modal>
    );
};

export default ResetPasswordSuccessModal;
