import React from 'react';

const HomePodDescription = ({ curSelected }) => (
    <div className="home-pod-description flex-row flex-column-lm">
        <div className={`content ${!!curSelected.mainImageSrc ? 'flex-8' : 'flex'}`}>
            <div
                className="wysiwyg"
                dangerouslySetInnerHTML={{
                    __html: curSelected.description,
                }}
            />
        </div>

        {!!curSelected.mainImageSrc && (
            <div className="image flex-4">
                <img alt={curSelected.mainImageAlt} src={curSelected.mainImageSrc} />
            </div>
        )}
    </div>
);

export default HomePodDescription;
