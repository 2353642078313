import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';

export const fetchSolutionCaseStudiesListRequest = createAction(
    'fetchSolutionCaseStudiesListRequest',
);
export const fetchSolutionCaseStudiesListSuccess = createAction(
    'fetchSolutionCaseStudiesListSuccess',
);
export const fetchSolutionCaseStudiesListFailure = createAction(
    'fetchSolutionCaseStudiesListFailure',
);

export const fetchSolutionCaseStudiesList = () => async dispatch => {
    dispatch(fetchSolutionCaseStudiesListRequest());

    try {
        const { data } = await api.get('SolutionCaseStudies');

        return dispatch(fetchSolutionCaseStudiesListSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchSolutionCaseStudiesListFailure, e);
    }
};
