import BatterySystemsLandscapeMap from '@pages/batterySystemsLandscapeMap/BatterySystemsLandscapeMap';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

const BatterySystemsLanscapeRoutes = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${path}`}>
                <BatterySystemsLandscapeMap />
            </Route>
        </Switch>
    );
};

export default BatterySystemsLanscapeRoutes;
