import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';

export const fetchSingleUserRequest = createAction('fetchSingleUserRequest');
export const fetchSingleUserSuccess = createAction('fetchSingleUserSuccess');
export const fetchSingleUserFailure = createAction('fetchSingleUserFailure');

export const fetchSingleUser = userID => async dispatch => {
    dispatch(fetchSingleUserRequest());

    try {
        const { data } = await api.get(`user/${userID}`);

        return dispatch(fetchSingleUserSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchSingleUserFailure, e);
    }
};
