const TeamCarouselItem = ({ teamMember: { name, jobTitle, jobDescription, link, image } }) => {
    return (
        <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="team-carousel-item flex-column align-center"
        >
            <div className="image-wrapper">
                <img src={image} alt={name} />
            </div>

            <div className="content-wrapper flex-column align-center">
                <h3>{name}</h3>
                <h4>{jobTitle}</h4>
                <p>{jobDescription}</p>
            </div>
        </a>
    );
};

export default TeamCarouselItem;
