import { useParams } from 'react-router-dom';

import Banner from '@components/banner/Banner';

import DataCheck from '@components/dataCheck/DataCheck';
import SingleNewsDetails from './SingleNewsDetails';

import useSingleNewsFetch from './hooks/useSingleNewsFetch';
import useSimilarNewsFetch from './hooks/useSimilarNewsFetch';
import { RAW_S3_STORAGE_URL } from 'src/config';
import NewsListCard from '../list/NewsListCard';

const SingleNews = () => {
    const { id } = useParams();
    const [news, isFetching, error] = useSingleNewsFetch(id);
    const [similarNews, isFetchingSimilarNews, similarNewsError] = useSimilarNewsFetch(id);

    return (
        <>
            <DataCheck data={news} isFetching={isFetching} error={error}>
                <div className="container">
                    <Banner
                        title="News"
                        image={`${RAW_S3_STORAGE_URL}/${news ? news.headerImageS3Key : ''}`}
                    />
                </div>

                <SingleNewsDetails news={news} />
            </DataCheck>

            <div className="container">
                <div className="page-section">
                    <h2 className="page-sub-heading">Related Posts</h2>
                </div>

                <DataCheck
                    data={similarNews}
                    isFetching={isFetchingSimilarNews}
                    error={similarNewsError}
                >
                    <div className="flex-row flex-column-lm">
                        {[...similarNews]
                            .sort((a, b) => b.similarTagCount - a.similarTagCount)
                            .slice(0, 3)
                            .map(news => (
                                <NewsListCard key={news.id} item={news} isRelatedPosts />
                            ))}
                    </div>
                </DataCheck>
            </div>
        </>
    );
};

export default SingleNews;
