import AutomotiveBackground from '../_content/sectors/automotive_background.jpg';
import RailBackground from '../_content/sectors/rail_background.jpg';
import AerospaceBackground from '../_content/sectors/aerospace_background.jpg';
import MaritimeBackground from '../_content/sectors/maritime_background.jpg';
import DefenceBackground from '../_content/sectors/defence_background.jpg';
import StationaryBackground from '../_content/sectors/stationary_background.jpg';
import OtherBackground from '../_content/sectors/other_background.jpg';

export const organisationTypes = {
    industry: 1,
    university: 2,
    contractResearch: 3,
    government: 4,
    other: 5,
};

export const orgaisationTypeNames = {
    1: 'Industry',
    2: 'University',
    3: 'Contract Research',
    4: 'Governemnt',
    5: 'Other',
};

export const specialistSectors = {
    automotive: 1,
    rail: 2,
    aerospace: 3,
    maritime: 4,
    defence: 5,
    stationary: 6,
    other: 7,
};

export const specialistSectorsNames = {
    1: 'Automotive',
    2: 'Rail',
    3: 'Aerospace',
    4: 'Maritime',
    5: 'Defence',
    6: 'Stationary',
    7: 'Other',
};

export const specialistSectorsImages = {
    1: AutomotiveBackground,
    2: RailBackground,
    3: AerospaceBackground,
    4: MaritimeBackground,
    5: DefenceBackground,
    6: StationaryBackground,
    7: OtherBackground,
};

export const supplyChainDescriptions = {
    cellsAndMaterials: 1,
    modulesAndPacks: 2,
    endOfLife: 3,
    recycling: 4,
    endUserApplications: 5,
    other: 6,
};

export const specialistSectorsSelectListNames = [
    { label: 'Automotive', value: 1 },
    { label: 'Rail', value: 2 },
    { label: 'Aerospace', value: 3 },
    { label: 'Maritime', value: 4 },
    { label: 'Defence', value: 5 },
    { label: 'Stationary', value: 6 },
    {
        label: 'Other',
        value: 7,
    },
];

export const caseStudyTypes = {
    website: 1,
    problem: 2,
    solution: 3,
};

export const caseStudyNames = {
    1: 'Website',
    2: 'Problem',
    3: 'Solution',
};

export const connectionStatusValues = {
    pending: 1,
    approved: 2,
};
