import { metaData } from 'src/constants/meta';

import RegisterImage from '@content/images/auth/register/Battery_image.jpg';

import Banner from '@components/banner/Banner';
import LoginFormContainer from './LoginFormContainer';
import Meta from '@components/meta/Meta';

const Login = ({ prevLocation }) => (
    <>
        <Meta data={metaData.login} />
        <div className="container">
            <Banner title="Login" image={RegisterImage} />
        </div>
        <section className="auth-subheading">
            <div className="container page-section">
                <h2>Please login with your email and password</h2>
            </div>
        </section>

        <div className="container page-bottom-margin">
            <LoginFormContainer prevLocation={prevLocation} />
        </div>
    </>
);

export default Login;
