import AboutMainImage from '@content/images/homepage/open-pod-image.png';
import DiscussionMainImage from '@content/images/homepage/open-pod-image4.png';
import WebinarMainImage from '@content/images/homepage/open-pod-3.png';
import AboutSummaryImage from '@content/images/homepage/thumbnail-about.png';
import CrossSectorSummaryImage from '@content/images/homepage/thumbnail-community.png';
import BatteriesForSummaryImage from '@content/images/homepage/thumbnail-podcast.png';

import pod1 from '@content/images/networking/pod1.jpg';
import pod2 from '@content/images/networking/pod2.jpg';

import MainProfileImg from '@content/images/dashboard/home/main-profile.jpg';
import ConnectionRequestImg from '@content/images/dashboard/home/connection-requests.jpg';
import SolutionImg from '@content/images/dashboard/home/solution.jpg';
import ProblemImg from '@content/images/dashboard/home/problem.jpg';

export const homePods = [
    {
        title: 'About',
        summary:
            'The strategic objective of this InnovationNetwork is to create an open and collaborativecross-sectoral community for researchersand innovators.',
        summaryImageSrc: AboutSummaryImage,
        summaryImageAlt: 'people discussing notes',
        mainImageSrc: AboutMainImage,
        mainImageAlt: 'battery',
        description: `
            <p>
                <strong>Co-funded by Innovate UK Business Connect and the Faraday Battery Challenge</strong>, the Cross-Sector Battery Systems (CSBS)Innovation Network aims to:
            </p>
            <ul>
                <li>
                    foster and develop the UK supply chain for battery systems beyond the automotive sector;
                </li>
                <li>
                    identify cross-sector common user needs for battery systems;
                </li>
                <li>
                    make it easier for UK battery systems users to find UK supply chain partners;
                </li>
                <li>
                    enable better battery related UK networking and collaboration;
                </li>
                <li>
                    provide a consolidated voice for an emerging supply chain.
                </li>
            </ul>
            <a class="button transparent" style="margin-top:35px;" href="https://www.ukbatteriesnetwork.org/about" target="_blank" rel="noopener">READ MORE ></a>
        `,
    },
    {
        title: 'The 2035 UK Battery Recycling Industry Vision',
        summary:
            'The Cross-Sector Battery Systems Innovation Network has developed a 2035 UK Battery Recycling Industry Vision through an infographic and its supportive report to outline how the industry can continue to grow and become more sustainable in the future.',
        summaryImageSrc: CrossSectorSummaryImage,
        summaryImageAlt: 'battery',
        mainImageSrc: DiscussionMainImage,
        mainImageAlt: 'battery',
        description: `<p>This vision reflects how sustainable battery recycling across the supply chain can be a reality in the future; 
        the importance of critical minerals including cobalt and lithium; and the journey towards electrification of road transport which will help governments across the world achieve their Net Zero and decarbonisation goals.</p>

        <p>To make this vision a reality, join our battery community, engage in the discussion and collaborate with other members to be part of the solution and help the UK claim a significant percentage of the £27 billion global battery recycling market.</p>
        <a href='https://www.ukbatteriesnetwork.org/resources/reports/71' rel="noreferrer"><p>Access the infographic, read the report and join the community</p></a>
        `,
    },
    {
        title: 'Battery Caffè Podcast Series',
        summary: `A series of short interviews with experts discussing many battery-related topics from investment to recycling, materials and next-generation technologies.`,
        summaryImageSrc: BatteriesForSummaryImage,
        summaryImageAlt: 'people in a meeting',
        mainImageSrc: WebinarMainImage,
        mainImageAlt: 'battery',
        description: `
        <p>The Battery Caffè aims to highlight a diverse range of battery-related topics and is inspired by the work Innovate UK Business Connect is doing behind the scenes. Make yourself a coffee and join us!</p>
        <p><a href='https://www.ukbatteriesnetwork.org/news/37' target='_blank' rel='noreferrer'>Listen to the Battery Caffè Podcasts</a></p>
     `,
    },
];

export const networkingHubPods = [
    {
        title: 'Battery Challenges',
        summary: 'Share your battery challenge & talk with a network of UK solution providers.',
        link: 'problems',
        summaryImageSrc: pod1,
    },
    {
        title: 'Battery Solutions',
        summary: 'Share your battery solution & collaborate with a community of innovators.',
        link: 'solutions',
        summaryImageSrc: pod2,
    },
];

export const dashboardHomePods = [
    {
        title: 'My Profile',
        link: 'dashboard/profile',
        image: MainProfileImg,
    },
    {
        title: 'Connection requests',
        link: 'dashboard/connection-requests',
        image: ConnectionRequestImg,
    },
    {
        title: 'Solution Case Studies',
        link: 'dashboard/solution-case-studies',
        image: SolutionImg,
    },
    {
        title: 'Problem Case Studies',
        link: 'dashboard/problem-case-studies',
        image: ProblemImg,
    },
];
