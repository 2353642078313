import { useEffect } from 'react';
import ReactGA from 'react-ga';
import Routes from './routes';

import Header from './components/header/Header';
import Footer from '@components/footer/Footer';
import useGlobalRedirect from 'lib/src/hooks/useGlobalRedirect';
import useIsLoggedIn from 'lib/src/hooks/useIsLoggedIn';
import { fetchSingleLoggedInUser } from '@actions/auth/index';
import { useDispatch } from 'react-redux';
import analytics from './analytics';
import { gTagID } from './constants/googleTagManager';
import { useLocation } from 'react-router-dom';

if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    ReactGA.initialize('UA-192891093-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
}

function App() {
    useGlobalRedirect();
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    const isLoggedIn = useIsLoggedIn();

    useEffect(() => {
        if (isLoggedIn) {
            dispatch(fetchSingleLoggedInUser());
        }
    }, [isLoggedIn, dispatch]);

    useEffect(() => {
        if (process.env.REACT_APP_ENVIRONMENT !== 'dev')
            analytics(window, document, 'script', 'dataLayer', gTagID);
    }, [pathname]); // Google Tag Manager

    return (
        <>
            <Header />
            <Routes />
            <Footer />
        </>
    );
}

export default App;
