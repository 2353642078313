import { Route, useRouteMatch } from 'react-router-dom';
import SwitchWith404 from './SwitchWith404';

import Login from '@pages/auth/login/Login';
import Register from '@pages/auth/register/Register';
import ForgotPassword from '@pages/auth/forgotPassword/ForgotPassword';
import ResetPassword from '@pages/auth/forgotPassword/ResetPassword';

const AuthRoutes = ({ prevLocation }) => {
    const { path } = useRouteMatch();
    return (
        <SwitchWith404>
            <Route exact path={`${path}/login`}>
                <Login routePath={`${path}/login`} prevLocation={prevLocation} />
            </Route>
            <Route exact path={`${path}/register`}>
                <Register />
            </Route>
            <Route exact path={`${path}/forgot-password`}>
                <ForgotPassword />
            </Route>
            <Route exact path={`${path}/forgot-password/:guid`}>
                <ResetPassword />
            </Route>
        </SwitchWith404>
    );
};

export default AuthRoutes;
