import { convertCamelCaseToFormLabel } from 'lib/src/utils/generic';

export const enumToFormOptions = baseEnum => {
    return Object.entries(baseEnum).map(([key, value]) => ({
        value,
        label: convertCamelCaseToFormLabel(key),
    }));
};

export const connectionStatus = {
    1: 'Pending',
    2: 'Approved',
};
