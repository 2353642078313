import aerospaceImage from '@content/images/logos/foundingMembers/aerospace.jpeg';
// import ktnImage from '@content/images/logos/foundingMembers/ktn.png';
import alexanderDennis from '@content/images/logos/foundingMembers/alexander-dennis.png';
import deltaMotorsport from '@content/images/logos/foundingMembers/delta-motorsport.png';
import dstl from '@content/images/logos/foundingMembers/dstl.png';
import faraday from '@content/images/logos/foundingMembers/faraday.png';
import innovateUK from '@content/images/logos/foundingMembers/innovate-uk.png';
import railwayIndustry from '@content/images/logos/foundingMembers/railway.png';
import rollsRoyce from '@content/images/logos/foundingMembers/rolls-royce.png';
import ukbic from '@content/images/logos/foundingMembers/ukbic.png';
import amte from '@content/images/logos/foundingMembers/amte.png';

const foundingMembers = [
    // { id: 1, name: 'KTN', image: ktnImage },
    { id: 2, name: 'Innovate UK', image: innovateUK },
    { id: 3, name: 'Faraday Institute', image: faraday },
    { id: 4, name: 'UK Battery Industrialisation Center', image: ukbic },
    { id: 5, name: 'Rolls Royce', image: rollsRoyce },
    { id: 6, name: 'Aerospace Institute of Technology', image: aerospaceImage },
    { id: 7, name: 'Alexander Dennis', image: alexanderDennis },
    { id: 8, name: 'Delta Motorsport', image: deltaMotorsport },
    { id: 9, name: 'DSTL', image: dstl },
    { id: 10, name: 'Railway Industry Association', image: railwayIndustry },
    { id: 11, name: 'amte', image: amte },
];

export default foundingMembers;
