const useCaseStudiesToShow = (amountToShow, form, caseStudies) => {
    const caseStudiesToShow = getCaseStudiesToShow();

    function getCaseStudiesToShow() {
        const caseStudiesArray = Object.values(caseStudies);

        const filteredArray = [...caseStudiesArray]
            .filter(({ sectors }) => {
                if (!form.selectedOptions.length) return true;

                const tags = sectors.map(sector => sector);
                return tags.some(tag => form.selectedOptions.includes(tag));
            })
            .sort((a, b) => new Date(b.date) - new Date(a.date))
            .filter(({ title, summary }) => {
                if (!form.searchString) return true;

                const searchString = form.searchString.toLowerCase();
                const matchesTitle = title && title.toLowerCase().includes(searchString);
                const matchesSummary = summary && summary.toLowerCase().includes(searchString);

                return matchesTitle || matchesSummary;
            });

        return { total: filteredArray.length, items: filteredArray.slice(0, amountToShow) };
    }

    return [caseStudiesToShow];
};

export default useCaseStudiesToShow;
