import { useSelector } from 'react-redux';

import { getFunding } from '@selectors/funding';

const useFundingToShow = (amountToShow, form) => {
    const funding = useSelector(getFunding);
    const fundingToShow = getFundingToShow();

    function getFundingToShow() {
        const fundingArray = Object.values(funding);
        const filteredArray = [...fundingArray]
            .filter(({ title, summary }) => {
                if (!form.searchString) return true;

                const searchString = form.searchString.toLowerCase();
                const matchesTitle = title && title.toLowerCase().includes(searchString);
                const matchesSummary = summary && summary.toLowerCase().includes(searchString);

                return matchesTitle || matchesSummary;
            })
            .sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));

        return { total: filteredArray.length, items: filteredArray.slice(0, amountToShow) };
    }

    return [fundingToShow];
};

export default useFundingToShow;
