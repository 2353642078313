import { Link, useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { getUserNameFromToken } from 'lib/src/utils/jwt';

import KTNLogo from '@content/images/logos/Innovate UK Logo - Full Colour.png';

import useWindowSize from 'src/hooks/useWindowSize';
import useIsLoggedIn from 'lib/src/hooks/useIsLoggedIn';
import useShowMobileNavigation from './hooks/useShowMobileNavigation';

import LinkButton from 'lib/src/components/button/LinkButton';
import Navigation from './Navigation';
import mediaQueries from 'src/constants/mediaQueries';

const Header = () => {
    const history = useHistory();
    const isLoggedIn = useIsLoggedIn();
    const user = getUserNameFromToken();

    const { width } = useWindowSize();
    const { showMobileNavigation, setShowMobileNavigation } = useShowMobileNavigation();

    if (isMobile || width <= mediaQueries.lsTablet) {
        return (
            <>
                <header className="header-mobile">
                    {isLoggedIn && <p className="logged-in-as">Logged in as: {user}</p>}

                    <div className="container flex-row justify-between align-center">
                        <Link to="/" className="logo">
                            <img alt="KTN Innovation Networks" src={KTNLogo} />
                        </Link>

                        <button className="menu-icon" onClick={() => setShowMobileNavigation(true)}>
                            <i className="fal fa-bars"></i>
                        </button>
                    </div>
                </header>

                <nav className={`navigation-mobile ${showMobileNavigation ? 'active' : ''}`}>
                    <button className="close-menu" onClick={() => setShowMobileNavigation(false)}>
                        <i className="fal fa-times"></i>
                    </button>

                    <Navigation
                        isMobile
                        hideNavigation={() => setShowMobileNavigation(false)}
                        logout={logout}
                    />
                </nav>
            </>
        );
    }

    return (
        <header className="header">
            <div className="account-row">
                <div className="container flex-row justify-end align-center">
                    {isLoggedIn ? (
                        <div className="flex-row account-details align-center">
                            <Link to="/dashboard/profile" className="flex-row align-center">
                                <p>{user}</p>
                            </Link>
                            <span className="divider">&#183;</span>
                            <p>
                                <Link to="/dashboard">My dashboard</Link>
                            </p>
                            <span className="divider">&#183;</span>
                            <p>
                                <button className="unstyled-button" onClick={logout}>
                                    Log out
                                </button>
                            </p>
                        </div>
                    ) : (
                        <>
                            <LinkButton color="orange" href="/auth/register">
                                Register
                            </LinkButton>
                            <LinkButton color="grey" href="/auth/login">
                                Login
                            </LinkButton>
                        </>
                    )}
                </div>
            </div>

            <div className="header-row">
                <div className="container flex-row align-center justify-end">
                    <Link to="/" className="logo flex-row justify-center">
                        <img alt="KTN Innovation Networks" src={KTNLogo} />
                    </Link>

                    <nav className="flex-row flex-8 flex-9-sd justify-between navigation">
                        <Navigation />
                    </nav>
                </div>

                <div className="nav-background" />
            </div>
        </header>
    );

    function logout(e) {
        e.preventDefault();
        localStorage.removeItem('token');
        history.replace('/');
    }
};

export default Header;
