import React, { useState } from 'react';

import { metaData } from 'src/constants/meta';

import useFetchProblemCaseStudies from './hooks/useFetchProblemCaseStudies';
import DashboardHeader from '@components/dashboardHeader/DashboardHeader';
import useShowModal from './hooks/useShowModal';
import CreateCaseStudyModal from './modals/CreateCaseStudyModal';
import Meta from '@components/meta/Meta';
import DataCheck from '@components/dataCheck/DataCheck';
import DashboardCaseStudyList from '../shared/DashboardCaseStudyList';
import ActionButton from 'lib/src/components/button/ActionButton';
import EditCaseStudyModal from './modals/EditCaseStudyModal';

const ProblemCaseStudies = () => {
    const [caseStudies, isFetching, error] = useFetchProblemCaseStudies();
    const [createShow, createData, handleShowModal, closeShowModal] = useShowModal();

    const [showEditModal, setShowEditModal] = useState(false);

    const [caseStudy, setCaseStudy] = useState(null);

    return (
        <>
            <Meta data={metaData.myProblemCaseStudies} disableIndex />

            <div className="dashboard-container problem-case-studies-container page-margin-top page-margin-bottom">
                <DashboardHeader pageTitle="My Problem Case Studies" />
                <div className="container page-section">
                    <div className="list-header-container wysiwyg flex-row align-center justify-between">
                        <h2>My Problem Cases Studies</h2>
                    </div>
                    <div className="list-container">
                        <DataCheck
                            data={caseStudies}
                            error={error}
                            isFetching={isFetching}
                            noDataMessage="No entries to display"
                        >
                            <div className="list-container flex-row flex-wrap flex-column-pt">
                                <DashboardCaseStudyList
                                    caseStudies={caseStudies}
                                    handleEditClick={handleEditClick}
                                    error={error}
                                    isFetching={isFetching}
                                    urlPrefix="problems"
                                />
                            </div>
                        </DataCheck>
                    </div>

                    <ActionButton onClick={handleShowModal} color="grey">
                        Add new
                    </ActionButton>
                </div>
                {showEditModal && (
                    <EditCaseStudyModal
                        closeModal={() => setShowEditModal(false)}
                        item={caseStudy}
                    />
                )}
                {createShow && (
                    <CreateCaseStudyModal item={createData} closeModal={closeShowModal} />
                )}
            </div>
        </>
    );

    function handleEditClick(caseStudy) {
        setShowEditModal(!showEditModal);
        setCaseStudy(caseStudy);
    }
};

export default ProblemCaseStudies;
