import { useEffect, useState } from 'react';

import useForm from 'lib/src/hooks/useForm';
import usePrevious from 'lib/src/hooks/usePrevious';

const useNetworkingHubCaseStudyForm = () => {
    const [showYouTubeLink, setShowYouTubeLink] = useState(false);
    const [showVimeoLink, setShowVimeoLink] = useState(false);

    const [form, handleChange] = useForm({
        title: '',
        summary: '',
        description: '',
        sectors: [],
        youTubeLink: null,
        vimeoLink: null,
    });

    const prevProps = usePrevious({ showVimeoLink, showYouTubeLink });

    const postBody = {
        ...form,
        date: new Date(),
        isPublished: true,
    };

    useEffect(() => {
        if (prevProps.showVimeoLink && !showVimeoLink) {
            handleChange('vimeoLink', null);
        }
        if (prevProps.showYouTubeLink && !showYouTubeLink) {
            handleChange('youTubeLink', null);
        }
    }, [showVimeoLink, showYouTubeLink, prevProps, handleChange]);

    return {
        form,
        handleChange,
        postBody,
        showYouTubeLink,
        showVimeoLink,
        setShowYouTubeLink,
        setShowVimeoLink,
    };
};

export default useNetworkingHubCaseStudyForm;
