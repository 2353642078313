import useForm from 'lib/src/hooks/useForm';
import usePrevious from 'lib/src/hooks/usePrevious';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { postRegister } from '@actions/auth/postRegister';
import { getAuthIsPosting, getAuthPostSuccess } from '@selectors/auth';

import RegisterForm from './RegisterForm';

const RegisterFormContainer = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isPosting = useSelector(getAuthIsPosting);
    const postSuccess = useSelector(getAuthPostSuccess);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const [formState, handleChange] = useForm({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        postcode: '',
        jobTitle: '',
        organisationName: '',
        organisationURL: '',
        agreedToDataProcessing: null,
    });

    const prevPostSuccess = usePrevious(postSuccess);

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            setShowSuccessModal(true);
        }
    }, [postSuccess, prevPostSuccess, history]);

    return (
        <RegisterForm
            handleChange={handleChange}
            formState={formState}
            handleSubmit={handleSubmit}
            isPosting={isPosting}
            showSuccessModal={showSuccessModal}
        />
    );

    function handleSubmit() {
        dispatch(postRegister(formState));
    }
};

export default RegisterFormContainer;
