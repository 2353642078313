import { Redirect, Route, useLocation } from 'react-router-dom';
import { getJwtToken, validateJWT } from 'lib/src/utils/jwt';

const SubRouter = ({ path, children, auth }) => {
    const location = useLocation();

    if (auth) {
        const token = getJwtToken();
        const isValidToken = validateJWT(token);

        if (!isValidToken) {
            return <Redirect to="/auth/login" from={location.pathname} />;
        }
    }
    return <Route path={path}>{children}</Route>;
};

export default SubRouter;
