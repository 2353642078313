import { createReducer } from '@reduxjs/toolkit';

import {
    postContactSubmissionFailure,
    postContactSubmissionRequest,
    postContactSubmissionSuccess,
} from '@actions/contactUs/index';

const initialState = {
    isPosting: false,
    postSuccess: false,
    error: null,
};

export default createReducer(initialState, {
    [postContactSubmissionRequest]: handlePostRequest,
    [postContactSubmissionSuccess]: handlePostSuccess,
    [postContactSubmissionFailure]: handleFailure,
});

function handlePostRequest(state) {
    state.isPosting = true;
    state.postSuccess = false;
    state.error = null;
}

function handlePostSuccess(state) {
    state.isPosting = false;
    state.postSuccess = true;
}

function handleFailure(state, action) {
    state.isPosting = false;
    state.error = action.payload;
}
