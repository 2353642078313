import React, { useLayoutEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { specialistSectorsSelectListNames } from 'lib/src/constants/enums';
import { metaData } from 'src/constants/meta';

import useForm from 'lib/src/hooks/useForm';
import useIsLoggedIn from 'lib/src/hooks/useIsLoggedIn';
import useShowMoreItems from 'src/hooks/useShowMoreItems';
import useAllProblemsFetch from './hooks/useAllProblemsFetch';
import useCaseStudiesToShow from './hooks/useCaseStudiesToShow';
import useCaseStudyOnSubmission from './hooks/useCaseStudyOnSubmission';

import ButtonRow from 'lib/src/components/button/ButtonRow';
import ActionButton from 'lib/src/components/button/ActionButton';
import MultiSelect from 'lib/src/components/form/MultiSelect';
import TextArea from 'lib/src/components/form/TextArea';

import DataCheck from '@components/dataCheck/DataCheck';
import FilterSubHeading from '@components/filterSubHeading/FilterSubHeading';
import NetworkingHubCaseStudyListCard from './NetworkingHubCaseStudyListCard';
import NetworkingHubProblemSingle from '../single/NetworkingHubProblemSingle';
import NetworkingHubSubmitProblemModal from '../modals/NetworkingHubSubmitProblemModal';
import ErrorModal from '@components/modals/ErrorModal';
import Meta from '@components/meta/Meta';

const NetworkingHubProblemsList = () => {
    const scrollRef = useRef(null);
    const { id } = useParams();
    const [form, handleChange] = useForm({ selectedOptions: [], searchString: '' });
    const { problems, isFetching, error } = useAllProblemsFetch();
    const [amountToShow, showMoreItems] = useShowMoreItems(12, 12);
    const [caseStudiesToShow] = useCaseStudiesToShow(amountToShow, form, problems);

    const [showSubmissionModal, setShowSubmissionModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);

    const { postError } = useCaseStudyOnSubmission(
        () => setShowSubmissionModal(false),
        () => setShowErrorModal(true),
        'problems',
    );

    const isLoggedIn = useIsLoggedIn();

    useLayoutEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView();
        }
    }, []);

    if (id) return <NetworkingHubProblemSingle />;

    return (
        <>
            <Meta data={metaData.networkingHubProblems} />
            <div ref={scrollRef} />
            <FilterSubHeading
                id="challenges"
                title="Challenges"
                subText="Create or view posts about battery-related challenges"
            >
                <div className="filter-box-wrapper flex-row flex justify-evenly align-center flex-column-lm align-stretch-lm">
                    <div className="flex-row align-center flex-column-pt flex-4-pt align-stretch-pt">
                        <div className="flex-column hub-filters">
                            <MultiSelect
                                name="selectedOptions"
                                value={form.selectedOptions}
                                placeholder="Please select your filter tags"
                                options={specialistSectorsSelectListNames}
                                onChange={handleChange}
                                extraClasses="grey filter-bar column"
                                formFieldClasses="no-margin"
                            />
                            <TextArea
                                name="searchString"
                                onChange={handleChange}
                                placeholder="Search by title or summary"
                                extraClasses="grey filter-bar-search no-margin"
                            />
                        </div>
                        {isLoggedIn && (
                            <ActionButton
                                extraClasses="filter-box-button"
                                color="orange"
                                onClick={() => setShowSubmissionModal(true)}
                            >
                                Submit a challenge
                            </ActionButton>
                        )}
                    </div>
                </div>
            </FilterSubHeading>

            <div className="container page-bottom-margin">
                <DataCheck
                    data={caseStudiesToShow.items}
                    isFetching={isFetching}
                    error={error}
                    noDataMessage="There are no problems to display"
                >
                    <div className="flex-row flex-wrap">
                        {caseStudiesToShow.items.map(solution => (
                            <NetworkingHubCaseStudyListCard
                                key={solution.id}
                                item={solution}
                                urlPrefix="problems"
                            />
                        ))}
                    </div>

                    {caseStudiesToShow.total > amountToShow && (
                        <ButtonRow>
                            <ActionButton color="grey" onClick={showMoreItems}>
                                Load more News Articles
                            </ActionButton>
                        </ButtonRow>
                    )}
                </DataCheck>

                {showSubmissionModal && (
                    <NetworkingHubSubmitProblemModal
                        closeModal={() => setShowSubmissionModal(false)}
                    />
                )}

                {showErrorModal && (
                    <ErrorModal error={postError} closeModal={() => setShowErrorModal(false)} />
                )}
            </div>
        </>
    );
};

export default NetworkingHubProblemsList;
