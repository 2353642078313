import { createAction } from '@reduxjs/toolkit';

export const addFieldError = createAction('addFieldError');
export const addFormError = createAction('addFormError');
export const clearFormError = createAction('clearFormError');
export const removeFieldError = createAction('removeFieldError');
export const clearFieldErrors = createAction('clearFieldErrors');
export const setFieldErrors = createAction('setFieldErrors');
export const setErrorsVisible = createAction('setErrorsVisible');

export const showFieldErrors = () => dispatch => {
    dispatch(setErrorsVisible(true));

    setTimeout(() => {
        dispatch(setErrorsVisible(false));
    }, 1000);
};
