import useForgotPassword from './hooks/useForgotPassword';

import ButtonRow from 'lib/src/components/button/ButtonRow';
import ActionButton from 'lib/src/components/button/ActionButton';
import Form from 'lib/src/components/form/Form';
import FormRow from 'lib/src/components/form/FormRow';
import TextInput from 'lib/src/components/form/TextInput';
import FormField from 'lib/src/components/form/FormField';

const ForgotPasswordForm = () => {
    const {
        form: { email },
        handleChange,
        handleSubmit,
        error,
        isPosting,
        showSuccessMessage,
    } = useForgotPassword();

    return showSuccessMessage ? (
        <div className="container flex-row justify-center wysiwyg">
            <h2>
                If you have registered using this email address, you will receive an email with a
                link to reset your password.
            </h2>
        </div>
    ) : (
        <Form
            onSubmit={handleSubmit}
            isPosting={isPosting}
            extraClasses="flex-6 flex-12-sd"
            omitButtons
        >
            <FormRow>
                <FormField error={error}>
                    <TextInput
                        type="email"
                        name="email"
                        value={email}
                        onChange={handleChange}
                        placeholder="Email address *"
                        extraClasses="auth-form-field"
                        required
                    />
                </FormField>
            </FormRow>
            <ButtonRow alignment="right">
                <ActionButton isPosting={isPosting} color="grey">
                    Submit
                </ActionButton>
            </ButtonRow>
        </Form>
    );
};

export default ForgotPasswordForm;
