// alignment options are: left, center, right
const Title = ({ children, tag = 'h1', alignment = '', className = '', ...props }) => {
    const Tag = tag;

    return (
        <Tag className={`page-title ${alignment} ${className}`} {...props}>
            {children}
        </Tag>
    );
};

export default Title;
