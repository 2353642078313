import useContactSubmissionPost from './hooks/useContactSubmissionPost';

import Form from 'lib/src/components/form/Form';
import TextInput from 'lib/src/components/form/TextInput';
import TextArea from 'lib/src/components/form/TextArea';
import ActionButton from 'lib/src/components/button/ActionButton';
import FormRow from 'lib/src/components/form/FormRow';
import FormField from 'lib/src/components/form/FormField';

const ContactUsForm = () => {
    const {
        form: { name, email, message },
        handleChange,
        handleSubmit,
        isPosting,
        showMessage,
    } = useContactSubmissionPost();

    return !showMessage ? (
        <Form extraClasses="flex-11 flex-10-pt flex-11-lm" omitButtons onSubmit={handleSubmit}>
            <FormRow extraClasses="flex-column-lm">
                <TextInput
                    name="name"
                    value={name}
                    placeholder="Name*"
                    onChange={handleChange}
                    required
                    extraClasses="auth-form-field"
                />
                <TextInput
                    name="email"
                    value={email}
                    placeholder="Email*"
                    onChange={handleChange}
                    type="email"
                    required
                    extraClasses="auth-form-field"
                />
            </FormRow>
            <FormField>
                <TextArea
                    name="message"
                    value={message}
                    placeholder="Message*"
                    onChange={handleChange}
                    required
                    extraClasses="auth-form-field no-margin"
                />
            </FormField>

            <ActionButton disabled={isPosting} type="submit" color="grey">
                Submit
            </ActionButton>
        </Form>
    ) : (
        <div className="wysiwyg page-section">
            <h2>Contact submission sent!</h2>
            <p>
                Thank you for contacting the Cross-Sector Battery Systems team. We will get back to
                you soon. In the meantime, please feel free to use our Networking Hub to share your
                challenge or solution with members of this community.
            </p>
        </div>
    );
};

export default ContactUsForm;
