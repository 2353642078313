import { createReducer } from '@reduxjs/toolkit';

import {
    postLoginRequest,
    postLoginSuccess,
    postLoginFailure,
    postRegisterRequest,
    postRegisterSuccess,
    postRegisterFailure,
    postForgotPasswordFailure,
    postForgotPasswordRequest,
    postForgotPasswordSuccess,
    validateGuidFailure,
    validateGuidRequest,
    validateGuidSuccess,
} from '@actions/auth';
import {
    postUpdateUserProfileFailure,
    postUpdateUserProfileRequest,
    postUpdateUserProfileSuccess,
} from '@actions/auth/updateUserProfile';
import {
    fetchSingleLoggedInUserFailure,
    fetchSingleLoggedInUserRequest,
    fetchSingleLoggedInUserSuccess,
    postResetPasswordFailure,
    postResetPasswordRequest,
    postResetPasswordSuccess,
} from '@actions/auth/index';

const initialState = {
    isPosting: false,
    isFetching: false,
    postSuccess: false,
    error: null,
    validateGuidIsFetching: false,
    validateGuidSuccess: false,
    user: {},
};

export default createReducer(initialState, {
    [postLoginRequest]: handlePostRequest,
    [postLoginSuccess]: handlePostSuccess,
    [postLoginFailure]: handleFailure,
    [postRegisterRequest]: handlePostRequest,
    [postRegisterSuccess]: handlePostSuccess,
    [postRegisterFailure]: handleFailure,
    [postForgotPasswordRequest]: handlePostRequest,
    [postForgotPasswordSuccess]: handlePostSuccess,
    [postForgotPasswordFailure]: handleFailure,
    [validateGuidRequest]: handleGuidIsFetching,
    [validateGuidSuccess]: handleGuidSuccess,
    [validateGuidFailure]: handleFailure,
    [postUpdateUserProfileRequest]: handlePostRequest,
    [postUpdateUserProfileSuccess]: handlePostUpdateUserSuccess,
    [postUpdateUserProfileFailure]: handleFailure,
    [fetchSingleLoggedInUserRequest]: handleIsFetching,
    [fetchSingleLoggedInUserSuccess]: handleFetchUserSuccess,
    [fetchSingleLoggedInUserFailure]: handleFailure,
    [postResetPasswordRequest]: handlePostRequest,
    [postResetPasswordSuccess]: handlePostSuccess,
    [postResetPasswordFailure]: handleFailure,
});

function handleIsFetching(state) {
    state.isFetching = true;
    state.error = false;
}

function handleFetchUserSuccess(state, action) {
    state.isFetching = false;
    state.user = action.payload;
}

function handlePostRequest(state) {
    state.isPosting = true;
    state.postSuccess = false;
    state.error = null;
}

function handlePostSuccess(state) {
    state.isPosting = false;
    state.postSuccess = true;
}

function handleFailure(state, action) {
    state.isPosting = false;
    state.error = action.payload;
}

function handleGuidIsFetching(state) {
    state.validateGuidIsFetching = true;
    state.error = null;
}

function handleGuidSuccess(state) {
    state.validateGuidIsFetching = false;
    state.validateGuidSuccess = true;
}

function handlePostUpdateUserSuccess(state, action) {
    state.isPosting = false;
    state.postSuccess = true;
    state.user = action.payload;
}
