import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';

// Login
export const fetchSingleNewsRequest = createAction('fetchSingleNewsRequest');
export const fetchSingleNewsSuccess = createAction('fetchSingleNewsSuccess');
export const fetchSingleNewsFailure = createAction('fetchSingleNewsFailure');

export const fetchSingleNews = newsID => async dispatch => {
    dispatch(fetchSingleNewsRequest());

    try {
        const { data } = await api.get(`news/${newsID}`);
        return dispatch(fetchSingleNewsSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchSingleNewsFailure, e);
    }
};
