import { metaData } from 'src/constants/meta';

import Banner from '@components/banner/Banner';
import RegisterFormContainer from './RegisterFormContainer';

import RegisterImage from '@content/images/auth/register/Battery_image.jpg';
import FooterImage from '@content/images/auth/register/Footer-Image.jpg';
import Meta from '@components/meta/Meta';

const Register = () => (
    <>
        <Meta data={metaData.register} />
        <div className="container">
            <Banner title="Register" image={RegisterImage} />
        </div>

        <section className="auth-subheading">
            <div className="container page-section">
                <h2>Register to join the Cross-Sector Battery Systems Innovation Network</h2>
                <h3>Benefits:</h3>
                <ul>
                    <li>
                        Receive our newsletter including latest news, reports and funding updates
                    </li>
                    <li>Post and view battery related solutions and challenges</li>
                    <li>
                        Become part of a growing community which will be sharing knowledge around
                        the challenges and opportunities associated with batteries for a broad range
                        of sectors
                    </li>
                </ul>
            </div>
        </section>

        <div id="auth-page" className="container">
            <RegisterFormContainer />
        </div>

        <div className="page-bottom-image">
            <img
                src={FooterImage}
                alt="Light illustrating a networks across a city"
                className="image-fit"
            />
        </div>
    </>
);

export default Register;
