import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';

// Login
export const validateGuidRequest = createAction('validateGuidRequest');
export const validateGuidSuccess = createAction('validateGuidSuccess');
export const validateGuidFailure = createAction('validateGuidFailure');

export const validateGuid = guid => async dispatch => {
    dispatch(validateGuidRequest());
    try {
        const { data } = await api.get(`auth/password/${guid}`);

        return dispatch(validateGuidSuccess(data.token));
    } catch (e) {
        handleApiErrors(dispatch, validateGuidFailure, e);
    }
};
