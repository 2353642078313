import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchSimilarNewsList } from '@actions/news/index';
import { getSimilarNews, getSimilarNewsError, getSimilarNewsIsFetching } from '@selectors/news';

const useSimilarNewsFetch = newsID => {
    const dispatch = useDispatch();
    const similarNews = useSelector(getSimilarNews);
    const isFetchingSimilarNews = useSelector(getSimilarNewsIsFetching);
    const similarNewsError = useSelector(getSimilarNewsError);

    useEffect(() => {
        dispatch(fetchSimilarNewsList(newsID));
    }, [dispatch, newsID]);

    return [similarNews, isFetchingSimilarNews, similarNewsError];
};

export default useSimilarNewsFetch;
