import AeroSpaceImage from '@content/images/cross-sectoral-opportunities/aerospace.png';
import AutomotiveImage from '@content/images/cross-sectoral-opportunities/automotive.jpg';
import ChemSupplyChainImage from '@content/images/cross-sectoral-opportunities/chem-supply-chain.png';
import DefenceImage from '@content/images/cross-sectoral-opportunities/defence.jpg';
import EndOfLifeImage from '@content/images/cross-sectoral-opportunities/end-of-life.png';
import MaritimeImage from '@content/images/cross-sectoral-opportunities/maritime.jpg';
import RailImage from '@content/images/cross-sectoral-opportunities/rail.jpg';
import StationaryStorageImage from '@content/images/cross-sectoral-opportunities/stationary-storage.png';

export const crossSectoralOpportunitiesData = [
    {
        id: 1,
        subheading: 'Aerospace',
        body: `<p>Electric flight will not only enable carbon emission reduction, but will also develop a new era of quieter and more environmentally-friendly transport. Electric power can support vertical take-off and landing (eVTOL), and short take off and landings (STOL). This will allow aircrafts to take off and land in cities and urban areas silently and without emissions.</p><br /> 
            <p>Commercially available battery cells now allow flight times up to an hour, and these flight times are steadily increasing with continuing innovations in storage energy density. In general, batteries for aerospace are driven by high energy requirements, however they are sensitive to power at a weight limit.</p><br />
            <p>Watch our webinar on ‘<a href='https://ktn-uk.org/events/future-flight-fridays-batteries-for-aviation/' target='_blank' rel=”noreferrer” >Batteries for Aviation</a>’, with contributions from Rolls-Royce, Ricardo and Vertical Aerospace, to learn more about the opportunities for batteries in this sector.</p>`,
        image: AeroSpaceImage,
        preview: `<p>Electric flight will not only enable carbonemission reduction, but will also develop a new era of quieter and more environmentally-friendly transport. Electric power can supportvertical take-off and landing (eVTOL), and short take off and landings (STOL). </p>`,
        headingColour: 'green',
        bodyColour: 'bright-green',
    },
    {
        id: 2,
        subheading: 'Automotive',
        body: `<p>The global automotive industry is moving from internal combustion vehicles towards electric vehicles. The UK is at the forefront of this transition to the electrification of road transport. According to the Government’s ‘Green Plan’ new petrol and diesel cars will be banned from sale from 2030.</p><br /> 
            <p>The key drivers for batteries, for the volume automotive sector (private ownership), are lower pack level costs and better continuous charge acceptance, whilst maintaining or improving energy density levels.</p>
            <p>Read APC's latest <a href='https://www.apcuk.co.uk/technology-roadmaps/' target='_blank' rel='noreferrer'>technology roadmaps</a> for automotive propulsion systems to 2040.</p>`,
        image: AutomotiveImage,
        preview: `<p>The global automotive industry is moving from internal combustion vehicles towards electric vehicles. The UK is at the forefront of thistransition to the electrification of road transport. According to the Government’s ‘Green Plan’ new petrol and diesel cars</p>`,
        headingColour: 'dark-green',
        bodyColour: 'white',
    },
    {
        id: 3,
        subheading: 'Chemical Supply Chain',
        body: `<p>A <a href='https://ktn-uk.org/wp-content/uploads/2020/08/Automotive-Batteries-Report-Summary-April-2019.pdf' target='_blank' rel='nooopener'>2018 report</a> examining the UK Chemical Supply Chain for Battery Manufacture has found that the UK chemical sector could capture a £4.8bn per annum market share by 2030, meeting the needs of UK-built vehicles alone. Currently, three-fifths of a vehicle battery pack’s value is within  the  chemicals  and  materials.  With  the  UK  boasting  some  of  the  largest  suppliers  of materials to produce cathodes, anodes and electrolytes, the UK is well-placed to capitalise on this.</p><br /> 
            <p>There is a real opportunity to expand existing UK capabilities and grow the capacity to support volume production of batteries built here in the UK. Through strategic government support and collaboration between the end-use sectors and the chemicals supply chain, there is potential for domestic production to grow exports, especially as EU battery production grows.</p><br />
            <p>For battery cell manufacturing to be economically viable there is a need for local suppliers of different materials. However, the expectation is that battery chemistry will evolve over the next decade, so it is principal that the companies involved within this supply chain are primed for innovation as well as manufacturing investment.
            </p>
            `,
        image: ChemSupplyChainImage,
        preview: `<p>A 2018 report examining the UK Chemical Supply Chain for Battery Manufacture has found that the UK chemical sector couldcapture a £4.8bn per annum market share by 2030, meeting the needs of UK-built vehicles alone. Currently, three-fifths of a vehicle</p>`,
        headingColour: 'bright-green',
        bodyColour: 'grey',
    },
    {
        id: 4,
        subheading: 'Defence',
        body: `<p>The defence sector has the opportunity to become a key early adopter of batteries that are not yet commercially viable for other markets. DSTL and the British Army’s Future Force Development team have identified a list of innovation needs. These include:</p><br /> 
            <ul>
                <li>Ruggedisation to address:
                <ul>
                    <li>Deployability into harsh environments (hot, cold, wet, dirty, impact, vibrations, etc)</li>
                    <li>Survivability (from kinetic damage and fire, electromagnetic pulse, etc.) with the ability to operate if damaged.</li>
                    <li>Reliability (reducing logistics burden, longer longevity, less failure rate)</li>
                </ul>
                </li>
                <li>Other technical characteristic improvements to address:
                    <ul>
                    <li>Emerging use-cases, such as future hybrid and electrical land vehicles, energy storage over deployed generation and other defence systems.</li>
                    <li>Standardisation and interoperability</li>
                    <li>Weight and size reduction, particularly for dismounted soldiers or air systems.</li>
                    <li>Increased energy density to allow longer use, higher voltages and also batteries which can meet the need for surges of energy.</li>
                    <li>Legacy systems, such as replacing older vehicle batteries, with better modern solutions.</li>
                    </ul>
                </li>
            </ul>
            <p>Watch our webinar on <a href='https://ktn-uk.org/events/cross-sector-battery-systems-innovation-network/' target='_blank' rel='noreferrer'>’Batteries for Defence'</a>, with contributions from DSTL and BAE Systems, to learn more about the opportunities for batteries in this sector.</p>`,
        image: DefenceImage,
        preview: `<p>The defence sector has the opportunity to become a key early adopter of batteries that are not yet commercially viable for othermarkets. DSTL and the British Army’s Future Force Development team have identified a list of innovation needs. These include:</p>`,
        headingColour: 'bright-green',
        bodyColour: 'grey',
    },
    {
        id: 5,
        subheading: 'Maritime',
        body: `<p>An entirely electric vessel for ocean voyages may still not be a foreseeable scenario. However, the shipping industry is exploring battery-powered options, confirmed by an increasing interest in the development of electric vessels.</p><br /> 
            <p>With the current battery technology, all-electric vessels will continue to be used primarily for shorter distances in harbours and coastal shipping. However, the industry is indeed increasingly seeing deep-sea vessels using batteries to optimise their power use – both in terms of propulsion and auxiliary power – to save fuel and reduce maintenance costs.</p>
            <p>Watch our webinar on <a href='https://ktn-uk.org/events/cross-sector-battery-systems-innovation-network-batteries-for-maritime/' target='_blank' rel='noreferrer'>’Batteries for Marine'</a>, with contributions from British Marine, Artemis Technologies and the Port of London Authority, to learn more about the opportunities for batteries in this sector.</p>`,
        image: MaritimeImage,
        preview: `<p>An entirely electric vessel for ocean voyages may still not be a foreseeable scenario. However, the shipping industry is exploringbattery-powered options, confirmed by anincreasing interest in the development ofelectric vessels. With the current battery</p>`,
        headingColour: 'green',
        bodyColour: 'bright-green',
    },
    {
        id: 6,
        subheading: 'Rail',
        body: `<p>The UK Government has set out a challenge for the rail industry to decarbonise the network by 2040. This means that the rail industry will need to replace diesel passenger trains with those that use electricity, hydrogen or batteries. This requires better understanding of which solutions are best suited to various parts of the rail network.</p><br /> 
            <p>According to the Railway Industry Association (RIA), for intensively used lines, electrification is the optimal solution, whilst for routes with lower traffic levels, new technologies like hydrogen and batteries have a role to play. To support the transition towards electrification, bi-mode and battery hybrid trains will be utilised in the interim.</p>
            <p>Watch our webinar on <a href='https://ktn-uk.org/events/cross-sector-battery-systems-innovation-network-batteries-for-rail/' target='_blank' rel='noreferrer'>’Batteries for Rail’</a>, with contributions from the Railway Industry Association, Angel Trains, and WMG, to learn more about the opportunities for batteries in this sector.</p>`,
        image: RailImage,
        preview: `<p>The UK Government has set out a challenge for the rail industry to decarbonise the network by 2040. This means that the rail industry will need to replace diesel passenger trains with those that use electricity, hydrogen or batteries. This requires better understanding of</p>`,
        headingColour: 'dark-green',
        bodyColour: 'white',
    },
    {
        id: 7,
        subheading: 'End-of-life',
        body: `<p>As the use of high-performance batteries increases, there is a growing need to consider end-of-life strategies. This is in part due to the hazardous nature of batteries but also the opportunity to recover the critical materials batteries are made from. End-of-life approaches could include:</p><br /> 
            <ul>
                <li>Reuse: battery is used again in a product</li>
                <li>Repair: battery performance and/or parts are repaired so the battery can be used again in a product</li>
                <li>Remanufacture: battery parts are separated and used again</li>
                <li>Recycle: battery is processed in to materials that can be used again</li>
            </ul>
            <p>Through the Faraday Battery Challenge, projects have been funded to evaluate end of life strategies, you can find out more about the Faraday projects 
            <a href='https://www.ukri.org/wp-content/uploads/2021/10/UKRI-051021-FaradayBatteryChallengeFundedProjectsBookletSept2021.pdf' target='_blank' rel='noreferrer'>here</a>. In addition, a recent report produced by 
            WMG, supported by the Advanced Propulsion Centre Electrical Energy Storage Spoke and the High Value Manufacturing Catapult explores Automotive Lithium ion Battery Recycling in the UK, the report 
            can be found <a href='https://warwick.ac.uk/fac/sci/wmg/business/transportelec/22350m_wmg_battery_recycling_report_v7.pdf' target='_blank' rel='noreferrer'>here</a>.</p>
            <p>Addressing end-of-life challenges may have benefits beyond sustainability and circularity by providing access to and security of critical raw material supply. Batteries contain a number of raw materials, for example, lithium and cobalt. If these materials can be isolated and returned to the chemical and material supply chains, it will provide additional supply options.</p>
            `,
        image: EndOfLifeImage,

        preview: `<p>As the use of high-performance batteries increases, there is a growing need to consider end-of-life strategies. This is in part due to the hazardous nature of batteries but also the opportunity to recover the critical materials batteries are made from. End-of-life</p>`,
        headingColour: 'bright-green',
        bodyColour: 'white',
    },
    {
        id: 8,
        subheading: 'Stationary Storage',
        body: `<p>The international market for stationary battery storage systems is growing rapidly. Over the last decade, grid-connected battery systems have progressed from a niche market to a global one. The International Renewable Energy Agency (IRENA) suggests that a total storage capacity of up to 420GWh will be installed by 2030.</p><br /> 
                <p>Grid-connected storage systems are used in various ways, ranging from small-scale applications, such as residential home storage systems, to multi-megawatt batteries that provide balancing services and mitigate grid congestion problems on all voltage levels.</p><br />
                <p>Stationary storage is a cost sensitive sector and will be dominated by total operating cost requirements.</p>
            `,
        image: StationaryStorageImage,
        preview: `<p>The international market for stationary battery storage systems is growing rapidly. Over the last decade, grid-connected battery systems have progressed from a niche market to a global one. The International Renewable Energy Agency (IRENA) suggests that a </p>`,
        headingColour: 'dark-green',
        bodyColour: 'grey',
    },
];
