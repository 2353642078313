import React from 'react';
import { useSelector } from 'react-redux';

import { getConnectionRequestsIsPosting } from '@selectors/connectionRequests';

import Modal from 'lib/src/components/modal/Modal';
import Form from 'lib/src/components/form/Form';

const NetworkingHubIntroductionRequestModal = ({ handleSubmit, closeModal }) => {
    const isPosting = useSelector(getConnectionRequestsIsPosting);

    return (
        <Modal title="Send introduction request" closeModal={closeModal}>
            <div className="wysiwyg description">
                <p>Would you like to send an introduction request?</p>
            </div>

            <Form
                isPosting={isPosting}
                onSubmit={handleSubmit}
                onCancel={closeModal}
                submitButtonColor="green"
            />
        </Modal>
    );
};

export default NetworkingHubIntroductionRequestModal;
