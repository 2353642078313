import { Route, Switch, useRouteMatch } from 'react-router-dom';

import News from '@pages/news/news/list/News';
import SingleNews from '@pages/news/news/single/SingleNews';

const NewsRoutes = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`}>
                <News />
            </Route>
            <Route exact path={`${path}/:id`}>
                <SingleNews />
            </Route>
        </Switch>
    );
};

export default NewsRoutes;
