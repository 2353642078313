import { Link } from 'react-router-dom';

const NetworkingHubHowItWorks = () => {
    return (
        <>
            <div className="sub-heading-container clickable">
                <div className="container flex-row align-center justify-between align-stretch-lm flex-2">
                    <div className="sub-heading-title-container flex-column-lm justify-center">
                        <h2>How it works</h2>
                    </div>
                </div>
            </div>
            <div className="container page-section">
                <section className="how-it-works-wrapper page-bottom-margin">
                    <div className="page-section flex-row justify-between flex-column-lm">
                        <div className="info-section wysiwyg flex-6">
                            <p>
                                The networking hub platform is designed to share real world
                                challenges, match suppliers and develop an eco system to breed
                                innovation.
                                <br />
                                <br />
                                Simply navigate in the different sections and request to connect.
                            </p>
                        </div>
                        <div className="number-section wysiwyg flex-6 flex-column">
                            <ol>
                                <li>
                                    Create posts about your battery related challenges or solutions
                                </li>
                                <li>
                                    View battery-related solutions and challenges that other members
                                    have posted
                                </li>
                                <li>
                                    Start networking by clicking on ‘Send an introduction request’
                                    at the bottom of each post
                                </li>
                                <li>
                                    Check your <Link to="/dashboard">dashboard</Link> to manage and
                                    engage with your new connections
                                </li>
                            </ol>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default NetworkingHubHowItWorks;
