import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchUsersSolutionCaseStudies } from '@actions/caseStudies';
import {
    getCaseStudiesIsFetching,
    getUsersSolutionCaseStudies,
    getCaseStudiesError,
} from '@selectors/caseStudies';

export default function useFetchSolutionCaseStudies({ id }) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchUsersSolutionCaseStudies(id));
    }, [dispatch, id]);

    const isFetching = useSelector(getCaseStudiesIsFetching);
    const caseStudies = useSelector(getUsersSolutionCaseStudies);
    const error = useSelector(getCaseStudiesError);

    return { caseStudies, isFetching, error };
}
