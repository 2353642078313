import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useForm from 'lib/src/hooks/useForm';
import usePrevious from 'lib/src/hooks/usePrevious';

import { getAuthError, getAuthIsPosting, getAuthPostSuccess } from '@selectors/auth';
import { postForgotPassword } from '@actions/auth/index';

export default function useForgotPassword() {
    const dispatch = useDispatch();

    const [form, handleChange] = useForm({ email: '' });
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const handleSubmit = useCallback(() => {
        dispatch(postForgotPassword(form));
    }, [dispatch, form]);

    const isPosting = useSelector(getAuthIsPosting);
    const error = useSelector(getAuthError);
    const postSuccess = useSelector(getAuthPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            setShowSuccessMessage(true);
        }
    }, [postSuccess, prevPostSuccess]);

    return {
        form,
        handleChange,
        handleSubmit,
        error,
        isPosting,
        showSuccessMessage,
    };
}
