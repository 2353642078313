const mediaQueries = {
    lgDesktop: 1599,
    mdDesktop: 1279,
    smDesktop: 1099,
    lsTablet: 1023,
    ptTablet: 800,
    lgMobile: 767,
    mdMobile: 479,
    smMobile: 413,
};

export default mediaQueries;
