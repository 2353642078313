import { useState } from 'react';

export default function useForm(initialData) {
    const [formData, setFormData] = useState(initialData);

    function handleChange(name, value) {
        setFormData(prev => ({ ...prev, [name]: value }));
    }

    function resetData(data) {
        setFormData(data);
    }

    return [formData, handleChange, resetData];
}
