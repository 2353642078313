import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';

export const fetchProblemCaseStudiesListRequest = createAction(
    'fetchProblemCaseStudiesListRequest',
);
export const fetchProblemCaseStudiesListSuccess = createAction(
    'fetchProblemCaseStudiesListSuccess',
);
export const fetchProblemCaseStudiesListFailure = createAction(
    'fetchProblemCaseStudiesListFailure',
);

export const fetchProblemCaseStudiesList = () => async dispatch => {
    dispatch(fetchProblemCaseStudiesListRequest());

    try {
        const { data } = await api.get('ProblemCaseStudies');

        return dispatch(fetchProblemCaseStudiesListSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchProblemCaseStudiesListFailure, e);
    }
};
