import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';

export const postUpdateSolutionCaseStudyRequest = createAction(
    'postUpdateSolutionCaseStudyRequest',
);
export const postUpdateSolutionCaseStudySuccess = createAction(
    'postUpdateSolutionCaseStudySuccess',
);
export const postUpdateSolutionCaseStudyFailure = createAction(
    'postUpdateSolutionCaseStudyFailure',
);

export const postUpdateSolutionCaseStudy = (id, postBody) => async dispatch => {
    dispatch(postUpdateSolutionCaseStudyRequest());

    try {
        const { data } = await api.patch(`SolutionCaseStudies/${id}`, postBody);
        return dispatch(postUpdateSolutionCaseStudySuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, postUpdateSolutionCaseStudyFailure, e);
    }
};
