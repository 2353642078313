import React from 'react';
import { useSelector } from 'react-redux';

import { enumToFormOptions } from 'lib/src/utils/generic';
import { specialistSectors } from 'lib/src/constants/enums';

import { getCaseStudiesIsPosting } from '@selectors/caseStudies';

import Form from 'lib/src/components/form/Form';
import FormRow from 'lib/src/components/form/FormRow';

import TextInput from 'lib/src/components/form/TextInput';
import TextArea from 'lib/src/components/form/TextArea';
import WYSIWYG from 'lib/src/components/form/WYSIWYG';
import MultiSelect from 'lib/src/components/form/MultiSelect';
import Checkbox from 'lib/src/components/form/Checkbox';
import useNetworkingHubCaseStudyForm from './hooks/useNetworkingHubCaseStudyForm';

const NetworkingHubCaseStudyForm = ({ handleSubmit, onCancel }) => {
    const {
        form,
        handleChange,
        postBody,
        showYouTubeLink,
        showVimeoLink,
        setShowYouTubeLink,
        setShowVimeoLink,
    } = useNetworkingHubCaseStudyForm();

    const isPosting = useSelector(getCaseStudiesIsPosting);

    const sectorOptions = enumToFormOptions(specialistSectors);

    return (
        <Form
            isPosting={isPosting}
            onSubmit={() => handleSubmit(postBody)}
            onCancel={onCancel}
            submitButtonColor="green"
        >
            <FormRow>
                <TextInput
                    name="title"
                    value={form.title}
                    onChange={handleChange}
                    label="Title"
                    required
                />
            </FormRow>
            <FormRow>
                <TextArea
                    name="summary"
                    value={form.summary}
                    onChange={handleChange}
                    label="Summary"
                    required
                />
            </FormRow>

            <FormRow>
                <WYSIWYG
                    name="description"
                    value={form.description}
                    onChange={handleChange}
                    label="Description"
                    required
                    isPublicFacing
                />
            </FormRow>
            <FormRow>
                <MultiSelect
                    name="sectors"
                    value={form.sectors}
                    onChange={handleChange}
                    label="Sectors"
                    options={sectorOptions}
                    required
                />
            </FormRow>
            <FormRow extraClasses="flex-column-lm">
                <Checkbox
                    name="youTubeLink"
                    value={showYouTubeLink}
                    onChange={() => setShowYouTubeLink(!showYouTubeLink)}
                    label="Attach YouTube Link?"
                    disabled={showVimeoLink}
                />
                <Checkbox
                    name="vimeoLink"
                    value={showVimeoLink}
                    onChange={() => setShowVimeoLink(!showVimeoLink)}
                    label="Attach Vimeo Link?"
                    disabled={showYouTubeLink}
                />
            </FormRow>
            <FormRow>
                {showYouTubeLink && (
                    <TextInput
                        name="youTubeLink"
                        value={form.youTubeLink}
                        onChange={handleChange}
                        label="YouTube Link"
                        placeholder="YouTube Link"
                    />
                )}
                {showVimeoLink && (
                    <TextInput
                        name="vimeoLink"
                        value={form.vimeoLink}
                        onChange={handleChange}
                        label="Vimeo Link"
                        placeholder="Vimeo Link"
                    />
                )}
            </FormRow>
        </Form>
    );
};

export default NetworkingHubCaseStudyForm;
