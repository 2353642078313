import React from 'react';
import { useDispatch } from 'react-redux';

import { createProblemCaseStudy } from '@actions/caseStudies';

import Modal from 'lib/src/components/modal/Modal';
import NetworkingHubCaseStudyForm from './NetworkingHubCaseStudyForm';

const NetworkingHubSubmitProblemModal = ({ closeModal }) => {
    const dispatch = useDispatch();

    return (
        <Modal title="Submit a problem" closeModal={closeModal}>
            <div className="wysiwyg description">
                <p>Enter the details below to submit a problem.</p>
            </div>

            <NetworkingHubCaseStudyForm handleSubmit={handleSubmit} onCancel={closeModal} />
        </Modal>
    );

    function handleSubmit(postBody) {
        dispatch(createProblemCaseStudy(postBody));
    }
};

export default NetworkingHubSubmitProblemModal;
