import { useParams } from 'react-router-dom';

import { RAW_S3_STORAGE_URL } from 'src/config';

import Banner from '@components/banner/Banner';

import DataCheck from '@components/dataCheck/DataCheck';
import SingleEventDetails from './SingleEventDetails';

import useSingleEventFetch from './hooks/useSingleEventFetch';

const SingleEvent = () => {
    const { id } = useParams();
    const [event, isFetching, error] = useSingleEventFetch(id);

    return (
        <>
            <DataCheck data={event} isFetching={isFetching} error={error}>
                <div className="container">
                    <Banner
                        title="Events"
                        image={`${RAW_S3_STORAGE_URL}/${event ? event.headerOneImageS3Key : ''}`}
                    />
                </div>

                <SingleEventDetails event={event} />
            </DataCheck>
        </>
    );
};

export default SingleEvent;
