import useForm from 'lib/src/hooks/useForm';
import usePrevious from 'lib/src/hooks/usePrevious';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getAuthIsPosting, getAuthPostSuccess } from '@selectors/auth';

import { postUpdateUserProfile } from '@actions/auth/updateUserProfile';

export default function useUpdateProfile({
    firstName,
    lastName,
    email,
    postcode,
    jobTitle,
    organisationName,
    organisationURL,
    agreedToDataProcessing,
}) {
    const dispatch = useDispatch();
    const history = useHistory();

    const isPosting = useSelector(getAuthIsPosting);
    const postSuccess = useSelector(getAuthPostSuccess);

    const [showSuccessModal, setShowModal] = useState(false);

    const [form, handleChange] = useForm({
        firstName,
        lastName,
        email,
        postcode,
        jobTitle,
        organisationName,
        organisationURL,
        agreedToDataProcessing,
    });

    const prevPostSuccess = usePrevious(postSuccess);

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            setShowModal(true);
        }
    }, [postSuccess, prevPostSuccess, history]);

    function handleSubmit() {
        dispatch(postUpdateUserProfile(form));
    }

    function handleModalClose() {
        setShowModal(false);
    }
    return {
        handleSubmit,
        handleChange,
        isPosting,
        form,
        showSuccessModal,
        handleModalClose,
    };
}
