import dayjs from 'dayjs';

import useIsLoggedIn from 'lib/src/hooks/useIsLoggedIn';

import { getIDFromToken } from 'lib/src/utils/jwt';

import { specialistSectorsNames, connectionStatusValues } from 'lib/src/constants/enums';
import { postedByDateTime } from 'src/constants/dateFormats';
import PageVideo from '@components/pageVideo/PageVideo';
import ActionButton from 'lib/src/components/button/ActionButton';
import LinkButton from 'lib/src/components/button/LinkButton';
import useCheckIntroductionRequest from './hooks/useCheckIntroductionRequest';
import Loading from '@components/loading/Loading';
import Meta from '@components/meta/Meta';
import SanitiseHTML from '@components/sanitiseHTML/SanitiseHTML';

const NetworkingHubCaseStudyDetails = ({
    caseStudy: {
        id,
        createdByName,
        createdByUserID,
        date,
        title,
        summary,
        description,
        vimeoLink,
        youTubeLink,
        sectors,
        organisationName,
    },
    urlPrefix,
    caseStudyType,
    setShowIntroductionModal,
}) => {
    const isLoggedIn = useIsLoggedIn();
    const userID = getIDFromToken();

    const { isFetchingConnections, connectionStatus } = useCheckIntroductionRequest(
        id,
        caseStudyType,
    );

    const metaData = {
        title,
        description: summary,
    };

    return (
        <>
            <Meta data={metaData} />

            <div className="networking-hub-single container page-bottom-margin">
                <div className="page-section">
                    <main className="post-content">
                        <p className="post-details">
                            Posted by{' '}
                            <strong>
                                {createdByName}, {organisationName}
                            </strong>{' '}
                            on <strong>{dayjs(date).format(postedByDateTime)}</strong>
                        </p>

                        {sectors && (
                            <div className="flex-row tag-wrapper">
                                {sectors.map(sector => (
                                    <div key={`${sector}`} className="tag">
                                        {specialistSectorsNames[sector]}
                                    </div>
                                ))}
                            </div>
                        )}

                        <div className="wysiwyg">
                            <h2>{title}</h2>
                            <SanitiseHTML content={description} />

                            <PageVideo vimeoLink={vimeoLink} youTubeLink={youTubeLink} />
                        </div>

                        {isLoggedIn && +userID !== createdByUserID && (
                            <section className="flex-row align-center justify-between button-container large">
                                {!isFetchingConnections ? (
                                    <>
                                        {!connectionStatus && (
                                            <ActionButton
                                                color="yellow"
                                                onClick={() => setShowIntroductionModal(true)}
                                            >
                                                Send introduction request
                                            </ActionButton>
                                        )}

                                        {connectionStatus === connectionStatusValues.pending && (
                                            <ActionButton color="yellow" disabled>
                                                Introduction request pending
                                            </ActionButton>
                                        )}

                                        {connectionStatus === connectionStatusValues.approved && (
                                            <ActionButton color="green" disabled>
                                                Introduction request approved
                                            </ActionButton>
                                        )}
                                    </>
                                ) : (
                                    <Loading />
                                )}
                            </section>
                        )}
                        <section className="flex-row align-center justify-between button-container large">
                            <LinkButton color="grey" href={`/networking-hub/${urlPrefix}`}>
                                Go Back
                            </LinkButton>
                        </section>
                    </main>
                </div>
            </div>
        </>
    );
};

export default NetworkingHubCaseStudyDetails;
