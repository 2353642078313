import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
    getConnectionRequestsIsPosting,
    getConnectionRequestsPostError,
    getConnectionRequestsPostSuccess,
} from '@selectors/connectionRequests';
import { createIntroductionRequest } from '@actions/connectionRequests/index';

import usePrevious from 'lib/src/hooks/usePrevious';

const useCreateIntroductionRequest = (closeModal, showError, type) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const isPosting = useSelector(getConnectionRequestsIsPosting);
    const error = useSelector(getConnectionRequestsPostError);
    const success = useSelector(getConnectionRequestsPostSuccess);

    const prevProps = usePrevious({ success, error });

    const postBody = {
        caseStudyID: +id,
        caseStudyType: type,
    };

    useEffect(() => {
        if (success && !prevProps.success) {
            closeModal();
        }

        if (error && !prevProps.error) {
            closeModal();
            showError();
        }
    }, [success, prevProps.success, error, prevProps.error, closeModal, showError]);

    function handleSubmit() {
        dispatch(createIntroductionRequest(postBody));
    }

    return { isPosting, postError: error, postSuccess: success, handleSubmit };
};

export default useCreateIntroductionRequest;
