import EmailLogo from '@content/images/logos/email-footer-icon.png';
import LinkedInLogo from '@content/images/logos/linked-in-footer-icon.png';
import TwitterLogo from '@content/images/logos/twitter-footer-icon.png';
import KTNFooterLogo from '@content/images/logos/Innovate-UK-Logo-White.png';
import { Link } from 'react-router-dom';

const Footer = () => (
    <footer className="footer">
        <div className="container page-section">
            <div className="contact-wrapper flex-row align-center justify-center-lt">
                <Link to="/contact-us">Contact us</Link>

                <a href="mailto:batterysystems@iuk.ktn-uk.org">
                    <img alt="KTN Innovation Networks" src={EmailLogo} />
                </a>

                <a href="https://www.linkedin.com/company/ktnuk/" target="_blank" rel="noreferrer">
                    <img alt="Linked In Logo" src={LinkedInLogo} />
                </a>

                <a href="https://twitter.com/iuk_ktn" target="_blank" rel="noreferrer">
                    <img alt="Twitter Logo" src={TwitterLogo} />
                </a>
            </div>

            <div className="logos-wrapper flex-row align-center justify-center-lt flex-wrap-lt">
                <img alt="KTN Innovation Networks" src={KTNFooterLogo} />
            </div>

            <p className="info-message">
                This site uses cookies to offer you a better browsing experience. Find out more on
                how we use your personal information in our{' '}
                <Link to="/privacy-policy">Privacy Policy</Link>.
            </p>
        </div>
    </footer>
);

export default Footer;
