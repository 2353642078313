import { useParams } from 'react-router-dom';
import useSingleReportFetch from './hooks/useSingleReportFetch';

import { RAW_S3_STORAGE_URL } from 'src/config';

import Banner from '@components/banner/Banner';
import DataCheck from '@components/dataCheck/DataCheck';

import ReportSingleDetails from './ReportSingleDetails';

function ReportSingle() {
    const { id } = useParams();

    const [report, isFetching, error] = useSingleReportFetch(id);

    return (
        <DataCheck data={report} isFetching={isFetching} error={error}>
            <div className="container">
                <Banner
                    title="Reports"
                    image={`${RAW_S3_STORAGE_URL}/${report ? report.imageS3Key : ''}`}
                />
            </div>

            <ReportSingleDetails report={report} />
        </DataCheck>
    );
}

export default ReportSingle;
