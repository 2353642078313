import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';

// Login
export const fetchSimilarNewsListRequest = createAction('fetchSimilarNewsListRequest');
export const fetchSimilarNewsListSuccess = createAction('fetchSimilarNewsListSuccess');
export const fetchSimilarNewsListFailure = createAction('fetchSimilarNewsListFailure');

export const fetchSimilarNewsList = newsID => async dispatch => {
    dispatch(fetchSimilarNewsListRequest());

    try {
        const { data } = await api.get(`news/${newsID}/similar`);
        return dispatch(fetchSimilarNewsListSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchSimilarNewsListFailure, e);
    }
};
