import axios from 'axios';
import { setFieldErrors } from 'lib/src/redux/actions/fieldErrors';
import { addFormError } from '../redux/actions/fieldErrors';
import { setRedirectUrl } from '../redux/actions/redirects';

const getConfig = () => ({
    headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
});

let API_URL = '';
export const initApi = apiUrl => {
    API_URL = apiUrl;
};

export const api = {
    get: url => axios.get(`${API_URL}/${url}`, getConfig()),
    post: (url, data) => axios.post(`${API_URL}/${url}`, data, getConfig()),
    put: (url, data) => axios.put(`${API_URL}/${url}`, data, getConfig()),
    patch: (url, data) => axios.patch(`${API_URL}/${url}`, data, getConfig()),
    delete: url => axios.delete(`${API_URL}/${url}`, getConfig()),
};

export const handleApiErrors = (dispatch, failureAction, err) => {
    const { response, message } = err;

    if (response && response.status === 400) {
        typeof response.data === 'string'
            ? dispatch(addFormError(response.data))
            : dispatch(setFieldErrors(response.data.errors));

        return dispatch(failureAction(null));
    }
    if (response && response.status === 401) {
        dispatch(failureAction('Unauthorized'));
        localStorage.removeItem('token');
        return dispatch(setRedirectUrl('/auth/login'));
    }

    return dispatch(failureAction(message));
};
