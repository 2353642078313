import dayjs from 'dayjs';
import { RAW_S3_STORAGE_URL } from 'src/config';

import LinkButton from 'lib/src/components/button/LinkButton';
import { eventsDateTime } from 'src/constants/dateFormats';

const WebinarListCard = ({ webinar }) => {
    const startDate = dayjs(webinar.startDate);
    const endDate = dayjs(webinar.endDate);

    return (
        <div className="list-card-container flex-row full-list">
            <div className="post-content flex-column">
                <div className="wysiwyg flex">
                    <h3>{webinar.title}</h3>
                    <h4 style={{ marginTop: 0 }}>
                        {startDate.format(eventsDateTime)} -
                        <br />
                        {endDate.format(eventsDateTime)} GMT
                    </h4>
                    <p>{webinar.summary}</p>
                </div>

                <div className="button-container flex-row align-center">
                    <LinkButton href={`/resources/webinars/${webinar.id}`} color="purple">
                        Read more
                    </LinkButton>
                </div>
            </div>

            <div
                className="image-wrapper"
                style={{ backgroundImage: `url(${RAW_S3_STORAGE_URL}/${webinar.listImageS3Key})` }}
            />
        </div>
    );
};

export default WebinarListCard;
