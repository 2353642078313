import { Link } from 'react-router-dom';

const LinkButton = ({ color = 'blue', href = '', icon, children, extraClasses = '' }) => (
    <Link to={href} className={`button ${color} ${extraClasses && extraClasses}`}>
        {icon && <i className={`icon far fa-fw fa-${icon}`}></i>}
        <span className="text">{children}</span>
    </Link>
);

export default LinkButton;
