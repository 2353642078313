import React from 'react';
import sanitizeHtml from 'sanitize-html';

const SanitiseHTML = ({ content, ...props }) => {
    const sanitisedContent = sanitizeHtml(content);

    return (
        <div
            dangerouslySetInnerHTML={{
                __html: sanitisedContent,
            }}
            {...props}
        />
    );
};

export default SanitiseHTML;
