import dayjs from 'dayjs';

import ButtonRow from 'lib/src/components/button/ButtonRow';
import LinkButton from 'lib/src/components/button/LinkButton';
import ActionButton from 'lib/src/components/button/ActionButton';
import { specialistSectorsNames } from 'lib/src/constants/enums';
import useWindowSize from 'src/hooks/useWindowSize';
import mediaQueries from 'src/constants/mediaQueries';

const DashboardCaseStudyList = ({ caseStudies, handleEditClick, urlPrefix }) => {
    const { width } = useWindowSize();
    let substrLength = null;

    if (width >= mediaQueries.smDesktop) {
        substrLength = 25;
    } else if (width > mediaQueries.ptTablet) {
        substrLength = 15;
    }

    return Object.values(caseStudies).map((caseStudy, index) => (
        <div
            key={index}
            className="flex-6 flex-row align-center justify-between case-study-item-wrapper flex-column-lm align-stretch-lm"
        >
            <div className="flex-column flex-10">
                <div className="content-wrapper flex-row flex align-center flex-column-pt align-stretch-pt">
                    <div className="cs-title">
                        <p>
                            {substrLength ? (
                                <>
                                    {caseStudy.title.length > substrLength
                                        ? caseStudy.title.substr(0, substrLength - 1) + '...'
                                        : caseStudy.title}
                                </>
                            ) : (
                                caseStudy.title
                            )}
                        </p>
                    </div>

                    <div className="date">
                        <p>{dayjs(caseStudy.date).format('DD-MM-YY')}</p>
                    </div>
                </div>
                {caseStudy.sectors && (
                    <div className="flex-row tag-wrapper align-center">
                        {caseStudy.sectors.map((sector, index) => {
                            return (
                                index < 3 && (
                                    <div key={`${sector}-${index}`} className="tag">
                                        {specialistSectorsNames[sector]}
                                    </div>
                                )
                            );
                        })}
                    </div>
                )}
            </div>
            <ButtonRow>
                {caseStudy.isPublished ? (
                    <LinkButton
                        href={`/networking-hub/${urlPrefix}/${caseStudy.id}`}
                        extraClasses="medium-button flex-6"
                        color="green"
                    >
                        View
                    </LinkButton>
                ) : (
                    <ActionButton extraClasses="medium-button flex-6" color="green" disabled>
                        Unpublished
                    </ActionButton>
                )}
                <ActionButton
                    onClick={() => handleEditClick(caseStudy)}
                    extraClasses="medium-button flex-6"
                    color="orange"
                >
                    Edit
                </ActionButton>
            </ButtonRow>
        </div>
    ));
};

export default DashboardCaseStudyList;
