import HomeBannerBg from '@content/images/banners/home-page.png';

import { metaData } from 'src/constants/meta';

import Banner from '@components/banner/Banner';
import HomePods from './HomePods';
import Meta from '@components/meta/Meta';
import CenterBox from './CenterBox';
import GetStarted from './GetStarted';

const Home = () => (
    <>
        <Meta data={metaData.home} />
        <div id="home-page" className="container page-margin-bottom">
            <Banner image={HomeBannerBg} extraClasses="no-margin" />
            <CenterBox />
            <GetStarted />
            <HomePods />
        </div>
    </>
);

export default Home;
