import AboutBannerBg from '@content/images/banners/about-us.png';

import { metaData } from 'src/constants/meta';

import Banner from '@components/banner/Banner';
import Meta from '@components/meta/Meta';
import OurMission from './OurMission';
import OurTeam from './OurTeam';
import FoundingMembers from './FoundingMembers';

const About = () => (
    <>
        <Meta data={metaData.about} />

        <div id="about-page" className="container">
            <Banner image={AboutBannerBg} extraClasses="no-margin" />
            <OurMission />
            <OurTeam />
            <FoundingMembers />
        </div>
    </>
);

export default About;
