import { createReducer } from '@reduxjs/toolkit';

import {
    fetchWebinarsListRequest,
    fetchWebinarsListSuccess,
    fetchWebinarsListFailure,
} from '@actions/webinars';

const initialState = {
    isFetching: false,
    error: null,
    webinars: [],
};

export default createReducer(initialState, {
    [fetchWebinarsListRequest]: handleFetchRequest,
    [fetchWebinarsListFailure]: handleFetchFailure,
    [fetchWebinarsListSuccess]: handleFetchAllSuccess,
});

function handleFetchRequest(state) {
    state.isFetching = true;
    state.error = null;
}

function handleFetchFailure(state, action) {
    state.isFetching = false;
    state.error = action.payload;
}

function handleFetchAllSuccess(state, action) {
    state.isFetching = false;
    state.webinars = action.payload;
}
