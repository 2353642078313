import { Link, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import useIsLoggedIn from 'lib/src/hooks/useIsLoggedIn';

const Navigation = ({ isMobile, hideNavigation = () => {}, logout }) => {
    const isLoggedIn = useIsLoggedIn();
    const location = useLocation();
    const { pathname } = location;

    return (
        <>
            {isMobile && (
                <div className="nav-item">
                    <Link
                        to="/"
                        className={pathname === '/' ? 'active' : ''}
                        onClick={hideNavigation}
                    >
                        Home
                    </Link>
                </div>
            )}
            {pages.map(({ name, slug, subNav }) => (
                <div key={slug} className="flex-row flex nav-item">
                    <Link
                        key={slug}
                        to={slug}
                        className={`flex-row align-center ${
                            pathname.includes(slug) ? 'active' : ''
                        }`}
                        onClick={hideNavigation}
                    >
                        {name}
                    </Link>
                    {subNav && (
                        <div className="sub-nav-container flex-column justify-start">
                            {subNav.map(({ name, slug }) => (
                                <HashLink
                                    key={slug}
                                    className="sub-nav-item flex-column justify-center"
                                    onClick={hideNavigation}
                                    smooth
                                    to={slug}
                                >
                                    {name}
                                </HashLink>
                            ))}
                        </div>
                    )}
                </div>
            ))}
            {isMobile && (
                <>
                    {isLoggedIn ? (
                        <>
                            <div className="nav-item">
                                <Link
                                    to="/dashboard"
                                    className={pathname.includes('/dashboard') ? 'active' : ''}
                                    onClick={hideNavigation}
                                >
                                    My Dashboard
                                </Link>
                            </div>
                            <div className="nav-item">
                                <button
                                    onClick={e => {
                                        logout(e);
                                        hideNavigation();
                                    }}
                                >
                                    Logout
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="nav-item">
                                <Link
                                    to="/auth/login"
                                    className={pathname.includes('/auth/login') ? 'active' : ''}
                                    onClick={hideNavigation}
                                >
                                    Login
                                </Link>
                            </div>
                            <div className="nav-item">
                                <Link
                                    to="/auth/register"
                                    className={pathname.includes('/auth/register') ? 'active' : ''}
                                    onClick={hideNavigation}
                                >
                                    Register
                                </Link>
                            </div>
                        </>
                    )}
                </>
            )}
        </>
    );
};

const pages = [
    {
        name: 'About',
        slug: '/about',
    },
    {
        name: 'Cross Sectoral Opportunities',
        slug: '/cross-sectoral-opportunities',
    },
    {
        name: 'News',
        slug: '/news',
    },
    {
        name: 'Events',
        slug: '/events',
    },
    {
        name: 'Reports',
        slug: '/reports',
    },
    {
        name: 'Networking Hub',
        slug: '/networking-hub/problems',
    },
    {
        name: 'Battery Systems Landscape Map',
        slug: '/battery-systems-landscape-map',
    },
];

export default Navigation;
