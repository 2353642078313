import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Events from '@pages/events/list/Events';
import SingleEvent from '@pages/events/single/SingleEvent';

const EventsRoutes = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`}>
                <Events />
            </Route>
            <Route exact path={`${path}/:id`}>
                <SingleEvent />
            </Route>
        </Switch>
    );
};

export default EventsRoutes;
