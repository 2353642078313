import React from 'react';
import FilterSubHeading from '@components/filterSubHeading/FilterSubHeading';
import TextArea from 'lib/src/components/form/TextArea';
import useForm from 'lib/src/hooks/useForm';
import WebinarsList from './list/Webinars';

function Webinars() {
    const [form, handleChange] = useForm({
        webinarSearchString: '',
    });

    return (
        <>
            <FilterSubHeading id="webinar-outputs" title="Webinar Outputs">
                <div className="filter-box-wrapper flex-row flex align-center flex-column-lm align-stretch-lm">
                    <TextArea
                        name="webinarSearchString"
                        onChange={handleChange}
                        placeholder="Search by title or summary"
                        extraClasses="grey filter-bar-search no-margin"
                    />
                </div>
            </FilterSubHeading>

            <div className="container page-bottom-margin">
                <WebinarsList searchString={form.webinarSearchString} />
            </div>
        </>
    );
}

export default Webinars;
