import { useState } from 'react';

const useShowModal = () => {
    const [modal, setModal] = useState({
        show: false,
        data: {},
    });

    const handleShowModal = (state = false, data = {}) => {
        if (!state) {
            setModal({ ...modal, show: false, data: {} });
        } else {
            setModal({ ...modal, show: true, data });
        }
    };

    return {
        ...modal,
        handleModal: handleShowModal,
    };
};

export default useShowModal;
