import React from 'react';
import { Helmet } from 'react-helmet';

const Meta = ({ data: { title, description }, disableIndex = false }) => (
    <Helmet>
        <title>{title}</title>
        {description && <meta name="description" content={description} />}
        {disableIndex && <meta name="robots" content="noindex"></meta>}
    </Helmet>
);

export default Meta;
