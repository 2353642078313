import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import usePrevious from 'lib/src/hooks/usePrevious';

import {
    getCaseStudiesError,
    getCaseStudiesIsFetching,
    getSingleProblemCaseStudy,
} from '@selectors/caseStudies';

import { caseStudyTypes } from 'lib/src/constants/enums';

import useCreateIntroductionRequest from '../modals/hooks/useCreateIntroductionRequest';

import DataCheck from '@components/dataCheck/DataCheck';
import NetworkingHubCaseStudyDetails from './NetworkingHubCaseStudyDetails';
import NetworkingHubIntroductionRequestModal from '../modals/NetworkingHubIntroductionRequestModal';
import ErrorModal from '@components/modals/ErrorModal';

const NetworkingHubProblemSingle = () => {
    const [showIntroductionModal, setShowIntroductionModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);

    const { postError, handleSubmit } = useCreateIntroductionRequest(
        () => setShowIntroductionModal(false),
        () => setShowErrorModal(true),
        caseStudyTypes.problem,
    );

    const { id } = useParams();
    const history = useHistory();
    const problem = useSelector(state => getSingleProblemCaseStudy(state, id));
    const isFetching = useSelector(getCaseStudiesIsFetching);
    const error = useSelector(getCaseStudiesError);

    const prevProps = usePrevious({ isFetching });

    useEffect(() => {
        if (!isFetching && prevProps.isFetching && !problem) {
            history.replace('/networking-hub/problems');
        }
    }, [isFetching, prevProps.isFetching, problem, history]);

    return (
        <DataCheck data={problem} isFetching={isFetching} error={error}>
            <NetworkingHubCaseStudyDetails
                caseStudy={problem}
                urlPrefix="problems"
                caseStudyType={caseStudyTypes.problem}
                setShowIntroductionModal={setShowIntroductionModal}
            />

            {showIntroductionModal && (
                <NetworkingHubIntroductionRequestModal
                    handleSubmit={handleSubmit}
                    closeModal={() => setShowIntroductionModal(false)}
                />
            )}

            {showErrorModal && (
                <ErrorModal
                    title="There was an error"
                    error={postError}
                    closeModal={() => setShowErrorModal(false)}
                />
            )}
        </DataCheck>
    );
};

export default NetworkingHubProblemSingle;
