import { createReducer } from '@reduxjs/toolkit';
import { convertArrToObj } from 'lib/src/utils/generic';

import {
    fetchNewsListRequest,
    fetchNewsListSuccess,
    fetchNewsListFailure,
    fetchSingleNewsRequest,
    fetchSingleNewsSuccess,
    fetchSingleNewsFailure,
    fetchSimilarNewsListRequest,
    fetchSimilarNewsListSuccess,
    fetchSimilarNewsListFailure,
} from '@actions/news';
import {
    fetchNewsTagsFailure,
    fetchNewsTagsRequest,
    fetchNewsTagsSuccess,
} from '@actions/news/index';

const initialState = {
    isFetching: false,
    error: null,
    news: {},
    isFetchingSimilarNews: false,
    similarNewsError: null,
    similarNews: [],
    newsTags: [],
    filterTags: [],
};

export default createReducer(initialState, {
    [fetchNewsListRequest]: handleFetchRequest,
    [fetchNewsListFailure]: handleFetchFailure,
    [fetchNewsListSuccess]: handleFetchAllSuccess,
    [fetchSingleNewsRequest]: handleFetchRequest,
    [fetchSingleNewsFailure]: handleFetchFailure,
    [fetchSingleNewsSuccess]: handleFetchSingleSuccess,
    [fetchSimilarNewsListRequest]: handleFetchSimilarNewsRequest,
    [fetchSimilarNewsListFailure]: handleFetchSimilarNewsFailure,
    [fetchSimilarNewsListSuccess]: handleFetchSimilarNewsSuccess,
    [fetchNewsTagsRequest]: handleFetchRequest,
    [fetchNewsTagsSuccess]: handleFetchNewsTagsSuccess,
    [fetchNewsTagsFailure]: handleFetchFailure,
});

function handleFetchRequest(state) {
    state.isFetching = true;
    state.error = null;
}

function handleFetchSimilarNewsRequest(state) {
    state.isFetchingSimilarNews = true;
    state.similarNewsError = null;
    state.similarNews = [];
}

function handleFetchFailure(state, action) {
    state.isFetching = false;
    state.error = action.payload;
}

function handleFetchSimilarNewsFailure(state, action) {
    state.isFetchingSimilarNews = false;
    state.similarNewsError = action.payload;
}

function handleFetchAllSuccess(state, action) {
    state.isFetching = false;
    state.news = convertArrToObj(action.payload);
}

function handleFetchSingleSuccess(state, action) {
    state.isFetching = false;
    state.news[action.payload.id] = action.payload;
}

function handleFetchSimilarNewsSuccess(state, action) {
    state.isFetchingSimilarNews = false;
    state.similarNews = action.payload;
}

function handleFetchNewsTagsSuccess(state, action) {
    state.isFetching = false;
    state.newsTags = action.payload;
}
