import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createProblemCaseStudy } from '@actions/caseStudies';
import usePrevious from 'lib/src/hooks/usePrevious';
import useForm from 'lib/src/hooks/useForm';
import {
    getCaseStudiesIsPosting,
    getCaseStudiesPostSuccess,
    getCaseStudiesPostError,
} from '@selectors/caseStudies';

const initialState = {
    title: '',
    summary: '',
    description: '',
    listImageS3Key: '',
    sectors: [],
    youTubeLink: null,
    vimeoLink: null,
    isPublished: false,
};

const useCreateCaseStudy = closeModal => {
    const dispatch = useDispatch();
    const [showYouTubeLink, setShowYouTubeLink] = useState(false);
    const [showVimeoLink, setShowVimeoLink] = useState(false);
    const [formState, handleChange] = useForm(initialState);

    const isPosting = useSelector(getCaseStudiesIsPosting);
    const error = useSelector(getCaseStudiesPostError);
    const postSuccess = useSelector(getCaseStudiesPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);
    const prevProps = usePrevious({ showVimeoLink, showYouTubeLink });

    const handleSubmit = useCallback(() => {
        dispatch(createProblemCaseStudy({ ...formState, date: new Date() }));
    }, [dispatch, formState]);

    useEffect(() => {
        if (prevProps.showVimeoLink && !showVimeoLink) {
            handleChange('vimeoLink', null);
        }
        if (prevProps.showYouTubeLink && !showYouTubeLink) {
            handleChange('youTubeLink', null);
        }
    }, [showVimeoLink, showYouTubeLink, prevProps, handleChange]);

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            closeModal();
        }
    }, [postSuccess, closeModal, prevPostSuccess]);

    return {
        handleSubmit,
        formState,
        handleChange,
        isPosting,
        error,
        showYouTubeLink,
        showVimeoLink,
        setShowYouTubeLink,
        setShowVimeoLink,
    };
};

export default useCreateCaseStudy;
