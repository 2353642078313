import backgroundImage from '@content/images/auth/register/register-background.jpg';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import LinkButton from 'lib/src/components/button/LinkButton';

const RegisterSuccessModal = () => {
    return (
        <div className="modal-overlay registration-modal">
            <div
                className="registration-modal-body flex-column align-center"
                style={{ backgroundImage: `url(${backgroundImage})` }}
            >
                <h2>Registration successful</h2>
                <ButtonRow>
                    <LinkButton color="white" href="/networking-hub">
                        Enter the hub
                    </LinkButton>
                </ButtonRow>
            </div>
        </div>
    );
};

export default RegisterSuccessModal;
