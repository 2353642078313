import { useLocation } from 'react-router-dom';

import NetworkingHubProblemsList from '../list/NetworkingHubProblemsList';
import NetworkingHubSolutionsList from '../list/NetworkingHubSolutionsList';

const NetworkingHubListRoute = () => {
    const location = useLocation();
    const splitUrl = location.pathname.split('/');

    if (splitUrl.includes('problems')) {
        return <NetworkingHubProblemsList />;
    }

    if (splitUrl.includes('solutions')) {
        return <NetworkingHubSolutionsList />;
    }

    return null;
};

export default NetworkingHubListRoute;
