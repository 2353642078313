import { useState } from 'react';

import { homePods } from 'src/constants/pods';

const useHomePods = () => {
    const [activePod, setActivePod] = useState(null);
    const curSelected = homePods.find(pod => pod.title === activePod);

    return [activePod, setActivePod, curSelected];
};

export default useHomePods;
