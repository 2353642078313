import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';

export const postContactSubmissionRequest = createAction('postContactSubmissionRequest');
export const postContactSubmissionSuccess = createAction('postContactSubmissionSuccess');
export const postContactSubmissionFailure = createAction('postContactSubmissionFailure');

export const postContactSubmission = contactSubmission => async dispatch => {
    dispatch(postContactSubmissionRequest());

    try {
        const { data } = await api.post(`ContactEnquiries`, contactSubmission);
        return dispatch(postContactSubmissionSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, postContactSubmissionFailure, e);
    }
};
