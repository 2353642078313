import { useSelector } from 'react-redux';

import { getWebinars } from '@selectors/webinars';

const useWebinarsToShow = (amountToShow, searchString) => {
    const webinars = useSelector(getWebinars);
    const webinarsToShow = getWebinarsToShow();

    function getWebinarsToShow() {
        const webinarsArray = Object.values(webinars);

        const filteredArray = [...webinarsArray]
            .sort((a, b) => new Date(b.endDate) - new Date(a.endDate))
            .filter(({ title, summary }) => {
                if (!searchString) return true;

                const searchStringToLower = searchString.toLowerCase();
                const matchesTitle = title && title.toLowerCase().includes(searchStringToLower);
                const matchesSummary =
                    summary && summary.toLowerCase().includes(searchStringToLower);

                return matchesTitle || matchesSummary;
            });

        return { total: filteredArray.length, items: filteredArray.slice(0, amountToShow) };
    }

    return [webinarsToShow];
};

export default useWebinarsToShow;
