import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';

// Login
export const postResetPasswordRequest = createAction('postResetPasswordRequest');
export const postResetPasswordSuccess = createAction('PoastResetSuccess');
export const postResetPasswordFailure = createAction('postResetPasswordFailure');

export const postResetPassword = (guid, postBody) => async dispatch => {
    dispatch(postResetPasswordRequest());
    try {
        const { data } = await api.post(`auth/password/${guid}`, postBody);

        return dispatch(postResetPasswordSuccess(data.token));
    } catch (e) {
        handleApiErrors(dispatch, postResetPasswordFailure, e);
    }
};
