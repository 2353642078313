import jwtDecode from 'jwt-decode';

export function validateJWT(token) {
    try {
        if (!token) return false;

        const now = new Date().valueOf() / 1000;
        const { exp: expiry } = jwtDecode(token);
        const isValid = expiry > now;
        return isValid;
    } catch {
        return false;
    }
}

export const getJwtToken = () => {
    const token = localStorage.getItem('token');
    return token;
};

export const clearJtwtToken = () => {
    localStorage.removeItem('token');
};

export const getDecodedJwtToken = () => {
    const token = getJwtToken();
    const isValid = validateJWT(token);
    if (!isValid) {
        clearJtwtToken();
        return null;
    }

    const decoded = jwtDecode(token);
    return decoded;
};

export const getUserNameFromToken = () => {
    const token = getDecodedJwtToken();
    if (token) {
        const { FirstName, LastName } = token;
        const quoteRegex = new RegExp('"', 'g');
        return `${FirstName} ${LastName}`.replace(quoteRegex, '');
    }
    return null;
};

export const getIDFromToken = () => {
    const token = getDecodedJwtToken();
    if (token) {
        const { ID } = token;
        return ID;
    }
    return null;
};
