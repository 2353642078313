import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import usePrevious from 'lib/src/hooks/usePrevious';

import { fetchSingleEvent } from '@actions/events/fetchSingleEvent';
import { getEventsError, getEventsIsFetching, getSingleEvent } from '@selectors/events';

const useSingleEventFetch = eventID => {
    const dispatch = useDispatch();
    const history = useHistory();
    const event = useSelector(state => getSingleEvent(state, eventID));
    const isFetching = useSelector(getEventsIsFetching);
    const error = useSelector(getEventsError);

    const prevProps = usePrevious({ error });

    useEffect(() => {
        dispatch(fetchSingleEvent(eventID));
    }, [dispatch, eventID]);

    useEffect(() => {
        if (!prevProps.error && error) {
            history.push('/events');
        }
    }, [error, prevProps.error, history]);

    return [event, isFetching, error];
};

export default useSingleEventFetch;
