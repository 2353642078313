import React from 'react';
import dayjs from 'dayjs';

const ListItem = ({ item: { caseStudyTitle, createdOn, status }, handleModal }) => {
    const approved = status !== 1;

    return (
        <div className="dashboard-list-item-container">
            <div className="flex-row justify-between align-center flex-column-lm align-stretch-lm">
                <div className="left">
                    <p className="list-item-title">
                        <span className="cs-title">
                            {caseStudyTitle.length > 25
                                ? caseStudyTitle.substr(0, 25 - 1) + '...'
                                : caseStudyTitle}
                        </span>{' '}
                        <span className="separator">•</span> {dayjs(createdOn).format('DD/MM/YY')}
                    </p>
                </div>
                <div className="right">
                    <div className="buttons spacing flex-row">
                        {approved && (
                            <button className="button small-button flex-6-lm" onClick={handleModal}>
                                View
                            </button>
                        )}
                        {!approved ? (
                            <button className="button small-button cursor-initial green flex-6-lm">
                                Pending
                            </button>
                        ) : (
                            <button className="button small-button cursor-initial orange flex-6-lm">
                                Approved
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListItem;
