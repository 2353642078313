import { useDispatch, useSelector } from 'react-redux';
import useForm from 'lib/src/hooks/useForm';
import usePrevious from 'lib/src/hooks/usePrevious';

import { getVimeoLink, getYouTubeLink } from 'lib/src/utils/generic';
import { postUpdateSolutionCaseStudy } from '@actions/caseStudies/postUpdateSolutionCaseStudy';
import {
    getCaseStudiesError,
    getCaseStudiesIsPosting,
    getCaseStudiesPostSuccess,
} from '@selectors/caseStudies';

export default function useEditSolutionCaseStudy(caseStudy) {
    const dispatch = useDispatch();

    const {
        id,
        title,
        description,
        youTubeLink,
        vimeoLink,
        summary,
        sectors,
        isPublished,
        date,
    } = caseStudy;

    const youtube = !!youTubeLink ? getYouTubeLink(youTubeLink) : '';
    const vimeo = !!vimeoLink ? getVimeoLink(vimeoLink) : '';

    const [form, handleChange] = useForm({
        id,
        title,
        description,
        youTubeLink: youtube,
        vimeoLink: vimeo,
        summary,
        sectors,
        isPublished,
        date,
    });

    function handleSubmit() {
        dispatch(postUpdateSolutionCaseStudy(id, form));
    }

    const isPosting = useSelector(getCaseStudiesIsPosting);
    const error = useSelector(getCaseStudiesError);
    const postSuccess = useSelector(getCaseStudiesPostSuccess);

    const prevPostSuccess = usePrevious(postSuccess);

    return { form, handleChange, handleSubmit, isPosting, error, prevPostSuccess, postSuccess };
}
