import { useSelector } from 'react-redux';

import { getEvents } from '@selectors/events';

const useEventsToShow = (amountToShow, form) => {
    const events = useSelector(getEvents);
    const eventsToShow = getEventsToShow();

    function getEventsToShow() {
        let eventsArray = Object.values(events);

        if (form.date) {
            eventsArray = eventsArray.filter(event => {
                const filterDate = new Date(form.date);
                const startDate = new Date(event.startDate);
                const endDate = new Date(event.endDate);

                filterDate.setHours(0, 0, 0);
                startDate.setHours(0, 0, 0);
                endDate.setHours(0, 0, 0);

                if (filterDate < startDate || filterDate > endDate) return false;

                return true;
            });
        }

        const filteredArray = [...eventsArray]
            .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
            .filter(({ title, summary }) => {
                if (!form.searchString) return true;

                const searchString = form.searchString.toLowerCase();
                const matchesTitle = title && title.toLowerCase().includes(searchString);
                const matchesSummary = summary && summary.toLowerCase().includes(searchString);

                return matchesTitle || matchesSummary;
            });

        return { total: filteredArray.length, items: filteredArray.slice(0, amountToShow) };
    }

    return [eventsToShow];
};

export default useEventsToShow;
