import { useEffect, useState } from 'react';
import useAddSolutionCaseStudy from './hooks/useAddSolutionCaseStudy';

import Modal from 'lib/src/components/modal/Modal';
import Form from 'lib/src/components/form/Form';
import FormRow from 'lib/src/components/form/FormRow';
import TextInput from 'lib/src/components/form/TextInput';
import WYSIWYG from 'lib/src/components/form/WYSIWYG';
import Checkbox from 'lib/src/components/form/Checkbox';
import TextArea from 'lib/src/components/form/TextArea';
import MultiSelect from 'lib/src/components/form/MultiSelect';

import { enumToFormOptions } from 'lib/src/utils/generic';
import { specialistSectors } from 'lib/src/constants/enums';

const AddSolutionCaseStudyModal = ({ closeModal, caseStudy }) => {
    const {
        form: { title, summary, description, youTubeLink, vimeoLink, isPublished, sectors },
        handleChange,
        handleSubmit,
        error,
        isPosting,
        prevPostSuccess,
        postSuccess,
    } = useAddSolutionCaseStudy(caseStudy);

    const sectorOptions = enumToFormOptions(specialistSectors);

    const [showYouTubeLink, setShowYouTubeLink] = useState(!!youTubeLink);
    const [showVimeoLink, setShowVimeoLink] = useState(!!vimeoLink);

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            closeModal();
        }
    }, [prevPostSuccess, postSuccess, closeModal]);

    return (
        <Modal title="Add a solution" closeModal={closeModal}>
            <Form
                onCancel={closeModal}
                onSubmit={handleSubmit}
                error={error}
                isPosting={isPosting}
                submitButtonColor="green"
            >
                <FormRow>
                    <TextInput
                        name="title"
                        value={title}
                        onChange={handleChange}
                        label="Title"
                        placeholder="Title"
                        required
                    />
                </FormRow>
                <FormRow>
                    <TextArea
                        name="summary"
                        value={summary}
                        onChange={handleChange}
                        label="Summary"
                        placeholder="Summary"
                        required
                    />
                </FormRow>
                <FormRow>
                    <WYSIWYG
                        name="description"
                        value={description}
                        onChange={handleChange}
                        label="Description"
                        placeholder="Description"
                        required
                        isPublicFacing
                    />
                </FormRow>
                <FormRow>
                    <MultiSelect
                        name="sectors"
                        value={sectors}
                        onChange={handleChange}
                        label="Sectors"
                        options={sectorOptions}
                        required
                    />
                </FormRow>
                <FormRow>
                    <Checkbox
                        name="youTubeLink"
                        value={showYouTubeLink}
                        onChange={() => setShowYouTubeLink(!showYouTubeLink)}
                        label="Attach YouTube Link?"
                        disabled={showVimeoLink}
                    />
                    <Checkbox
                        name="vimeoLink"
                        value={showVimeoLink}
                        onChange={() => setShowVimeoLink(!showVimeoLink)}
                        label="Attach Vimeo Link?"
                        disabled={showYouTubeLink}
                    />
                </FormRow>
                <FormRow>
                    {showYouTubeLink && (
                        <TextInput
                            name="youTubeLink"
                            value={youTubeLink}
                            onChange={handleChange}
                            label="YouTube Link"
                            placeholder="YouTube Link"
                        />
                    )}
                    {showVimeoLink && (
                        <TextInput
                            name="vimeoLink"
                            value={vimeoLink}
                            onChange={handleChange}
                            label="Vimeo Link"
                            placeholder="Vimeo Link"
                        />
                    )}
                </FormRow>
                <FormRow>
                    <Checkbox
                        name="isPublished"
                        value={isPublished}
                        onChange={handleChange}
                        label="Is Published"
                    />
                </FormRow>
            </Form>
        </Modal>
    );
};

export default AddSolutionCaseStudyModal;
