import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchProblemCaseStudiesList } from '@actions/caseStudies';
import {
    getCaseStudiesError,
    getCaseStudiesIsFetching,
    getProblemCaseStudies,
} from '@selectors/caseStudies';

const useAllProblemsFetch = () => {
    const dispatch = useDispatch();
    const problems = useSelector(getProblemCaseStudies);
    const isFetching = useSelector(getCaseStudiesIsFetching);
    const error = useSelector(getCaseStudiesError);

    useEffect(() => {
        dispatch(fetchProblemCaseStudiesList());
    }, [dispatch]);

    return { problems, isFetching, error };
};

export default useAllProblemsFetch;
