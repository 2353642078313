import { metaData } from 'src/constants/meta';

import Meta from '@components/meta/Meta';
import React from 'react';
import PodLink from './PodLink';

const DashboardHome = () => {
    return (
        <>
            <Meta data={metaData.dashboard} disableIndex />

            <div className="dashboard-container home-container container page-section page-margin-top page-margin-bottom">
                <div className="wysiwyg">
                    <h1>Your dashboard</h1>
                    <h3>
                        This section is where you can edit your profile, see your connection
                        requests and see feedback on case studies that you are involved in.
                        <br />
                        <br />
                        You can also edit and update the relevant sections here.
                    </h3>
                </div>

                <PodLink />
            </div>
        </>
    );
};

export default DashboardHome;
