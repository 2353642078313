import dayjs from 'dayjs';

import ButtonRow from 'lib/src/components/button/ButtonRow';
import ExternalLinkButton from 'lib/src/components/button/ExternalLinkButton';

import ActionButton from 'lib/src/components/button/ActionButton';
import { useHistory } from 'react-router-dom';
import BreadCrumb from '@components/breadcrumb/BreadCrumb';
import ShareThis from '@components/shareThis/ShareThis';
import { eventsDateTime } from 'src/constants/dateFormats';
import PageVideo from '@components/pageVideo/PageVideo';
import Meta from '@components/meta/Meta';

const WebinarSingleDetails = ({ webinar }) => {
    const history = useHistory();

    const breadcrumbArray = [
        { text: 'Home', link: '/' },
        { text: 'Resources', link: '/resources' },
        { text: 'Webinars', link: '/resources#webinars' },
        { text: webinar.title, link: null },
    ];

    const startDate = dayjs(webinar.startDate);
    const endDate = dayjs(webinar.endDate);

    const metaData = {
        title: webinar.title,
        description: webinar.summary,
    };

    return (
        <>
            <Meta data={metaData} />

            <BreadCrumb breadcrumbArray={breadcrumbArray} />

            <div className="container">
                <div className="page-section">
                    <main className="post-content">
                        <div className="wysiwyg">
                            <h2>{webinar.title}</h2>
                            <h3>
                                {`${startDate.format(eventsDateTime)} - ${endDate.format(
                                    eventsDateTime,
                                )}`}{' '}
                                GMT
                            </h3>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: webinar.description,
                                }}
                            />

                            <PageVideo
                                vimeoLink={webinar.vimeoLink}
                                youTubeLink={webinar.youTubeLink}
                            />
                        </div>

                        {webinar.registerLink && (
                            <div className="button-container">
                                <ButtonRow alignment="left no-margin">
                                    <ExternalLinkButton color="orange" href={webinar.registerLink}>
                                        Register
                                    </ExternalLinkButton>
                                </ButtonRow>
                            </div>
                        )}

                        <section className="flex-row align-center justify-between button-container large page-bottom-margin">
                            <ActionButton color="grey" onClick={() => history.goBack()}>
                                Go Back
                            </ActionButton>
                            <ShareThis title={webinar.title} summary={webinar.summary} />
                        </section>
                    </main>
                </div>
            </div>
        </>
    );
};

export default WebinarSingleDetails;
