import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchWebinarsList } from '@actions/webinars/index';

import { getWebinars, getWebinarsIsFetching, getWebinarsError } from '@selectors/webinars';

const useAllWebinarsFetch = () => {
    const dispatch = useDispatch();
    const webinars = useSelector(getWebinars);
    const isFetching = useSelector(getWebinarsIsFetching);
    const error = useSelector(getWebinarsError);

    useEffect(() => {
        dispatch(fetchWebinarsList());
    }, [dispatch]);

    return [webinars, isFetching, error];
};

export default useAllWebinarsFetch;
