import React, { useCallback } from 'react';
import useShowModal from './hooks/useShowModal';
import ListItem from './ListItem';
import ViewConnectionModal from './ViewConnectionModel';

const List = ({ items, isRequested }) => {
    const { show, data, handleModal } = useShowModal();

    const showModal = useCallback(
        data => {
            handleModal(true, { ...data, modalType: isRequested });
        },
        [handleModal, isRequested],
    );

    return (
        <div className="dashboard-list-container">
            {items.map((item, index) => {
                return <ListItem key={index} item={item} handleModal={() => showModal(item)} />;
            })}
            {show && <ViewConnectionModal item={data} closeModal={() => handleModal()} />}
        </div>
    );
};

export default List;
