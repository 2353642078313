import { Route, Switch, useRouteMatch } from 'react-router-dom';

import ContactUs from '@pages/contactUs/ContactUs';

const ContactUsRoutes = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`}>
                <ContactUs />
            </Route>
        </Switch>
    );
};

export default ContactUsRoutes;
