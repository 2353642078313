import React from 'react';
import useWindowSize from 'src/hooks/useWindowSize';
import useHomePods from './hooks/useHomePods';

import { homePods } from 'src/constants/pods';
import mediaQueries from 'src/constants/mediaQueries';
import HomePodDescription from './HomePodDescription';

const HomePods = () => {
    const [activePod, setActivePod, curSelected] = useHomePods();
    const { width } = useWindowSize();
    const isMobile = width <= mediaQueries.lgMobile;

    return (
        <>
            <div className="home-pods flex-row flex-column-lm">
                {homePods.map(pod => {
                    const isActive = activePod === pod.title;

                    return (
                        <React.Fragment key={pod.title}>
                            <div
                                className={`item flex-column justify-end flex-4 ${
                                    activePod && !isActive ? 'inactive' : ''
                                }`}
                                onClick={() => setActivePod(isActive ? null : pod.title)}
                            >
                                <img
                                    className="image"
                                    alt={pod.summaryImageAlt}
                                    src={pod.summaryImageSrc}
                                />
                                <h2 className="title">{pod.title}</h2>
                                <p className="summary">{pod.summary}</p>

                                {isActive ? (
                                    <i className="arrow fas fa-caret-down"></i>
                                ) : (
                                    <i className="arrow fas fa-caret-right"></i>
                                )}
                            </div>

                            {isActive && isMobile && (
                                <HomePodDescription curSelected={curSelected} />
                            )}
                        </React.Fragment>
                    );
                })}
            </div>

            {activePod && !isMobile && <HomePodDescription curSelected={curSelected} />}
        </>
    );
};

export default HomePods;
