import dayjs from 'dayjs';

import ButtonRow from 'lib/src/components/button/ButtonRow';
import ExternalLinkButton from 'lib/src/components/button/ExternalLinkButton';

import ActionButton from 'lib/src/components/button/ActionButton';
import { useHistory } from 'react-router-dom';
import BreadCrumb from '@components/breadcrumb/BreadCrumb';
import ShareThis from '@components/shareThis/ShareThis';
import { eventsDateTime } from 'src/constants/dateFormats';
import PageVideo from '@components/pageVideo/PageVideo';
import Meta from '@components/meta/Meta';

const SingleEventDetails = ({ event }) => {
    const history = useHistory();

    const breadcrumbArray = [
        { text: 'Home', link: '/' },
        { text: 'Events', link: '/events' },
        { text: event.title, link: null },
    ];

    const startDate = dayjs(event.startDate);
    const endDate = dayjs(event.endDate);

    const metaData = {
        title: event.title,
        description: event.summary,
    };

    return (
        <>
            <Meta data={metaData} />

            <BreadCrumb breadcrumbArray={breadcrumbArray} />

            <div className="container">
                <div className="page-section">
                    <main className="post-content">
                        <div className="wysiwyg">
                            <p>KTN Innovation Networks Event</p>
                            <h2>{event.title}</h2>
                            <h3>
                                {`${startDate.format(eventsDateTime)} - ${endDate.format(
                                    eventsDateTime,
                                )}`}{' '}
                                GMT
                                <br />
                                <strong>{event.location}</strong>
                            </h3>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: event.description,
                                }}
                            />

                            <PageVideo
                                vimeoLink={event.vimeoLink}
                                youTubeLink={event.youTubeLink}
                            />
                        </div>

                        {event.registerLink && (
                            <div className="button-container">
                                <ButtonRow alignment="left no-margin">
                                    <ExternalLinkButton color="orange" href={event.registerLink}>
                                        Register
                                    </ExternalLinkButton>
                                </ButtonRow>
                            </div>
                        )}

                        <section className="event-button-container button-container large page-bottom-margin flex-row align-center justify-between">
                            <ActionButton color="grey" onClick={() => history.goBack()}>
                                Go Back
                            </ActionButton>
                            <ShareThis title={event.title} summary={event.summary} />
                        </section>
                    </main>
                </div>
            </div>
        </>
    );
};

export default SingleEventDetails;
