import React from 'react';

import { enumToFormOptions } from 'lib/src/utils/generic';
import { specialistSectors } from 'lib/src/constants/enums';
import useCreateCaseStudy from '../hooks/useCreateCaseStudy';
import Modal from 'lib/src/components/modal/Modal';
import Form from 'lib/src/components/form/Form';
import WYSIWYG from 'lib/src/components/form/WYSIWYG';
import FormRow from 'lib/src/components/form/FormRow';
import FormField from 'lib/src/components/form/FormField';
import TextInput from 'lib/src/components/form/TextInput';
import TextArea from 'lib/src/components/form/TextArea';
import Checkbox from 'lib/src/components/form/Checkbox';
import MultiSelect from 'lib/src/components/form/MultiSelect';

const CreateCaseStudyModal = ({ item, closeModal }) => {
    const sectorOptions = enumToFormOptions(specialistSectors);
    const {
        handleSubmit,
        handleChange,
        isPosting,
        error,
        showYouTubeLink,
        showVimeoLink,
        setShowVimeoLink,
        setShowYouTubeLink,
        formState: { title, summary, description, sectors, youTubeLink, vimeoLink, isPublished },
    } = useCreateCaseStudy(closeModal);

    return (
        <Modal title="Request new case study solution" closeModal={closeModal}>
            <div className="problem-case-study-modal">
                <Form
                    onSubmit={handleSubmit}
                    isPosting={isPosting}
                    onCancel={closeModal}
                    error={error}
                    submitButtonColor="green"
                >
                    <FormRow>
                        <FormField>
                            <TextInput
                                name="title"
                                value={title}
                                onChange={handleChange}
                                label="Title"
                                placeholder="Title"
                                required
                            />
                        </FormField>
                    </FormRow>

                    <FormRow>
                        <FormField>
                            <TextArea
                                name="summary"
                                value={summary}
                                onChange={handleChange}
                                label="Summary"
                                placeholder="Summary"
                                required
                            />
                        </FormField>
                    </FormRow>

                    <FormRow>
                        <WYSIWYG
                            name="description"
                            value={description}
                            onChange={handleChange}
                            label="Description"
                            placeholder="Description"
                            required
                            isPublicFacing
                        />
                    </FormRow>

                    <FormRow>
                        <MultiSelect
                            name="sectors"
                            value={sectors}
                            onChange={handleChange}
                            label="Sectors"
                            options={sectorOptions}
                            required
                        />
                    </FormRow>

                    <FormRow>
                        <Checkbox
                            name="youTubeLink"
                            value={showYouTubeLink}
                            onChange={() => setShowYouTubeLink(!showYouTubeLink)}
                            label="Attach YouTube Link?"
                            disabled={showVimeoLink}
                        />
                        <Checkbox
                            name="vimeoLink"
                            value={showVimeoLink}
                            onChange={() => setShowVimeoLink(!showVimeoLink)}
                            label="Attach Vimeo Link?"
                            disabled={showYouTubeLink}
                        />
                    </FormRow>

                    <FormRow>
                        {showYouTubeLink && (
                            <TextInput
                                name="youTubeLink"
                                value={youTubeLink}
                                onChange={handleChange}
                                label="YouTube Link"
                                placeholder="YouTube Link"
                            />
                        )}
                        {showVimeoLink && (
                            <TextInput
                                name="vimeoLink"
                                value={vimeoLink}
                                onChange={handleChange}
                                label="Vimeo Link"
                                placeholder="Vimeo Link"
                            />
                        )}
                    </FormRow>

                    <FormRow>
                        <Checkbox
                            name="isPublished"
                            value={isPublished}
                            onChange={handleChange}
                            label="Is Published"
                        />
                    </FormRow>
                </Form>
            </div>
        </Modal>
    );
};

export default CreateCaseStudyModal;
