import React from 'react';
import { Link } from 'react-router-dom';
import { dashboardHomePods } from 'src/constants/pods';

const PodLink = () => {
    return (
        <div className="pod-links-container flex-row flex-column-pt">
            {dashboardHomePods.map(pod => {
                return (
                    <Link
                        key={pod.title}
                        className={`item flex-column justify-end flex-4`}
                        to={pod.link}
                    >
                        <img className="image" alt={pod.title} src={pod.image} />
                        <h2 className="title">{pod.title}</h2>
                    </Link>
                );
            })}
        </div>
    );
};

export default PodLink;
