import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';

export const createSolutionCaseStudyRequest = createAction('createSolutionCaseStudyRequest');
export const createSolutionCaseStudySuccess = createAction('createSolutionCaseStudySuccess');
export const createSolutionCaseStudyFailure = createAction('createSolutionCaseStudyFailure');

export const createSolutionCaseStudy = postBody => async dispatch => {
    dispatch(createSolutionCaseStudyRequest());

    try {
        const { data } = await api.post('SolutionCaseStudies', postBody);
        return dispatch(createSolutionCaseStudySuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, createSolutionCaseStudyFailure, e);
    }
};
