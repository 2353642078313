import FormField from './FormField';
import useFieldValidation from '../../hooks/useFieldValidation';

const Checkbox = ({
    name,
    value,
    onChange,
    label = '',
    placeholder = '',
    required = false,
    disabled,
    customValidate,
    extraClasses = '',
}) => {
    const [error, showError] = useFieldValidation({
        name,
        required,
        value,
        customValidate,
    });

    return (
        <FormField
            name={name}
            label={label}
            className={extraClasses}
            required={required}
            error={error}
        >
            <div className="form-checkbox">
                <input
                    type="checkbox"
                    name={name}
                    id={name}
                    checked={value}
                    onChange={handleChange}
                    disabled={disabled}
                />
                <label className={`content ${disabled ? 'disabled' : ''}`} htmlFor={name}>
                    <div className="outer-box">
                        <i className={`inner-box far fa-check ${value ? 'active' : ''}`}></i>
                    </div>
                    {!!placeholder && <p>{placeholder}</p>}
                </label>
            </div>
        </FormField>
    );

    function handleChange() {
        const newVal = !value;
        onChange(name, newVal);
        showError();
    }
};

export default Checkbox;
