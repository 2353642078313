import React from 'react';

import { isEmpty } from 'lib/src/utils/generic';

import Loading from '@components/loading/Loading';
import PlaceholderMessage from '@components/text/placeholderMessage/PlaceholderMessage';

const DataCheck = ({
    children,
    data,
    isFetching,
    error,
    errorMessage = 'There was an error',
    noDataMessage = 'There is no data to display',
}) => {
    if (error) return <PlaceholderMessage text={errorMessage} />;

    if (isEmpty(data) && isFetching) return <Loading />;

    if (isEmpty(data)) return <PlaceholderMessage text={noDataMessage} />;

    return children;
};

export default DataCheck;
