import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import FundingSingle from '@pages/news/funding/single/FundingSingle';

const FundingRoutes = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`}>
                <Redirect to="/news" />
            </Route>
            <Route exact path={`${path}/:id`}>
                <FundingSingle />
            </Route>
        </Switch>
    );
};

export default FundingRoutes;
