import { CarouselProvider, Slide, Slider, ButtonBack, ButtonNext } from 'pure-react-carousel';

import 'pure-react-carousel/dist/react-carousel.es.css';

const CarouselContainer = ({
    items,
    renderItem,
    visibleSlides = 4,
    extraContainerClass = '',
    extraSliderClass = '',
    isIntrinsicHeight = true,
    isIntrinsicWidth = true,
    step = 1,
}) => {
    const RenderItem = renderItem;

    return (
        <div className={`carousel-container ${extraContainerClass}`}>
            <CarouselProvider
                visibleSlides={visibleSlides}
                totalSlides={items.length}
                step={step}
                naturalSlideWidth={400}
                naturalSlideHeight={400}
                touchEnabled={true}
                dragEnabled={items.length > 4}
                isIntrinsicHeight={isIntrinsicHeight}
                isIntrinsicWidth={isIntrinsicWidth}
            >
                <div className="carousel-slider-container">
                    <Slider className={`slider ${extraSliderClass}`}>
                        {items.map((item, index) => (
                            <Slide index={index} key={item.id}>
                                <RenderItem item={item} />
                            </Slide>
                        ))}
                    </Slider>
                    <ButtonBack className="carousel-button back">
                        <i className="fas fa-caret-left" />
                    </ButtonBack>
                    <ButtonNext className="carousel-button next">
                        <i className="fas fa-caret-right" />
                    </ButtonNext>
                </div>
            </CarouselProvider>
        </div>
    );
};

export default CarouselContainer;
