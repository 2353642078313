import OurTeamCarousel from './OurTeamCarousel';

const OurTeam = () => {
    return (
        <div className="our-team-container fluid flex-column align-center">
            <h2 className="title">Meet our team</h2>

            <OurTeamCarousel />
        </div>
    );
};

export default OurTeam;
