import { createReducer } from '@reduxjs/toolkit';
import { convertArrToObj } from 'lib/src/utils/generic';

import {
    fetchEventsListRequest,
    fetchEventsListSuccess,
    fetchEventsListFailure,
    fetchSingleEventRequest,
    fetchSingleEventSuccess,
    fetchSingleEventFailure,
} from '@actions/events';

const initialState = {
    isFetching: false,
    error: null,
    events: {},
};

export default createReducer(initialState, {
    [fetchEventsListRequest]: handleFetchRequest,
    [fetchEventsListFailure]: handleFetchFailure,
    [fetchEventsListSuccess]: handleFetchAllSuccess,
    [fetchSingleEventRequest]: handleFetchRequest,
    [fetchSingleEventFailure]: handleFetchFailure,
    [fetchSingleEventSuccess]: handleFetchSingleSuccess,
});

function handleFetchRequest(state) {
    state.isFetching = true;
    state.error = null;
}

function handleFetchFailure(state, action) {
    state.isFetching = false;
    state.error = action.payload;
}

function handleFetchAllSuccess(state, action) {
    state.isFetching = false;
    state.events = convertArrToObj(action.payload);
}

function handleFetchSingleSuccess(state, action) {
    state.isFetching = false;
    state.events[action.payload.id] = action.payload;
}
