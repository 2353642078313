import { useSelector } from 'react-redux';

import { metaData } from 'src/constants/meta';

import DataCheck from '@components/dataCheck/DataCheck';
import { getAuthError, getAuthIsFetching, getSingleLoggedInUser } from '@selectors/auth';

import ProfileForm from './ProfileForm';
import DashboardHeader from '@components/dashboardHeader/DashboardHeader';
import Meta from '@components/meta/Meta';

const Profile = () => {
    const user = useSelector(getSingleLoggedInUser);
    const isFetching = useSelector(getAuthIsFetching);
    const error = useSelector(getAuthError);

    return (
        <>
            <Meta data={metaData.profile} disableIndex />

            <div className="dashboard-container page-margin-top page-margin-bottom">
                <DashboardHeader pageTitle={`${user.firstName} ${user.lastName}'s details`} />
            </div>

            <div className="container page-bottom-margin">
                <DataCheck data={user} isFetching={isFetching} error={error}>
                    <ProfileForm user={user} />
                </DataCheck>
            </div>
        </>
    );
};

export default Profile;
