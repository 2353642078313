import { Route, Switch, useRouteMatch } from 'react-router-dom';

import CrossSectoralOpportunities from '@pages/crossSectoralOpportunities/list/CrossSectoralOpportunities';

const CrossSectoralOpportunitiesRoutes = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`}>
                <CrossSectoralOpportunities />
            </Route>
        </Switch>
    );
};

export default CrossSectoralOpportunitiesRoutes;
