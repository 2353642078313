import { metaData } from 'src/constants/meta';

import EventsListBanner from '@content/images/banners/events-list.png';

import useForm from 'lib/src/hooks/useForm';

import ActionButton from 'lib/src/components/button/ActionButton';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import TextArea from 'lib/src/components/form/TextArea';

import useEventsToShow from './hooks/useEventsToShow';
import useAllEventsFetch from './hooks/useAllEventsFetch';

import Banner from '@components/banner/Banner';
import DataCheck from '@components/dataCheck/DataCheck';
import useShowMoreItems from 'src/hooks/useShowMoreItems';
import EventListCard from './EventListCard';
import FilterSubHeading from '@components/filterSubHeading/FilterSubHeading';
import DatePicker from 'lib/src/components/form/DatePicker';
import Meta from '@components/meta/Meta';
import Webinars from '../webinars/Webinars';

const Events = () => {
    const [form, handleChange] = useForm({ date: null, searchString: '' });
    const [, isFetching, error] = useAllEventsFetch();
    const [amountToShow, showMoreItems] = useShowMoreItems(6, 6);
    const [eventsToShow] = useEventsToShow(amountToShow, form);

    return (
        <>
            <Meta data={metaData.events} />

            <div className="page-bottom-margin">
                <div className="container">
                    <Banner title="Events" image={EventsListBanner} extraImageClasses="top" />
                </div>

                <FilterSubHeading title="Forthcoming Events" extraClasses="justify-between">
                    <div className="filter-box-wrapper flex-row flex justify-between align-center flex-wrap-sd justify-around-sd flex-column-lm align-stretch-lm">
                        <DatePicker
                            name="date"
                            value={form.date}
                            onChange={handleChange}
                            formFieldClasses="no-margin"
                            extraClasses="grey"
                            placeholder="Filter by date"
                        />
                        <TextArea
                            name="searchString"
                            onChange={handleChange}
                            placeholder="Search by title or summary"
                            extraClasses="grey filter-bar-search margin-left"
                        />
                    </div>
                </FilterSubHeading>

                <div className="container">
                    <DataCheck
                        data={eventsToShow.items}
                        isFetching={isFetching}
                        error={error}
                        noDataMessage="There are no events to display"
                    >
                        <div className="events-list page-section flex-row justify-between flex-wrap flex-column-pt">
                            {eventsToShow.items.map(event => (
                                <EventListCard event={event} key={event.id} />
                            ))}
                        </div>

                        {eventsToShow.total > amountToShow && (
                            <ButtonRow>
                                <ActionButton color="grey" onClick={showMoreItems}>
                                    Show more events
                                </ActionButton>
                            </ButtonRow>
                        )}
                    </DataCheck>
                </div>
            </div>

            <Webinars />
        </>
    );
};

export default Events;
