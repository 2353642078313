import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';

export const fetchUsersSolutionCaseStudiesRequest = createAction(
    'fetchUsersSolutionCaseStudiesRequest',
);
export const fetchUsersSolutionCaseStudiesSuccess = createAction(
    'fetchUsersSolutionCaseStudiesSuccess',
);
export const fetchUsersSolutionCaseStudiesFailure = createAction(
    'fetchUsersSolutionCaseStudiesFailure',
);

export const fetchUsersSolutionCaseStudies = id => async dispatch => {
    dispatch(fetchUsersSolutionCaseStudiesRequest());

    try {
        const { data } = await api.get(`SolutionCaseStudies/user/${id}`);

        return dispatch(fetchUsersSolutionCaseStudiesSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchUsersSolutionCaseStudiesFailure, e);
    }
};
