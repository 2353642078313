import ReportsListCard from './ReportsListCard';

import useShowMoreItems from 'src/hooks/useShowMoreItems';
import useReportsToShow from './hooks/useReportsToShow';
import useAllReportsFetch from './hooks/useAllReportsFetch';

import DataCheck from '@components/dataCheck/DataCheck';
import ActionButton from 'lib/src/components/button/ActionButton';
import ButtonRow from 'lib/src/components/button/ButtonRow';

function ReportsList({ searchString }) {
    const [reports, isFetching, error] = useAllReportsFetch();
    const [amountToShow, showMoreItems] = useShowMoreItems(12, 12);

    const [reportsToShow] = useReportsToShow(amountToShow, searchString);

    return (
        <div className="container">
            <DataCheck data={reports} error={error} isFetching={isFetching}>
                <div className="flex-row flex-wrap item-list">
                    {reportsToShow.items.map(report => (
                        <ReportsListCard key={report.id} resource={report} />
                    ))}
                </div>

                {reportsToShow.total > amountToShow && (
                    <ButtonRow>
                        <ActionButton color="grey" onClick={showMoreItems} extraClasses="margin">
                            Load more reports
                        </ActionButton>
                    </ButtonRow>
                )}
            </DataCheck>
        </div>
    );
}

export default ReportsList;
