import { forwardRef, useEffect, useRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import dayjs from 'dayjs';

import useFieldValidation from '../../hooks/useFieldValidation';
import FormField from './FormField';

import 'react-datepicker/dist/react-datepicker.css';

const eightyYearsBeforeNow = new Date(new Date().setFullYear(new Date().getFullYear() - 80));
const tenYearsFromNow = new Date(new Date().setFullYear(new Date().getFullYear() + 10));

const dateFormat = 'DD/MM/YYYY';
const dateTimeFormat = 'DD/MM/YYYY HH:mm';

const DatePicker = ({
    name,
    value,
    onChange,
    label = '',
    placeholder = '',
    required = false,
    minDate = eightyYearsBeforeNow,
    maxDate = tenYearsFromNow,
    disabled,
    customValidate,
    showTimeSelect,
    formFieldClasses = '',
    extraClasses = '',
}) => {
    const [error, showError] = useFieldValidation({
        name,
        required,
        customValidate,
        value,
    });

    const hasMounted = useRef(false);
    const inputRef = useRef(null);
    useEffect(() => {
        if (hasMounted.current) showError();
        else hasMounted.current = true;
    }, [value, showError]);

    const CustomInput = forwardRef(({ onClick }, ref) => (
        <div className={`form-datepicker ${disabled ? 'disabled' : ''} ${extraClasses}`} ref={ref}>
            <input
                className={`form-input text-area ${error ? 'error' : ''}`}
                placeholder={placeholder}
                name={name}
                value={
                    value ? dayjs(value).format(showTimeSelect ? dateTimeFormat : dateFormat) : ''
                }
                onClick={onClick}
                onChange={() => {}}
                ref={inputRef}
                disabled={disabled}
            />
            {!!value && !disabled && (
                <button className="remove" onClick={handleClearDate}>
                    <i className="fal fa-times" />
                </button>
            )}

            <button className="calendar" onClick={handleInputFocus}>
                <i className="far fa-calendar-alt"></i>
            </button>
        </div>
    ));

    return (
        <FormField
            name={name}
            label={label}
            required={required}
            error={error}
            className={formFieldClasses}
        >
            <ReactDatePicker
                selected={value}
                onChange={_handleChange}
                customInput={<CustomInput />}
                dateFormat="dd/MM/yyyy HH:MM"
                disabled={disabled}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                minDate={minDate}
                maxDate={maxDate}
                showTimeSelect={showTimeSelect}
            />
        </FormField>
    );

    function _handleChange(newVal) {
        const c = typeof undefined;
        onChange(name, newVal);
    }

    function handleClearDate(e) {
        e.preventDefault();
        e.stopPropagation();
        _handleChange(null);
    }

    function handleInputFocus(e) {
        e.preventDefault();
        e.stopPropagation();

        inputRef.current.click();
    }
};

export default DatePicker;
