import useAllWebinarsFetch from './hooks/useAllWebinarsFetch';
import useShowMoreItems from 'src/hooks/useShowMoreItems';
import useWebinarsToShow from './hooks/useWebinarsToShow';

import ActionButton from 'lib/src/components/button/ActionButton';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import DataCheck from '@components/dataCheck/DataCheck';
import WebinarListCard from './WebinarListCard';

function Webinars({ searchString }) {
    const [webinars, isFetching, error] = useAllWebinarsFetch();
    const [amountToShow, showMoreItems] = useShowMoreItems(12, 12);
    const [webinarsToShow] = useWebinarsToShow(amountToShow, searchString);

    return (
        <div className="container">
            <DataCheck data={webinars} error={error} isFetching={isFetching}>
                <div className="flex-row flex-wrap item-list">
                    {webinarsToShow.items.map(webinar => (
                        <WebinarListCard key={webinar.id} webinar={webinar} />
                    ))}
                </div>

                {webinarsToShow.total > amountToShow && (
                    <ButtonRow>
                        <ActionButton color="grey" onClick={showMoreItems} extraClasses="margin">
                            Load more webinars
                        </ActionButton>
                    </ButtonRow>
                )}
            </DataCheck>
        </div>
    );
}

export default Webinars;
