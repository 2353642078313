const FormField = ({ children, name, label, required, error, className }) => (
    <div className={`form-field ${className ? className : ''}`}>
        {!!label && (
            <label htmlFor={name} className="form-label">
                {label}
                {required && <span className="form-asterisk">*</span>}
            </label>
        )}

        {children}

        {!!error && <p className="form-error">{error}</p>}
    </div>
);

export default FormField;
