import { Link } from 'react-router-dom';

import ActionButton from 'lib/src/components/button/ActionButton';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import Form from 'lib/src/components/form/Form';
import FormRow from 'lib/src/components/form/FormRow';
import TextInput from 'lib/src/components/form/TextInput';

const LoginForm = ({
    formState: { email, password },
    handleChange,
    handleSubmit,
    isPosting,
    customValidate,
}) => (
    <Form extraClasses="page-section" onSubmit={handleSubmit} isPosting={isPosting} omitButtons>
        <h3 className="auth-supply-title">Please enter your details here</h3>
        <FormRow extraClasses="flex-column-lm">
            <TextInput
                name="email"
                type="email"
                value={email}
                placeholder="Email address *"
                onChange={handleChange}
                required
                extraClasses="auth-form-field"
            />
            <TextInput
                name="password"
                value={password}
                placeholder="Password *"
                onChange={handleChange}
                type="password"
                required
                customValidate={customValidate}
                extraClasses="auth-form-field"
            />
        </FormRow>
        <div className="flex-row">
            <Link className="forgot-password-text" to="/auth/forgot-password">
                Forgot password?
            </Link>
        </div>
        <ButtonRow alignment="right">
            <ActionButton icon="sign-in" color="grey" isPosting={isPosting}>
                Login
            </ActionButton>
        </ButtonRow>
    </Form>
);

export default LoginForm;
