import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';

// Login
export const fetchNewsTagsRequest = createAction('fetchNewsTagsRequest');
export const fetchNewsTagsSuccess = createAction('fetchNewsTagsSuccess');
export const fetchNewsTagsFailure = createAction('fetchNewsTagsFailure');

export const fetchNewsTags = () => async dispatch => {
    dispatch(fetchNewsTagsRequest());

    try {
        const { data } = await api.get('newsTags');
        return dispatch(fetchNewsTagsSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchNewsTagsFailure, e);
    }
};
