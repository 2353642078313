import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchEventsList } from '@actions/events/fetchEventsList';
import { getEvents, getEventsError, getEventsIsFetching } from '@selectors/events';

const useAllEventsFetch = () => {
    const dispatch = useDispatch();
    const events = useSelector(getEvents);
    const isFetching = useSelector(getEventsIsFetching);
    const error = useSelector(getEventsError);

    useEffect(() => {
        dispatch(fetchEventsList());
    }, [dispatch]);

    return [events, isFetching, error];
};

export default useAllEventsFetch;
