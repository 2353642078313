import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import {
    specialistSectors,
    specialistSectorsImages,
    specialistSectorsNames,
} from 'lib/src/constants/enums';
import { postedByDateTime } from 'src/constants/dateFormats';
import LinkButton from 'lib/src/components/button/LinkButton';

const NetworkingHubCaseStudyListCard = ({ item, urlPrefix = '' }) => {
    const { id, createdByName, organisationName, date, title, sectors, summary } = item;
    const [sectorImage, setSectorImage] = useState(
        specialistSectorsImages[specialistSectors.other],
    );

    useEffect(() => {
        const randomSector = sectors[Math.floor(Math.random() * sectors.length)];
        setSectorImage(
            !sectors.length
                ? specialistSectorsImages[specialistSectors.other]
                : specialistSectorsImages[randomSector],
        );
    }, [sectors]);

    return (
        <div key={id} className="list-card-container full-list flex-row">
            <div className="post-content flex-column">
                <p className="post-details">
                    Posted by&nbsp;
                    <strong>
                        {createdByName}, {organisationName}
                    </strong>
                    &nbsp;on&nbsp;
                    <strong>{dayjs(date).format(postedByDateTime)}</strong>
                </p>

                {sectors && (
                    <div className="flex-row tag-wrapper">
                        {sectors.map((sector, index) => {
                            return (
                                index < 3 && (
                                    <div key={`${sector}-${index}`} className="tag">
                                        {specialistSectorsNames[sector]}
                                    </div>
                                )
                            );
                        })}
                    </div>
                )}

                <div className="wysiwyg flex">
                    <h3>{title}</h3>
                    <p>{summary}</p>
                </div>

                <div className="button-container flex-row align-center flex-column-lm align-stretch-lm">
                    <LinkButton color="purple" href={`/networking-hub/${urlPrefix}/${id}`}>
                        Read more
                    </LinkButton>
                </div>
            </div>

            <div className="image-wrapper" style={{ backgroundImage: `url(${sectorImage})` }} />
        </div>
    );
};

export default NetworkingHubCaseStudyListCard;
