export function isEmpty(item) {
    if (Array.isArray(item)) return !item.length;
    if (typeof item === 'string') return !item.trim().length;
    if (item instanceof Date) return isNaN(item.valueOf());
    if (typeof item === 'object') return isObjEmpty(item);
    if (typeof item === 'number') return false;

    return !item;
}

export function isObjEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
    }
    return true;
}

export function convertArrToObj(arr, field = 'id') {
    return arr.reduce((acc, item) => {
        acc[item[field]] = item;
        return acc;
    }, {});
}

export function areArraysEqual(arr1, arr2) {
    if (!arr1 || !arr2) return arr1 === arr2;
    return (
        arr1.length === arr2.length &&
        arr1.every(item => arr2.includes(item)) &&
        arr2.every(item => arr1.includes(item))
    );
}

export const boolToYesNo = bool => (bool ? 'Yes' : 'No');

export const getYouTubeLink = youTubeID => `https://www.youtube.com/watch?v=${youTubeID}`;
export const getVimeoLink = vimeoID => `https://vimeo.com/${vimeoID}`;

export function convertCamelCaseToFormLabel(str) {
    let newString = str.charAt(0).toUpperCase() + str.slice(1);

    return newString.replace(/([a-z])([A-Z])/g, '$1 $2');
}

export const enumToFormOptions = baseEnum => {
    return Object.entries(baseEnum).map(([key, value]) => ({
        value,
        label: convertCamelCaseToFormLabel(key),
    }));
};
