import React from 'react';

const PageVideo = ({ vimeoLink, youTubeLink }) => {
    if (!vimeoLink && !youTubeLink) return null;

    return (
        <div className="video-wrapper" style={{ marginTop: '30px' }}>
            <div className="video">
                {vimeoLink && (
                    <iframe
                        title="Vimeo video"
                        src={`https://player.vimeo.com/video/${vimeoLink}?color=ffffff&amp;title=0&amp;byline=0&amp;portrait=0`}
                        width="640"
                        height="360"
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                )}

                {youTubeLink && (
                    <iframe
                        title="YouTube video"
                        width="560"
                        height="315"
                        src={`https://www.youtube.com/embed/${youTubeLink}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                )}
            </div>
        </div>
    );
};

export default PageVideo;
