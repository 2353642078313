import React from 'react';
import DashboardHeader from '@components/dashboardHeader/DashboardHeader';
import useFetchConnectionRequests from './hooks/useFetchConnectionRequests';
import ConnectionsList from './ConnectionsList';
import Meta from '@components/meta/Meta';
import { metaData } from 'src/constants/meta';

const ConnectionRequests = () => {
    const [
        initiated,
        requested,
        isFetchingInitiated,
        isFetchingRequested,
        errorInitiated,
        errorRequested,
    ] = useFetchConnectionRequests();

    return (
        <>
            <Meta data={metaData.connectionRequests} disableIndex />

            <div className="dashboard-container connection-requests-container page-margin-top page-margin-bottom">
                <DashboardHeader pageTitle="Connection Requests" />
                <div className="container">
                    <div className="page-section">
                        <div className="wysiwyg">
                            <h2>Connection Requests</h2>
                        </div>

                        <div className="connections-container flex-row flex-wrap flex-column-pt">
                            <div className="connection-column flex-6">
                                <ConnectionsList
                                    title="Connection Incoming"
                                    items={requested}
                                    isFetching={isFetchingRequested}
                                    error={errorRequested}
                                />
                            </div>
                            <div className="connection-column flex-6">
                                <ConnectionsList
                                    title="Connection Outgoing"
                                    items={initiated}
                                    isFetching={isFetchingInitiated}
                                    error={errorInitiated}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ConnectionRequests;
