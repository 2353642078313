import { Route, useRouteMatch } from 'react-router-dom';
import SwitchWith404 from './SwitchWith404';

import Reports from '@pages/resources/list/Reports';
import ReportSingle from '@pages/resources/reports/single/ReportSingle';
import WebinarSingle from '@pages/events/webinars/single/WebinarSingle';

const ReportsRoutes = () => {
    const { path } = useRouteMatch();

    return (
        <SwitchWith404>
            <Route exact path={`${path}`}>
                <Reports />
            </Route>
            <Route exact path={`${path}/reports/:id`}>
                <ReportSingle />
            </Route>
            <Route exact path={`${path}/webinars/:id`}>
                <WebinarSingle />
            </Route>
        </SwitchWith404>
    );
};

export default ReportsRoutes;
