import { createReducer } from '@reduxjs/toolkit';

import { setRedirectUrl } from '../actions/redirects';

const initialState = {
    path: null,
};

export default createReducer(initialState, {
    [setRedirectUrl]: handleSetRedirectUrl,
});

function handleSetRedirectUrl(state, action) {
    state.path = action.payload;
}
