import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { postContactSubmission } from '@actions/contactUs';
import {
    getContactUsError,
    getContactUsIsPosting,
    getContactUsPostSuccess,
} from '@selectors/contactUs';

import useForm from 'lib/src/hooks/useForm';
import usePrevious from 'lib/src/hooks/usePrevious';

export default function useContactSubmissionPost() {
    const dispatch = useDispatch();

    const [form, handleChange] = useForm({ name: '', email: '', message: '' });
    const [showMessage, setShowMessage] = useState(false);

    const handleSubmit = useCallback(() => {
        dispatch(postContactSubmission(form));
    }, [dispatch, form]);

    const isPosting = useSelector(getContactUsIsPosting);
    const postSuccess = useSelector(getContactUsPostSuccess);
    const error = useSelector(getContactUsError);
    const prevPostSuccess = usePrevious(postSuccess);

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            setShowMessage(true);
        }
    }, [isPosting, postSuccess, prevPostSuccess]);

    return { form, handleChange, handleSubmit, error, isPosting, showMessage };
}
