import { useCallback, useRef, useState } from 'react';

import useFieldValidation from '../../hooks/useFieldValidation';
import FormField from './FormField';

const minRows = 1;
const maxRows = 10;

const TextArea = ({
    name,
    value,
    onChange,
    label = '',
    placeholder = '',
    required = false,
    validationRegExp,
    minLength,
    maxLength,
    disabled,
    customValidate,
    extraClasses = '',
}) => {
    const memoizedValidate = useCallback(_validate, [minLength, maxLength, validationRegExp]);
    const [error, showError] = useFieldValidation({
        name,
        required,
        value,
        customValidate,
        extendedValidate: memoizedValidate,
    });

    const [rows, setRows] = useState(minRows);
    const inputRef = useRef();

    return (
        <FormField
            className={`${extraClasses}`}
            name={name}
            label={label}
            required={required}
            error={error}
        >
            <textarea
                ref={inputRef}
                rows={rows}
                className={`form-input text-area ${error ? 'error' : ''}`}
                placeholder={placeholder}
                name={name}
                id={name}
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={disabled}
            />
        </FormField>
    );

    function handleChange(e) {
        e.preventDefault();

        fitRows();
        onChange(name, e.target.value);
    }

    function handleBlur() {
        showError();
    }

    function fitRows() {
        const input = inputRef.current;
        const prevRows = input.rows;
        input.rows = minRows; // reset number of rows in textarea

        const computedInput = window.getComputedStyle(input);
        const padding =
            parseFloat(computedInput.paddingTop) + parseFloat(computedInput.paddingBottom);
        const lineHeight = parseFloat(computedInput.lineHeight);
        const scrollHeight = input.scrollHeight - padding;

        const curRows = Math.floor(scrollHeight / lineHeight);

        if (curRows === prevRows) {
            input.rows = curRows;
        }

        if (curRows >= maxRows) {
            input.rows = maxRows;
            input.scrollTop = input.scrollHeight;
        }

        setRows(curRows < maxRows ? curRows : maxRows);
    }

    function _validate(val) {
        if (!val) return;

        if (minLength && val.length < minLength) {
            return `Value must have at least ${minLength} characters.`;
        }
        if (maxLength && val.length > maxLength) {
            return `Value cannot have more than ${maxLength} characters.`;
        }
        if (validationRegExp && !RegExp(validationRegExp).test(val)) {
            return 'Invalid value provided.';
        }
    }
};

export default TextArea;
