import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';

export const fetchUsersProblemCaseStudiesRequest = createAction(
    'fetchUsersProblemCaseStudiesRequest',
);
export const fetchUsersProblemCaseStudiesSuccess = createAction(
    'fetchUsersProblemCaseStudiesSuccess',
);
export const fetchUsersProblemCaseStudiesFailure = createAction(
    'fetchUsersProblemCaseStudiesFailure',
);

export const fetchUsersProblemCaseStudies = id => async dispatch => {
    dispatch(fetchUsersProblemCaseStudiesRequest());

    try {
        const { data } = await api.get(`ProblemCaseStudies/user/${id}`);

        return dispatch(fetchUsersProblemCaseStudiesSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchUsersProblemCaseStudiesFailure, e);
    }
};
