import dayjs from 'dayjs';
import { RAW_S3_STORAGE_URL } from 'src/config';

import { postedByDateTime } from 'src/constants/dateFormats';

import LinkButton from 'lib/src/components/button/LinkButton';

const NewsListCard = ({ item, isRelatedPosts }) => {
    const { id, publishedOn, listImageS3Key, title, summary, newsTags, isPinned } = item;

    return (
        <div
            className={`list-card-container flex-row ${
                isRelatedPosts ? 'related-list' : 'full-list'
            } `}
        >
            <div className="post-content flex-column">
                {isPinned && <i className="pinned-icon fas fa-thumbtack"></i>}

                <p className="post-details">
                    Posted on&nbsp;
                    <strong>{dayjs(publishedOn).format(postedByDateTime)}</strong>
                </p>

                {!!newsTags.length && (
                    <div className="flex-row tag-wrapper">
                        {newsTags.map(({ tag }, index) => {
                            return (
                                index < 3 && (
                                    <div key={`${tag}-${index}`} className="tag">
                                        {tag}
                                    </div>
                                )
                            );
                        })}
                    </div>
                )}

                <div className="wysiwyg flex">
                    <h3>{title}</h3>
                    <p>{summary}</p>
                </div>

                <div className="button-container flex-row align-center flex-column-lm align-stretch-lm">
                    <LinkButton href={`/news/${id}`} color="purple">
                        Read more
                    </LinkButton>
                </div>
            </div>

            <div
                className="image-wrapper"
                style={{ backgroundImage: `url(${RAW_S3_STORAGE_URL}/${listImageS3Key})` }}
            />
        </div>
    );
};

export default NewsListCard;
