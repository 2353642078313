import { useParams } from 'react-router';

import { RAW_S3_STORAGE_URL } from 'src/config';

import useSingleFundingFetch from './hooks/useSingleFundingFetch';

import DataCheck from '@components/dataCheck/DataCheck';
import FundingSingleDetails from './FundingSingleDetails';
import Banner from '@components/banner/Banner';

const FundingSingle = () => {
    const { id } = useParams();
    const [funding, isFetching, error] = useSingleFundingFetch(id);

    return (
        <DataCheck data={funding} isFetching={isFetching} error={error}>
            <div className="container">
                <Banner
                    title="Funding"
                    image={`${RAW_S3_STORAGE_URL}/${funding ? funding.imageS3Key : ''}`}
                />
            </div>

            <FundingSingleDetails funding={funding} />
        </DataCheck>
    );
};

export default FundingSingle;
