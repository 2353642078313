import Modal from 'lib/src/components/modal/Modal';
import Description from 'lib/src/components/typography/Description';
import ActionButton from 'lib/src/components/button/ActionButton';
import ButtonRow from 'lib/src/components/button/ButtonRow';

const UpdateProfileSuccessModal = ({ handleClose }) => {
    return (
        <Modal title="Success" closeModal={handleClose}>
            <Description>You have successfully updated your details</Description>

            <ButtonRow alignment="left">
                <ActionButton color="grey" onClick={() => handleClose()}>
                    Close
                </ActionButton>
            </ButtonRow>
        </Modal>
    );
};

export default UpdateProfileSuccessModal;
