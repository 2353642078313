import { useState } from 'react';
import { useSelector } from 'react-redux';

import { metaData } from 'src/constants/meta';

import AddSolutionCaseStudyModal from './AddSolutionCaseStudyModal';
import EditSolutionCaseStudyModal from './EditSolutionCaseStudyModal';
import useFetchSolutionCaseStudies from './hooks/useFetchSolutionCaseStudies';

import DataCheck from '@components/dataCheck/DataCheck';
import DashboardHeader from '@components/dashboardHeader/DashboardHeader';
import ActionButton from 'lib/src/components/button/ActionButton';
import DashboardCaseStudyList from '../shared/DashboardCaseStudyList';

import { getSingleLoggedInUser } from '@selectors/auth';
import Meta from '@components/meta/Meta';

const SolutionCaseStudies = () => {
    const user = useSelector(getSingleLoggedInUser);

    const { caseStudies, error, isFetching } = useFetchSolutionCaseStudies(user);

    const [showEditModal, setShowEditModal] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);

    const [caseStudy, setCaseStudy] = useState(null);

    return (
        <>
            <Meta data={metaData.mySolutionCaseStudies} disableIndex />

            <div className="dashboard-container solution-case-studies-container page-margin-top page-margin-bottom">
                <DashboardHeader pageTitle="My Solution Case Studies" />
                <div className="container page-section">
                    <div className="list-header-container wysiwyg flex-row align-center justify-between">
                        <h2>My Solution Cases Studies</h2>
                    </div>
                    <div className="list-container">
                        <DataCheck
                            data={caseStudies}
                            error={error}
                            isFetching={isFetching}
                            noDataMessage="No entries to display"
                        >
                            <div className="list-container flex-row flex-wrap flex-column-pt">
                                <DashboardCaseStudyList
                                    caseStudies={caseStudies}
                                    handleEditClick={handleEditClick}
                                    error={error}
                                    isFetching={isFetching}
                                    urlPrefix="solutions"
                                />
                            </div>
                        </DataCheck>
                    </div>
                    <ActionButton onClick={() => setShowAddModal(true)} color="grey">
                        Add new
                    </ActionButton>
                </div>
            </div>
            {showEditModal && (
                <EditSolutionCaseStudyModal
                    closeModal={() => setShowEditModal(false)}
                    caseStudy={caseStudy}
                />
            )}
            {showAddModal && (
                <AddSolutionCaseStudyModal closeModal={() => setShowAddModal(false)} />
            )}
        </>
    );

    function handleEditClick(caseStudy) {
        setShowEditModal(!showEditModal);
        setCaseStudy(caseStudy);
    }
};

export default SolutionCaseStudies;
