import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';

// Login
export const fetchSingleEventRequest = createAction('fetchSingleEventRequest');
export const fetchSingleEventSuccess = createAction('fetchSingleEventSuccess');
export const fetchSingleEventFailure = createAction('fetchSingleEventFailure');

export const fetchSingleEvent = eventID => async dispatch => {
    dispatch(fetchSingleEventRequest());

    try {
        const { data } = await api.get(`events/${eventID}`);
        return dispatch(fetchSingleEventSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchSingleEventFailure, e);
    }
};
