import ActionButton from 'lib/src/components/button/ActionButton';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import TextArea from 'lib/src/components/form/TextArea';
import useForm from 'lib/src/hooks/useForm';

import useAllFundingFetch from './hooks/useAllFundingFetch';
import useShowMoreItems from 'src/hooks/useShowMoreItems';
import useFundingToShow from './hooks/useFundingToShow';

import DataCheck from '@components/dataCheck/DataCheck';
import FundingListCard from './FundingListCard';
import FilterSubHeading from '@components/filterSubHeading/FilterSubHeading';

const FundingList = () => {
    const [funding, isFetching, error] = useAllFundingFetch();

    const [form, handleChange] = useForm({ searchString: '' });
    const [amountToShow, showMoreItems] = useShowMoreItems(12, 12);
    const [fundingToShow] = useFundingToShow(amountToShow, form);

    return (
        <DataCheck data={funding} isFetching={isFetching} error={error}>
            <FilterSubHeading
                id="funding"
                title="Funding Opportunities"
                extraClasses="justify-between align-center"
            >
                <div className="filter-box-wrapper">
                    <TextArea
                        name="searchString"
                        value={form.searchString}
                        onChange={handleChange}
                        placeholder="Search by title or summary"
                        extraClasses="grey filter-bar-search margin-left"
                    />
                </div>
            </FilterSubHeading>

            <div className="container page-margin-bottom">
                <div className="flex-row flex-wrap flex-column-lm">
                    {fundingToShow.items.map(funding => (
                        <FundingListCard key={funding.id} funding={funding} />
                    ))}
                </div>

                {fundingToShow.total > amountToShow && (
                    <ButtonRow>
                        <ActionButton color="grey" onClick={showMoreItems}>
                            See more Funding Awards
                        </ActionButton>
                    </ButtonRow>
                )}
            </div>
        </DataCheck>
    );
};

export default FundingList;
