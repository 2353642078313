import { metaData } from 'src/constants/meta';

import RegisterImage from '@content/images/auth/register/Battery_image.jpg';

import Banner from '@components/banner/Banner';
import ForgotPasswordForm from './ForgotPasswordForm';
import Meta from '@components/meta/Meta';

const ForgotPassword = () => {
    return (
        <>
            <Meta data={metaData.forgotPassword} disableIndex />
            <div className="container">
                <Banner title="Forgot password" image={RegisterImage} />
            </div>
            <section className="auth-subheading">
                <div className="container page-section">
                    <h2>Please enter your email address</h2>
                </div>
            </section>
            <div className="container flex-row justify-center page-section page-margin-bottom">
                <ForgotPasswordForm />
            </div>
        </>
    );
};

export default ForgotPassword;
