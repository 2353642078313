// alignmen options are: left, center, right
const Description = ({ children, tag = 'p', alignment = '', className, ...props }) => {
    const Tag = tag;

    return (
        <Tag className={`page-description ${alignment} ${className}`} {...props}>
            {children}
        </Tag>
    );
};

export default Description;
