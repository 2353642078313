const FilterSubHeading = ({ id = '', title, children, extraClasses = '', subText = '' }) => {
    return (
        <div id={id} className="sub-heading-container">
            <div
                className={`container flex-row align-center flex-column-lm align-stretch-lm ${extraClasses}`}
            >
                <div className="sub-heading-title-container flex-5 flex-column-lm justify-center">
                    <h2>{title}</h2>
                    <p>{!!subText.length && subText}</p>
                </div>
                {children}
            </div>
        </div>
    );
};

export default FilterSubHeading;
