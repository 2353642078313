import { metaData } from 'src/constants/meta';

import Banner from '@components/banner/Banner';
import Meta from '@components/meta/Meta';
import BannerImg from '@content/images/banners/cross-sectoral-banner.png';

const NotFound = () => {
    return (
        <>
            <Meta data={metaData.pageNotFound} disableIndex />
            <div className="container">
                <Banner title="Page not found" image={BannerImg} />
            </div>
            <div className="container page-section wysiwyg text-center page-margin-bottom">
                <h2>404: Page not found</h2>
                <p>The resource you were looking for could not be found.</p>
            </div>
        </>
    );
};

export default NotFound;
