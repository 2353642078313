import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getReportsError, getReportsIsFetching, getSingleReport } from '@selectors/reports';
import { fetchReportSingle } from '@actions/reports/index';

import usePrevious from 'lib/src/hooks/usePrevious';

const useSingleReportFetch = reportID => {
    const dispatch = useDispatch();
    const history = useHistory();
    const report = useSelector(state => getSingleReport(state, reportID));
    const isFetching = useSelector(getReportsIsFetching);
    const error = useSelector(getReportsError);

    const prevProps = usePrevious({ error });

    useEffect(() => {
        dispatch(fetchReportSingle(reportID));
    }, [dispatch, reportID]);

    useEffect(() => {
        if (!prevProps.error && error) {
            history.push('/resources');
        }
    }, [error, prevProps.error, history]);

    return [report, isFetching, error];
};

export default useSingleReportFetch;
