import LinkButton from 'lib/src/components/button/LinkButton';
import useIsLoggedIn from 'lib/src/hooks/useIsLoggedIn';

const GetStarted = () => {
    const isLoggedIn = useIsLoggedIn();

    return (
        <div className="get-started-container flex-row align-center justify-center flex-column-lt fluid">
            <div className="flex-column text-container">
                <h3>Ready to get started?</h3>
                <p>Enter the Networking hub</p>
            </div>

            <div className="flex-row flex-column-lt">
                {isLoggedIn ? (
                    <LinkButton color="green" href="dashboard">
                        Dashboard
                    </LinkButton>
                ) : (
                    <>
                        <LinkButton color="green" href="auth/register">
                            Register
                        </LinkButton>
                        <LinkButton color="transparent" href="auth/login">
                            Login
                        </LinkButton>
                    </>
                )}
            </div>
        </div>
    );
};

export default GetStarted;
