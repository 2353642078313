import { combineReducers } from 'redux';

import authReducer from './auth';
import eventsReducer from './events';
import fieldErrorsReducer from 'lib/src/redux/reducers/fieldErrors';
import newsReducer from './news';
import fundingReducer from './funding';
import redirectsReducer from 'lib/src/redux/reducers/redirects';
import reportsReducer from './reports';
import webinarsReducer from './webinars';
import caseStudiesReducer from './caseStudies';
import usersReducer from './users';
import contactUsReducer from './contactUs';

//dashboard
import connectionRequestsReducer from './connectionRequests';

export default combineReducers({
    authReducer,
    eventsReducer,
    fieldErrorsReducer,
    newsReducer,
    fundingReducer,
    redirectsReducer,
    reportsReducer,
    webinarsReducer,
    caseStudiesReducer,
    usersReducer,
    contactUsReducer,
    connectionRequestsReducer,
});
