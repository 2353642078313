import React from 'react';
import { HashLink } from 'react-router-hash-link';

const HashLinkButton = ({ color = 'blue', href = '', icon, children, extraClasses = '' }) => (
    <HashLink className={`button ${color} ${extraClasses && extraClasses}`} smooth to={href}>
        {icon && <i className={`icon far fa-fw fa-${icon}`}></i>}
        <span className="text">{children}</span>
    </HashLink>
);

export default HashLinkButton;
