import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';

// Login
export const postLoginRequest = createAction('postLoginRequest');
export const postLoginSuccess = createAction('postLoginSuccess');
export const postLoginFailure = createAction('postLoginFailure');

export const postLogin = postBody => async dispatch => {
    dispatch(postLoginRequest());
    try {
        const { data } = await api.post('auth/login', postBody);
        localStorage.setItem('token', data.token);

        return dispatch(postLoginSuccess(data.token));
    } catch (e) {
        handleApiErrors(dispatch, postLoginFailure, e);
    }
};
