import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';

export const createProblemCaseStudyRequest = createAction('createProblemCaseStudyRequest');
export const createProblemCaseStudySuccess = createAction('createProblemCaseStudySuccess');
export const createProblemCaseStudyFailure = createAction('createProblemCaseStudyFailure');

export const createProblemCaseStudy = postBody => async dispatch => {
    dispatch(createProblemCaseStudyRequest());

    try {
        const { data } = await api.post('ProblemCaseStudies', postBody);
        return dispatch(createProblemCaseStudySuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, createProblemCaseStudyFailure, e);
    }
};
