import BreadCrumb from '@components/breadcrumb/BreadCrumb';
import { getSingleLoggedInUser } from '@selectors/auth';

import { useSelector } from 'react-redux';

const DashboardHeader = ({ pageTitle }) => {
    const user = useSelector(getSingleLoggedInUser);

    const breadcrumbArray = [
        { text: 'Home', link: '/' },
        { text: 'User Dashboard', link: '/dashboard' },
        { text: pageTitle, link: null },
    ];

    return (
        <div className="dashboard-header">
            <div className="container">
                <div className="page-section header">
                    <h1 className="title">{`${user.firstName} ${user.lastName}'s dashboard`}</h1>
                </div>
            </div>
            <BreadCrumb breadcrumbArray={breadcrumbArray} />
        </div>
    );
};

export default DashboardHeader;
