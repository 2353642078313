import { createPortal } from 'react-dom';

import { useState, useEffect } from 'react';
import Title from '../typography/Title';

// size options are: large, medium, small
const Modal = ({ closeModal, children, size = 'small', style = {}, title }) => {
    const [hidden, updateHidden] = useState(true);

    useEffect(() => {
        document.body.style.overflow = 'hidden';

        // initially hidding the modal so that we can ease
        // in the opacity.
        updateHidden(false);

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, []);

    const className = hidden ? 'hidden' : '';
    return createPortal(
        <div className={`modal-overlay  ${className}`} onClick={closeModal}>
            <div
                className={`modal-body custom-scroll ${size} ${className}`}
                onClick={handleClickBody}
                style={style}
            >
                {!!title && <Title>{title}</Title>}
                {children}
            </div>
        </div>,
        document.getElementById('modal-root'),
    );

    function handleClickBody(e) {
        e.stopPropagation();
    }
};

export default Modal;
