import { createReducer } from '@reduxjs/toolkit';

import {
    fetchInitiatedConnectionsRequest,
    fetchInitiatedConnectionsSuccess,
    fetchInitiatedConnectionsFailure,
    fetchRequestedConnectionsRequest,
    fetchRequestedConnectionsSuccess,
    fetchRequestedConnectionsFailure,
    createIntroductionRequestRequest,
    createIntroductionRequestSuccess,
    createIntroductionRequestFailure,
} from '@actions/connectionRequests';
import { convertArrToObj } from 'lib/src/utils/generic';

const initialState = {
    isFetchingInitiated: false,
    isFetchingRequested: false,
    initiatedConnections: {},
    requestedConnections: {},
    errorInitiated: null,
    errorRequested: null,
    isPosting: false,
    postError: null,
    postSuccess: false,
};

export default createReducer(initialState, {
    [fetchInitiatedConnectionsRequest]: handleFetchInitiatedRequest,
    [fetchInitiatedConnectionsSuccess]: handleFetchInitiatedSuccess,
    [fetchInitiatedConnectionsFailure]: handleInitiatedFailure,
    [fetchRequestedConnectionsRequest]: handleFetchRequestedRequest,
    [fetchRequestedConnectionsSuccess]: handleFetchRequestedSuccess,
    [fetchRequestedConnectionsFailure]: handleRequestedFailure,
    [createIntroductionRequestRequest]: handlePostRequest,
    [createIntroductionRequestSuccess]: handlePostSuccess,
    [createIntroductionRequestFailure]: handlePostFailure,
});

function handleFetchInitiatedRequest(state) {
    state.isFetchingInitiated = true;
    state.errorInitiated = null;
}

function handleFetchInitiatedSuccess(state, action) {
    state.isFetchingInitiated = false;
    state.initiatedConnections = convertArrToObj(action.payload);
}

function handleInitiatedFailure(state, action) {
    state.isFetchingInitiated = false;
    state.errorInitiated = action.payload;
}

function handleFetchRequestedRequest(state) {
    state.isFetchingRequested = true;
    state.errorRequested = null;
}

function handleFetchRequestedSuccess(state, action) {
    state.isFetchingRequested = false;
    state.requestedConnections = convertArrToObj(action.payload);
}

function handleRequestedFailure(state, action) {
    state.isFetchingRequested = false;
    state.errorRequested = action.payload;
}

function handlePostRequest(state) {
    state.isPosting = true;
    state.postError = null;
    state.postSuccess = false;
}

function handlePostFailure(state, action) {
    state.isPosting = false;
    state.postError = action.payload;
}

function handlePostSuccess(state, action) {
    state.isPosting = false;
    state.postSuccess = true;
    state.initiatedConnections[action.payload.id] = action.payload;
}
