import { metaData } from 'src/constants/meta';

import ReportsList from '../reports/list/ReportsList';

import Banner from '@components/banner/Banner';

import BannerImage from '@content/images/banners/resources-banner.png';
import Meta from '@components/meta/Meta';
import FilterSubHeading from '@components/filterSubHeading/FilterSubHeading';
import TextArea from 'lib/src/components/form/TextArea';
import useForm from 'lib/src/hooks/useForm';
import useFetchAllNewsTags from 'src/hooks/useFetchAllNewsTags';
import DataCheck from '@components/dataCheck/DataCheck';

const Reports = () => {
    const { newsTagsOptions: reportTagOptions, isFetching, error } = useFetchAllNewsTags();

    const [form, handleChange] = useForm({
        reportSearchString: '',
        webinarSearchString: '',
    });

    return (
        <>
            <Meta data={metaData.resources} />

            <div className="container">
                <Banner title="Reports" image={BannerImage} />
            </div>
            <DataCheck isFetching={isFetching} error={error} data={reportTagOptions}>
                <FilterSubHeading id="reports" title="Search Reports">
                    <div className="filter-box-wrapper flex-row flex align-center flex-column-lm align-stretch-lm">
                        <div className="flex-column">
                            <TextArea
                                name="reportSearchString"
                                onChange={handleChange}
                                placeholder="Search by title or summary"
                                extraClasses="grey filter-bar-search no-margin"
                            />
                        </div>
                    </div>
                </FilterSubHeading>
            </DataCheck>

            <div className="container">
                <ReportsList searchString={form.reportSearchString} />
            </div>
        </>
    );
};

export default Reports;
