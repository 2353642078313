import { Route, useRouteMatch } from 'react-router-dom';
import Dashboard from '@pages/_dashboard/home/Home';

import ConnectionRequests from '@pages/_dashboard/connectionRequests/ConnectionRequests';
import SolutionCaseStudies from '@pages/_dashboard/caseStudies/solutionsCaseStudies/SolutionCaseStudies';
import ProblemCaseStudies from '@pages/_dashboard/caseStudies/problemCaseStudies/ProblemCaseStudies';
import Profile from '@pages/_dashboard/profile/Profile';
import SwitchWith404 from './SwitchWith404';

const DashboardRoutes = () => {
    const { path } = useRouteMatch();
    return (
        <SwitchWith404>
            <Route exact path={`${path}`}>
                <Dashboard />
            </Route>
            <Route exact path={`${path}/profile`}>
                <Profile />
            </Route>
            <Route exact path={`${path}/connection-requests`}>
                <ConnectionRequests />
            </Route>
            <Route exact path={`${path}/solution-case-studies`}>
                <SolutionCaseStudies />
            </Route>
            <Route exact path={`${path}/problem-case-studies`}>
                <ProblemCaseStudies />
            </Route>
        </SwitchWith404>
    );
};

export default DashboardRoutes;
