import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';

export const fetchSingleSolutionCaseStudyRequest = createAction(
    'fetchSingleSolutionCaseStudyRequest',
);
export const fetchSingleSolutionCaseStudySuccess = createAction(
    'fetchSingleSolutionCaseStudySuccess',
);
export const fetchSingleSolutionCaseStudyFailure = createAction(
    'fetchSingleSolutionCaseStudyFailure',
);

export const fetchSingleSolutionCaseStudy = caseStudyID => async dispatch => {
    dispatch(fetchSingleSolutionCaseStudyRequest());

    try {
        const { data } = await api.get(`SolutionCaseStudies/${caseStudyID}`);

        return dispatch(fetchSingleSolutionCaseStudySuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchSingleSolutionCaseStudyFailure, e);
    }
};
