import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editProblemCaseStudy } from '@actions/caseStudies';
import { getVimeoLink, getYouTubeLink } from 'lib/src/utils/generic';
import usePrevious from 'lib/src/hooks/usePrevious';
import useForm from 'lib/src/hooks/useForm';
import {
    getCaseStudiesIsPosting,
    getCaseStudiesPostSuccess,
    getCaseStudiesPostError,
} from '@selectors/caseStudies';

const useEditCaseStudy = (data, closeModal) => {
    const dispatch = useDispatch();
    const [showYouTubeLink, setShowYouTubeLink] = useState(false);
    const [showVimeoLink, setShowVimeoLink] = useState(false);

    const initialState = {
        ...data,
        summary: data.summary || '',
        youTubeLink: !!data.youTubeLink ? getYouTubeLink(data.youTubeLink) : '',
        vimeoLink: !!data.vimeoLink ? getVimeoLink(data.vimeoLink) : '',
    };
    const [formState, handleChange] = useForm(initialState);

    const isPosting = useSelector(getCaseStudiesIsPosting);
    const error = useSelector(getCaseStudiesPostError);
    const postSuccess = useSelector(getCaseStudiesPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);
    const prevProps = usePrevious({ showVimeoLink, showYouTubeLink });

    const handleSubmit = () => {
        dispatch(editProblemCaseStudy(data.id, formState));
    };

    useEffect(() => {
        setShowYouTubeLink(!!data.youTubeLink);
        setShowVimeoLink(!!data.vimeoLink);
    }, [data.youTubeLink, data.vimeoLink]);

    useEffect(() => {
        if (prevProps.showVimeoLink && !showVimeoLink) {
            handleChange('vimeoLink', null);
        }
        if (prevProps.showYouTubeLink && !showYouTubeLink) {
            handleChange('youTubeLink', null);
        }
    }, [showVimeoLink, showYouTubeLink, prevProps, handleChange]);

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            closeModal();
        }
    }, [postSuccess, closeModal, prevPostSuccess]);

    return {
        handleSubmit,
        formState,
        handleChange,
        isPosting,
        error,
        showYouTubeLink,
        showVimeoLink,
        setShowYouTubeLink,
        setShowVimeoLink,
    };
};

export default useEditCaseStudy;
