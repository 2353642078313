import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';

// Login
export const fetchEventsListRequest = createAction('fetchEventsListRequest');
export const fetchEventsListSuccess = createAction('fetchEventsListSuccess');
export const fetchEventsListFailure = createAction('fetchEventsListFailure');

export const fetchEventsList = () => async dispatch => {
    dispatch(fetchEventsListRequest());

    try {
        const { data } = await api.get('events');
        return dispatch(fetchEventsListSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchEventsListFailure, e);
    }
};
