import dayjs from 'dayjs';

import { caseStudyNames } from 'lib/src/constants/enums';
import { connectionStatus } from '@constants/enums';
import Modal from 'lib/src/components/modal/Modal';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import ActionButton from 'lib/src/components/button/ActionButton';

const ViewConnectionModal = ({ item, closeModal }) => {
    return (
        <Modal title="Connection Request" closeModal={closeModal}>
            <div className="view-connection-modal">
                <div className="modal-field-container">
                    <h6 className="modal-field-title">Name </h6>
                    <span className="modal-field">
                        {item.modalType ? item.createdByUserName : item.sentToUserName}
                    </span>
                </div>
                {item.modalType && (
                    <div className="modal-field-container">
                        <h6 className="modal-field-title">Email </h6>
                        <span className="modal-field">{item.createdByUserEmail}</span>
                    </div>
                )}
                <div className="modal-field-container">
                    <h6 className="modal-field-title">Date Created </h6>
                    <span className="modal-field">
                        {dayjs(item.createdOn).format('DD/MM/YYYY h:mm A')}
                    </span>
                </div>
                <div className="modal-field-container">
                    <h6 className="modal-field-title">Organisation </h6>
                    <span className="modal-field">{item.organisationName}</span>
                </div>
                <div className="modal-field-container">
                    <h6 className="modal-field-title">Case Study Title </h6>
                    <span className="modal-field">{item.caseStudyTitle}</span>
                </div>
                <div className="modal-field-container">
                    <h6 className="modal-field-title">Case Study Type </h6>
                    <span className="modal-field">{caseStudyNames[item.caseStudyType]}</span>
                </div>
                <div className="modal-field-container">
                    <h6 className="modal-field-title">Approved On </h6>
                    <span className="modal-field">
                        {dayjs(item.approvedOn).format('DD/MM/YYYY h:mm A')}
                    </span>
                </div>
                <div className="modal-field-container">
                    <span className="modal-field">
                        <span className="status">
                            Status{' '}
                            <button
                                className={`button small-button cursor-initial ${
                                    item.status === 1 ? 'green' : 'orange'
                                }`}
                            >
                                {connectionStatus[item.status]}
                            </button>
                        </span>
                    </span>
                </div>
            </div>

            <ButtonRow alignment="right">
                <ActionButton color="grey" onClick={closeModal}>
                    Close
                </ActionButton>
            </ButtonRow>
        </Modal>
    );
};

export default ViewConnectionModal;
