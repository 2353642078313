import dayjs from 'dayjs';

import { RAW_S3_STORAGE_URL } from 'src/config';
import { postedByDateTime } from 'src/constants/dateFormats';

import LinkButton from 'lib/src/components/button/LinkButton';

const ReportsListCard = ({ resource }) => {
    const { id, title, summary, imageS3Key, createdOn, reportTags } = resource;

    return (
        <div className="list-card-container flex-row full-list">
            <div className="post-content flex-column">
                <p className="post-details margined">
                    Posted on&nbsp;
                    <strong>{dayjs(createdOn).format(postedByDateTime)}</strong>
                </p>

                {!!reportTags?.length && (
                    <div className="flex-row tag-wrapper">
                        {reportTags.map(({ tag }, index) => {
                            return (
                                index < 3 && (
                                    <div key={`${tag}-${index}`} className="tag">
                                        {tag}
                                    </div>
                                )
                            );
                        })}
                    </div>
                )}

                <div className="wysiwyg flex">
                    <h3>{title}</h3>
                    <p>{summary}</p>
                </div>

                <div className="button-container flex-row align-center flex-column-lm align-stretch-lm">
                    <LinkButton href={`/resources/reports/${id}`} color="purple">
                        Read report
                    </LinkButton>
                </div>
            </div>

            <div
                className="image-wrapper"
                style={{ backgroundImage: `url(${RAW_S3_STORAGE_URL}/${imageS3Key})` }}
            />
        </div>
    );
};

export default ReportsListCard;
