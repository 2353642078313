import useResetPassword from './hooks/useResetPassword';
import { useParams } from 'react-router-dom';

import Form from 'lib/src/components/form/Form';
import FormRow from 'lib/src/components/form/FormRow';

import TextInput from 'lib/src/components/form/TextInput';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import ActionButton from 'lib/src/components/button/ActionButton';

import ResetPasswordSuccessModal from './ResetPasswordSuccessModal';

const ResetPasswordForm = () => {
    const { guid } = useParams();

    const {
        form: { password, confirmPassword },
        handleChange,
        validateConfirmPassword,
        handleSubmit,
        isPosting,
        guidSuccess,
        showSuccessModal,
    } = useResetPassword(guid);

    if (guidSuccess) {
        return (
            <>
                <Form
                    onSubmit={handleSubmit}
                    isPosting={isPosting}
                    extraClasses="flex-6 flex-12-sd"
                    omitButtons
                >
                    <FormRow extraClasses="flex-column-lm">
                        <TextInput
                            type="password"
                            name="password"
                            value={password}
                            onChange={handleChange}
                            placeholder="Password *"
                            extraClasses="auth-form-field"
                            required
                        />

                        <TextInput
                            type="password"
                            name="confirmPassword"
                            value={confirmPassword}
                            onChange={handleChange}
                            placeholder="Confirm password *"
                            customValidate={validateConfirmPassword}
                            extraClasses="auth-form-field"
                            required
                        />
                    </FormRow>
                    <ButtonRow alignment="right">
                        <ActionButton isPosting={isPosting} color="grey">
                            Submit
                        </ActionButton>
                    </ButtonRow>
                </Form>
                {showSuccessModal && <ResetPasswordSuccessModal />}
            </>
        );
    } else {
        return (
            <div className="container page-section flex-row justify-center forgot-password-success">
                <h2>Invalid link.</h2>
            </div>
        );
    }
};

export default ResetPasswordForm;
