import useForm from 'lib/src/hooks/useForm';
import { useDispatch, useSelector } from 'react-redux';
import usePrevious from 'lib/src/hooks/usePrevious';

import {
    getCaseStudiesError,
    getCaseStudiesIsPosting,
    getCaseStudiesPostSuccess,
} from '@selectors/caseStudies';

import { createSolutionCaseStudy } from '@actions/caseStudies/index';

const initialState = {
    title: '',
    description: '',
    youTubeLink: '',
    vimeoLink: '',
    summary: '',
    sectors: [],
    isPublished: null,
};

export default function useAddSolutionCaseStudy() {
    const dispatch = useDispatch();

    const [form, handleChange] = useForm(initialState);

    function handleSubmit() {
        const postBody = { ...form, date: new Date() };

        dispatch(createSolutionCaseStudy(postBody));
    }

    const isPosting = useSelector(getCaseStudiesIsPosting);
    const error = useSelector(getCaseStudiesError);
    const postSuccess = useSelector(getCaseStudiesPostSuccess);

    const prevPostSuccess = usePrevious(postSuccess);

    return { form, handleChange, handleSubmit, isPosting, error, prevPostSuccess, postSuccess };
}
