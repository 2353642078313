import nikoletaPicture from '@content/images/ourTeam/nikoleta-headshot.png';
import sheenaPicture from '@content/images/ourTeam/sheena-headshot.png';
import kevinPicture from '@content/images/ourTeam/kevin-headshot.png';
import annaPicture from '@content/images/ourTeam/anna-headshot.jpeg';
import juanMariaPicture from '@content/images/ourTeam/juan-maria-headshot.jpeg';
import mattPicture from '@content/images/ourTeam/matt-headshot.jpg';
import neelamPicture from '@content/images/ourTeam/neelam-headshot.jpg';

const ourTeam = [
    {
        id: 1,
        name: 'Kevin Brundish',
        jobTitle: 'Innovation Network Chair',
        jobDescription: 'Strategy Director, AMTE Power',
        link: 'https://www.linkedin.com/in/kevin-brundish-59158221/',
        image: kevinPicture,
    },
    {
        id: 2,
        name: 'Anna Wise',
        jobTitle: 'Innovation Network Deputy Chair',
        jobDescription: 'Head of Strategic Programmes, Nyobolt',
        link: 'https://www.linkedin.com/in/annamwise/',
        image: annaPicture,
    },
    {
        id: 3,
        name: 'Nikoleta Piperidou',
        jobTitle: 'Project Lead',
        jobDescription: 'Energy & Infrastructure, Innovate UK Business Connect',
        link: 'https://ktn-uk.org/people/nikoleta-piperidou/',
        image: nikoletaPicture,
    },
    {
        id: 4,
        name: 'Sheena Hindocha',
        jobTitle: 'Project Co-Lead',
        jobDescription: 'Materials Chemistry, Innovate UK Business Connect',
        link: 'https://ktn-uk.org/people/sheena-hindocha/',
        image: sheenaPicture,
    },
    {
        id: 5,
        name: 'Matt Cliffe',
        jobTitle: 'Marketing Manager',
        jobDescription: 'Innovate UK Business Connect',
        link: 'https://ktn-uk.org/people/matt-cliffe',
        image: mattPicture,
    },
    {
        id: 6,
        name: 'Juan Maria Gonzalez Carballo',
        jobTitle: 'Project Team',
        jobDescription: 'Chemistry, Innovate UK Business Connect',
        link: 'https://ktn-uk.org/people/juan-maria-gonzalez-carballo/',
        image: juanMariaPicture,
    },
    {
        id: 7,
        name: 'Neelam Mughal',
        jobTitle: 'Project Team',
        jobDescription: 'Advanced Materials, Innovate UK Business Connect',
        link: 'https://ktn-uk.org/people/neelam-mughal/',
        image: neelamPicture,
    },
];

export default ourTeam;
