import { createReducer } from '@reduxjs/toolkit';
import { convertArrToObj } from 'lib/src/utils/generic';

import {
    fetchFundingListRequest,
    fetchFundingListSuccess,
    fetchFundingListFailure,
    fetchSingleFundingRequest,
    fetchSingleFundingSuccess,
    fetchSingleFundingFailure,
} from '@actions/funding';

const initialState = {
    isFetching: false,
    error: null,
    funding: {},
};

export default createReducer(initialState, {
    [fetchFundingListRequest]: handleFetchRequest,
    [fetchFundingListFailure]: handleFetchFailure,
    [fetchFundingListSuccess]: handleFetchAllSuccess,
    [fetchSingleFundingRequest]: handleFetchRequest,
    [fetchSingleFundingFailure]: handleFetchFailure,
    [fetchSingleFundingSuccess]: handleFetchSingleSuccess,
});

function handleFetchRequest(state) {
    state.isFetching = true;
    state.error = null;
}

function handleFetchFailure(state, action) {
    state.isFetching = false;
    state.error = action.payload;
}

function handleFetchAllSuccess(state, action) {
    state.isFetching = false;
    state.funding = convertArrToObj(action.payload);
}

function handleFetchSingleSuccess(state, action) {
    state.isFetching = false;
    state.funding[action.payload.id] = action.payload;
}
