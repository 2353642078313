import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';

export const editProblemCaseStudyRequest = createAction('editProblemCaseStudyRequest');
export const editProblemCaseStudySuccess = createAction('editProblemCaseStudySuccess');
export const editProblemCaseStudyFailure = createAction('editProblemCaseStudyFailure');

export const editProblemCaseStudy = (id, postBody) => async dispatch => {
    dispatch(editProblemCaseStudyRequest());

    try {
        const { data } = await api.patch(`ProblemCaseStudies/${id}`, postBody);
        return dispatch(editProblemCaseStudySuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, editProblemCaseStudyFailure, e);
    }
};
