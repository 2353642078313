import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchNewsTags } from '@actions/news/index';
import { getNewsTags, getNewsError, getNewsIsFetching } from '@selectors/news';

const useFetchAllNewsTags = () => {
    const dispatch = useDispatch();
    const newsTags = useSelector(getNewsTags);
    const isFetching = useSelector(getNewsIsFetching);
    const error = useSelector(getNewsError);

    useEffect(() => {
        dispatch(fetchNewsTags());
    }, [dispatch]);

    function createSelectedOptionsList(newsTags) {
        const optionsList = newsTags.map(({ id, tag }) => {
            return { label: tag, value: id };
        });

        return optionsList;
    }

    const newsTagsOptions = createSelectedOptionsList(newsTags);

    return { newsTags, newsTagsOptions, isFetching, error };
};
export default useFetchAllNewsTags;
