import { useEffect, useState } from 'react';

const useShowMobileNavigation = () => {
    const [showMobileNavigation, setShowMobileNavigation] = useState(false);

    useEffect(() => {
        if (showMobileNavigation) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }

        return () => document.body.classList.remove('no-scroll');
    }, [showMobileNavigation]);

    return { showMobileNavigation, setShowMobileNavigation };
};

export default useShowMobileNavigation;
