import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getEventsError, getEventsIsFetching, getSingleEvent } from '@selectors/events';
import { fetchSingleEvent } from '@actions/events/index';

import usePrevious from 'lib/src/hooks/usePrevious';

const useSingleWebinarFetch = webinarID => {
    const dispatch = useDispatch();
    const history = useHistory();
    const webinar = useSelector(state => getSingleEvent(state, webinarID));
    const isFetching = useSelector(getEventsIsFetching);
    const error = useSelector(getEventsError);

    const prevProps = usePrevious({ error });

    useEffect(() => {
        dispatch(fetchSingleEvent(webinarID));
    }, [dispatch, webinarID]);

    useEffect(() => {
        if (!prevProps.error && error) {
            history.push('/resources');
        }
    }, [error, prevProps.error, history]);

    return [webinar, isFetching, error];
};

export default useSingleWebinarFetch;
