const OurMission = () => {
    return (
        <div className="our-mission-box-container fluid">
            <div className="container flex-column align-center">
                <h2 className="title">Our mission</h2>

                <p className="description">
                    Co-funded by Innovate UK Business Connect and the Faraday Battery Challenge, the
                    Cross-Sector Battery Systems (CSBS) Innovation Network aims to create an open
                    and collaborative cross-sectoral community for researchers and innovators in
                    battery manufacturing (including next-generation batteries), the related supply
                    chain and end-users. The community is helping to:
                </p>

                <div className="list-box">
                    <ul>
                        <li>
                            <strong>open new markets for the battery industry</strong>, by
                            introducing this community to new sectors, thus support the growth of
                            the UK economy;
                        </li>
                        <li>
                            <strong>promote innovation in batteries</strong> by identifying
                            technical gaps for their introduction to various sectors;
                        </li>
                        <li>
                            <strong>help decarbonise a wide range of end-users</strong> from rail,
                            maritime, aviation, construction etc. By enabling the adoption of
                            batteries thus supporting the Net Zero agenda.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default OurMission;
