import { metaData } from 'src/constants/meta';

import Banner from '@components/banner/Banner';
import Meta from '@components/meta/Meta';
import BannerImage from '@content/images/banners/events-list.png'; //<-- Not final image, will need changing

import ContactUsForm from './ContactUsForm';

const ContactUs = () => {
    return (
        <>
            <Meta data={metaData.contact} />
            <div id="contact-us">
                <div className="container">
                    <Banner title="Contact us" image={BannerImage} />
                </div>
                <div className="flex-row justify-center container page-margin-bottom">
                    <ContactUsForm />
                </div>
            </div>
        </>
    );
};
export default ContactUs;
