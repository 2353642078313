import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { caseStudyTypes } from 'lib/src/constants/enums';

import usePrevious from 'lib/src/hooks/usePrevious';

import {
    getCaseStudiesError,
    getCaseStudiesIsFetching,
    getSingleSolutionCaseStudy,
} from '@selectors/caseStudies';

import useCreateIntroductionRequest from '../modals/hooks/useCreateIntroductionRequest';

import DataCheck from '@components/dataCheck/DataCheck';
import NetworkingHubCaseStudyDetails from './NetworkingHubCaseStudyDetails';
import NetworkingHubIntroductionRequestModal from '../modals/NetworkingHubIntroductionRequestModal';
import ErrorModal from '@components/modals/ErrorModal';

const NetworkingHubSolutionSingle = () => {
    const [showIntroductionModal, setShowIntroductionModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);

    const { postError, handleSubmit } = useCreateIntroductionRequest(
        () => setShowIntroductionModal(false),
        () => setShowErrorModal(true),
        caseStudyTypes.solution,
    );

    const { id } = useParams();
    const history = useHistory();
    const solution = useSelector(state => getSingleSolutionCaseStudy(state, id));
    const isFetching = useSelector(getCaseStudiesIsFetching);
    const error = useSelector(getCaseStudiesError);

    const prevProps = usePrevious({ isFetching });

    useEffect(() => {
        if (!isFetching && prevProps.isFetching && !solution) {
            history.replace('/networking-hub/solutions');
        }
    }, [isFetching, prevProps.isFetching, solution, history]);

    return (
        <DataCheck data={solution} isFetching={isFetching} error={error}>
            <NetworkingHubCaseStudyDetails
                caseStudy={solution}
                urlPrefix="solutions"
                caseStudyType={caseStudyTypes.solution}
                setShowIntroductionModal={setShowIntroductionModal}
            />

            {showIntroductionModal && (
                <NetworkingHubIntroductionRequestModal
                    handleSubmit={handleSubmit}
                    closeModal={() => setShowIntroductionModal(false)}
                />
            )}

            {showErrorModal && (
                <ErrorModal
                    title="There was an error"
                    error={postError}
                    closeModal={() => setShowErrorModal(false)}
                />
            )}
        </DataCheck>
    );
};

export default NetworkingHubSolutionSingle;
