import CarouselContainer from '@components/carousel/CarouselContainer';
import { useEffect, useState } from 'react';
import foundingMembers from 'src/constants/foundingMembers';
import mediaQueries from 'src/constants/mediaQueries';
import useWindowSize from 'src/hooks/useWindowSize';

const FoundingMembersCarousel = () => {
    const { width } = useWindowSize();
    const [visibleSlides, setVisibleSlides] = useState(5);

    useEffect(() => {
        if (width < mediaQueries.ptTablet) {
            setVisibleSlides(2);
        } else if (width <= mediaQueries.lsTablet) {
            setVisibleSlides(4);
        } else {
            setVisibleSlides(5);
        }
    }, [width]);

    const renderItem = ({ item }) => {
        return (
            <div className="image-wrapper flex-row justify-center">
                <img src={item.image} alt={item.name} />
            </div>
        );
    };

    return (
        <CarouselContainer
            items={foundingMembers}
            renderItem={renderItem}
            extraContainerClass="founding-members-carousel"
            extraSliderClass="founding-members-slider"
            isIntrinsicHeight
            visibleSlides={visibleSlides}
        />
    );
};

export default FoundingMembersCarousel;
