import FoundingMembersCarousel from './FoundingMembersCarousel';

const FoundingMembers = () => {
    return (
        <div className="founding-members-container fluid">
            <div className="container flex-column align-center">
                <h2 className="title">Founding Members</h2>

                <FoundingMembersCarousel />
            </div>
        </div>
    );
};

export default FoundingMembers;
