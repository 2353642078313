import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';

// Login
export const fetchSingleFundingRequest = createAction('fetchSingleFundingRequest');
export const fetchSingleFundingSuccess = createAction('fetchSingleFundingSuccess');
export const fetchSingleFundingFailure = createAction('fetchSingleFundingFailure');

export const fetchSingleFunding = fundingID => async dispatch => {
    dispatch(fetchSingleFundingRequest());

    try {
        const { data } = await api.get(`funding/${fundingID}`);
        return dispatch(fetchSingleFundingSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchSingleFundingFailure, e);
    }
};
