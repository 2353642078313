import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';

export const fetchReportSingleRequest = createAction('fetchReportSingleRequest');
export const fetchReportSingleSuccess = createAction('fetchReportSingleSuccess');
export const fetchReportSingleFailure = createAction('fetchReportSingleFailure');

export const fetchReportSingle = reportID => async dispatch => {
    dispatch(fetchReportSingleRequest());

    try {
        const { data } = await api.get(`reports/${reportID}`);
        return dispatch(fetchReportSingleSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchReportSingleFailure, e);
    }
};
