import dayjs from 'dayjs';

import useWindowSize from 'src/hooks/useWindowSize';

import LinkButton from 'lib/src/components/button/LinkButton';
import { RAW_S3_STORAGE_URL } from 'src/config';
import { eventsDateTime } from 'src/constants/dateFormats';
import mediaQueries from 'src/constants/mediaQueries';

const EventListCard = ({ event }) => {
    const startDate = dayjs(event.startDate);
    const endDate = dayjs(event.endDate);
    const { width } = useWindowSize();
    const isMobile = width <= mediaQueries.lgMobile;

    return (
        <div className="event">
            <div className="image-wrapper">
                <img src={`${RAW_S3_STORAGE_URL}/${event.listImageS3Key}`} alt={`${event.title}`} />
                <LinkButton href={`/events/${event.id}`} color="purple block-hover">
                    Read more
                </LinkButton>
            </div>
            <div className="content wysiwyg">
                {event.isKTNEvent && <p className="white">KTN Innovation Networks Event</p>}
                <h3 style={{ marginTop: 0 }}>{event.title}</h3>
                <h4 style={{ marginTop: 0 }}>
                    {startDate.format(eventsDateTime)} -
                    <br />
                    {endDate.format(eventsDateTime)} GMT
                    <br />
                    {event.location}
                </h4>
                <p>{event.summary}</p>

                {isMobile && (
                    <LinkButton href={`/events/${event.id}`} color="purple block-hover">
                        Read more
                    </LinkButton>
                )}
            </div>
        </div>
    );
};

export default EventListCard;
