const ActionButton = ({
    children,
    type = 'submit',
    color = 'blue',
    icon,
    isPosting,
    disabled,
    onClick = () => {},
    extraClasses = '',
}) => (
    <button
        className={`button ${color} ${extraClasses}`}
        type={type}
        disabled={isPosting || disabled}
        onClick={onClick}
    >
        {icon && !isPosting && <i className={`icon far fa-fw fa-${icon}`}></i>}
        {isPosting && <i className="icon far fa-fw fa-spinner fa-spin"></i>}
        <span className="text">{children}</span>
    </button>
);

export default ActionButton;
