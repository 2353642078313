import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getFunding, getFundingError, getFundingIsFetching } from '@selectors/funding';
import { fetchFundingList } from '@actions/funding/index';

const useAllFundingFetch = () => {
    const dispatch = useDispatch();
    const funding = useSelector(getFunding);
    const isFetching = useSelector(getFundingIsFetching);
    const error = useSelector(getFundingError);

    useEffect(() => {
        dispatch(fetchFundingList());
    }, [dispatch]);

    return [funding, isFetching, error];
};

export default useAllFundingFetch;
