import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchNewsList } from '@actions/news/fetchNewsList';
import { getNews, getNewsError, getNewsIsFetching } from '@selectors/news';

const useAllNewsFetch = () => {
    const dispatch = useDispatch();
    const news = useSelector(getNews);
    const isFetching = useSelector(getNewsIsFetching);
    const error = useSelector(getNewsError);

    useEffect(() => {
        dispatch(fetchNewsList());
    }, [dispatch]);

    return [news, isFetching, error];
};

export default useAllNewsFetch;
