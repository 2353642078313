import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';

export const fetchSingleWebsiteCaseStudyRequest = createAction(
    'fetchSingleWebsiteCaseStudyRequest',
);
export const fetchSingleWebsiteCaseStudySuccess = createAction(
    'fetchSingleWebsiteCaseStudySuccess',
);
export const fetchSingleWebsiteCaseStudyFailure = createAction(
    'fetchSingleWebsiteCaseStudyFailure',
);

export const fetchSingleWebsiteCaseStudy = caseStudyID => async dispatch => {
    dispatch(fetchSingleWebsiteCaseStudyRequest());

    try {
        const { data } = await api.get(`WebsiteCaseStudies/${caseStudyID}`);

        return dispatch(fetchSingleWebsiteCaseStudySuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchSingleWebsiteCaseStudyFailure, e);
    }
};
