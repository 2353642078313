const Banner = ({ children, title, image, extraClasses = '', extraImageClasses = '' }) => (
    <div className={`page-banner flex-row flex-column-reverse-sd ${extraClasses}`}>
        <div className="content flex-column justify-between flex-4">
            <p className="sub-title">Charging the battery revolution network</p>
            {title ? <h1 className="title">{title}</h1> : children}
        </div>

        <div
            className={`image flex-8 flex-sd ${extraImageClasses}`}
            style={{ backgroundImage: `url(${image})` }}
        />
    </div>
);

export default Banner;
