import { useEffect } from 'react';
import { Route, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

import usePrevious from 'lib/src/hooks/usePrevious';

import SubRouter from './SubRouter';
import AuthRoutes from './AuthRoutes';
import EventsRoutes from './EventsRoutes';
import NewsRoutes from './NewsRoutes';
import FundingRoutes from './FundingRoutes';
import CrossSectoralOpportunitiesRoutes from './CrossSectoralOpportunitiesRoutes';
import ReportsRoutes from './ReportsRoutes';
import ResourcesRoutes from './ResourcesRoutes';
import NetworkingRoutes from './NetworkingRoutes';
import BatterySystemsLandscapeRoutes from './BatterySystemsLandscapeRoutes';
import ContactUsRoutes from './ContactUsRoutes';
import DashboardRoutes from './DashboardRoutes';

import Home from '@pages/home/Home';
import About from '@pages/about/About';
import PrivacyPolicy from '@pages/privacyPolicy/PrivacyPolicy';
import SwitchWith404 from './SwitchWith404';
import NotFound from '@pages/404/NotFound';

const Routes = () => {
    const location = useLocation();
    const prevProps = usePrevious({ location });

    useEffect(() => {
        const { pathname, search } = location;

        if (location !== prevProps.location && process.env.REACT_APP_ENVIRONMENT === 'production') {
            ReactGA.set({ page: pathname });
            ReactGA.pageview(pathname + search);
        }
    }, [location, prevProps.location]);

    return (
        <SwitchWith404>
            <Route exact path="/">
                <Home />
            </Route>
            <SubRouter path="/auth">
                <AuthRoutes prevLocation={prevProps.location} />
            </SubRouter>
            <Route exact path="/about">
                <About />
            </Route>
            <SubRouter path="/events">
                <EventsRoutes />
            </SubRouter>
            <SubRouter path="/news">
                <NewsRoutes />
            </SubRouter>
            <SubRouter path="/funding">
                <FundingRoutes />
            </SubRouter>
            <SubRouter path="/cross-sectoral-opportunities">
                <CrossSectoralOpportunitiesRoutes />
            </SubRouter>
            <SubRouter path="/reports">
                <ReportsRoutes />
            </SubRouter>
            <SubRouter path="/resources">
                <ResourcesRoutes />
            </SubRouter>
            <SubRouter path="/battery-systems-landscape-map">
                <BatterySystemsLandscapeRoutes />
            </SubRouter>
            <SubRouter path="/contact-us">
                <ContactUsRoutes />
            </SubRouter>
            <SubRouter path="/networking-hub" auth>
                <NetworkingRoutes />
            </SubRouter>
            <SubRouter path="/privacy-policy">
                <PrivacyPolicy />
            </SubRouter>
            <SubRouter path="/dashboard" auth>
                <DashboardRoutes />
            </SubRouter>

            <Route path="/page-not-found" component={NotFound} />
        </SwitchWith404>
    );
};

export default Routes;
