import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';

export const fetchWebsiteCaseStudiesListRequest = createAction(
    'fetchWebsiteCaseStudiesListRequest',
);
export const fetchWebsiteCaseStudiesListSuccess = createAction(
    'fetchWebsiteCaseStudiesListSuccess',
);
export const fetchWebsiteCaseStudiesListFailure = createAction(
    'fetchWebsiteCaseStudiesListFailure',
);

export const fetchWebsiteCaseStudiesList = () => async dispatch => {
    dispatch(fetchWebsiteCaseStudiesListRequest());

    try {
        const { data } = await api.get('WebsiteCaseStudies');

        return dispatch(fetchWebsiteCaseStudiesListSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchWebsiteCaseStudiesListFailure, e);
    }
};
