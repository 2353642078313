import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';

export const fetchRequestedConnectionsRequest = createAction('fetchRequestedConnectionsRequest');
export const fetchRequestedConnectionsSuccess = createAction('fetchRequestedConnectionsSuccess');
export const fetchRequestedConnectionsFailure = createAction('fetchRequestedConnectionsFailure');

export const fetchRequestedConnections = () => async dispatch => {
    dispatch(fetchRequestedConnectionsRequest());

    try {
        const { data } = await api.get('IntroductionRequests/requested');
        return dispatch(fetchRequestedConnectionsSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchRequestedConnectionsFailure, e);
    }
};
