import { metaData } from 'src/constants/meta';

import NewsListBanner from '@content/images/banners/news.png';

import ActionButton from 'lib/src/components/button/ActionButton';
import ButtonRow from 'lib/src/components/button/ButtonRow';

import useNewsToShow from './hooks/useNewsToShow';
import useAllNewsFetch from './hooks/useAllNewsFetch';

import Banner from '@components/banner/Banner';
import DataCheck from '@components/dataCheck/DataCheck';
import useShowMoreItems from 'src/hooks/useShowMoreItems';
import FilterSubHeading from '@components/filterSubHeading/FilterSubHeading';
import useFetchAllNewsTags from 'src/hooks/useFetchAllNewsTags';
import useForm from 'lib/src/hooks/useForm';
import NewsListCard from './NewsListCard';
import MultiSelect from 'lib/src/components/form/MultiSelect';
import TextArea from 'lib/src/components/form/TextArea';

import Meta from '@components/meta/Meta';
import FundingList from '@pages/news/funding/list/FundingList';
import HashLinkButton from '@components/button/HashLinkButton';

const News = () => {
    const [news, isFetching, error] = useAllNewsFetch();
    const { newsTagsOptions, isFetching: isFetchingTags, error: errorTags } = useFetchAllNewsTags();

    const [form, handleChange] = useForm({ selectedOptions: [], searchString: '' });

    const [amountToShow, showMoreItems] = useShowMoreItems(12, 12);
    const [newsToShow] = useNewsToShow(amountToShow, form);

    return (
        <>
            <Meta data={metaData.news} />

            <div className="container">
                <Banner title="News" image={NewsListBanner} />
            </div>
            <DataCheck data={newsTagsOptions} isFetching={isFetchingTags} error={errorTags}>
                <FilterSubHeading title="Latest News" extraClasses="justify-between">
                    <div className="filter-box-wrapper flex-row flex justify-between align-center flex-wrap-sd justify-around-sd flex-column-lm align-stretch-lm">
                        <div className="flex-column">
                            <MultiSelect
                                name="selectedOptions"
                                value={form.selectedOptions}
                                placeholder="Please select your filter tags"
                                options={newsTagsOptions}
                                onChange={handleChange}
                                extraClasses="grey filter-bar column"
                                formFieldClasses="no-margin"
                            />
                            <TextArea
                                name="searchString"
                                onChange={handleChange}
                                placeholder="Search by title or summary"
                                extraClasses="grey filter-bar-search no-margin"
                            />
                        </div>
                        <HashLinkButton
                            href="/news#funding"
                            extraClasses="funding-link normal-font filter-box-button"
                        >
                            See the latest funding opportunities
                        </HashLinkButton>
                    </div>
                </FilterSubHeading>
            </DataCheck>

            <div className="container page-margin-bottom">
                <DataCheck data={news} isFetching={isFetching} error={error}>
                    <div className="flex-row flex-wrap flex-column-lm">
                        {newsToShow.items.map(news => (
                            <NewsListCard key={news.id} item={news} />
                        ))}
                    </div>

                    {newsToShow.total > amountToShow && (
                        <ButtonRow>
                            <ActionButton color="grey" onClick={showMoreItems}>
                                Load more News Articles
                            </ActionButton>
                        </ButtonRow>
                    )}
                </DataCheck>
            </div>

            <FundingList />
        </>
    );
};

export default News;
