import { useCallback } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import useFieldValidation from '../../hooks/useFieldValidation';
import FormField from './FormField';

let API_KEY = '';

export const initWysiwyg = apiKey => {
    API_KEY = apiKey;
};

const WYSIWYG = ({
    name,
    value,
    onChange,
    label = '',
    type = 'text',
    required = false,
    validationRegExp,
    minLength,
    maxLength,
    minNumber,
    maxNumber,
    disabled,
    customValidate,
    isPublicFacing = false,
}) => {
    const memoizedValidate = useCallback(_validate, [
        minNumber,
        maxNumber,
        minLength,
        maxLength,
        validationRegExp,
        type,
    ]);
    const [error, showError] = useFieldValidation({
        name,
        required,
        value,
        customValidate,
        extendedValidate: memoizedValidate,
    });

    return (
        <FormField
            name={name}
            label={label}
            required={required}
            error={error}
            className="wysiwyg-field"
        >
            <Editor
                apiKey={API_KEY}
                value={value || ''}
                init={{
                    menubar: 'file edit',
                    plugins: isPublicFacing ? publicPlugins : privatePlugins,
                    toolbar: isPublicFacing ? publicToolbar : privateToolbar,
                }}
                onEditorChange={handleChange}
                disabled={disabled}
                onBlur={handleBlur}
            />
        </FormField>
    );

    function handleBlur() {
        showError();
    }

    function handleChange(value) {
        const newVal = value ? value : '';
        onChange(name, newVal);
    }

    function _validate(val) {
        if (!val) return;

        if (type === 'email' && !validateEmail(val)) {
            return 'Please provide a valid email.';
        }
        if (type === 'number' && minNumber && val < minNumber) {
            return `Value cannot be less than ${minNumber},`;
        }
        if (type === 'number' && maxNumber && val > maxNumber) {
            return `Value cannot be greater than ${maxNumber}.`;
        }
        if (type !== 'number' && minLength && val.length < minLength) {
            return `Value must have at least ${minLength} characters.`;
        }
        if (type !== 'number' && maxLength && val.length > maxLength) {
            return `Value cannot have more than ${maxLength} characters.`;
        }
        if (type !== 'number' && validationRegExp && !RegExp(validationRegExp).test(val)) {
            return 'Invalid value provided.';
        }
    }
};

const privatePlugins = 'link media';
const privateToolbar =
    'p h1 h2 h3 h4 h5 h6 | bold italic underline strikethrough blockquote | link media';
const publicPlugins = 'link';
const publicToolbar = 'p h1 h2 h3 h4 h5 h6 | bold italic underline strikethrough blockquote | link';

function validateEmail(val) {
    // eslint-disable-next-line
    const reg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return reg.test(val);
}

export default WYSIWYG;
