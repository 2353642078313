import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';

export const fetchInitiatedConnectionsRequest = createAction('fetchInitiatedConnectionsRequest');
export const fetchInitiatedConnectionsSuccess = createAction('fetchInitiatedConnectionsSuccess');
export const fetchInitiatedConnectionsFailure = createAction('fetchInitiatedConnectionsFailure');

export const fetchInitiatedConnections = () => async dispatch => {
    dispatch(fetchInitiatedConnectionsRequest());

    try {
        const { data } = await api.get('IntroductionRequests/initiated');
        return dispatch(fetchInitiatedConnectionsSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchInitiatedConnectionsFailure, e);
    }
};
