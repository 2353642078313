const CenterBox = () => {
    return (
        <div className="center-box-container fluid">
            <div className="container flex-column align-center">
                <h2 className="title">Welcome to the UK Batteries Network</h2>
                <p className="description">
                    Register to join a growing, open and collaborative community - the Cross-Sector
                    Battery Systems Innovation Network
                </p>

                <section className="info-box flex-column align-center">
                    <h3>Signing up today, you will:</h3>

                    <div className="flex-row justify-between flex-column-lt">
                        <div className="info-desc flex-row">
                            <i className="fas fa-check icon" />
                            <p>
                                Receive our newsletter including the latest news, exclusive invites
                                for members-only events, reports and funding updates
                            </p>
                        </div>

                        <div className="info-desc flex-row">
                            <i className="fas fa-check icon" />
                            <p>
                                Gain access to the networking hub of the platform where you will be
                                able to post and view battery-related solutions and challenges
                            </p>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default CenterBox;
