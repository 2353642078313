import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';

export const createIntroductionRequestRequest = createAction('createIntroductionRequestRequest');
export const createIntroductionRequestSuccess = createAction('createIntroductionRequestSuccess');
export const createIntroductionRequestFailure = createAction('createIntroductionRequestFailure');

export const createIntroductionRequest = postBody => async dispatch => {
    dispatch(createIntroductionRequestRequest());

    try {
        const { data } = await api.post('IntroductionRequests', postBody);
        return dispatch(createIntroductionRequestSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, createIntroductionRequestFailure, e);
    }
};
