import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';

export const fetchSingleProblemCaseStudyRequest = createAction(
    'fetchSingleProblemCaseStudyRequest',
);
export const fetchSingleProblemCaseStudySuccess = createAction(
    'fetchSingleProblemCaseStudySuccess',
);
export const fetchSingleProblemCaseStudyFailure = createAction(
    'fetchSingleProblemCaseStudyFailure',
);

export const fetchSingleProblemCaseStudy = caseStudyID => async dispatch => {
    dispatch(fetchSingleProblemCaseStudyRequest());

    try {
        const { data } = await api.get(`ProblemCaseStudies/${caseStudyID}`);

        return dispatch(fetchSingleProblemCaseStudySuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchSingleProblemCaseStudyFailure, e);
    }
};
