import { useDispatch, useSelector } from 'react-redux';

import { clearFormError, showFieldErrors } from '../../redux/actions/fieldErrors';
import { getFieldErrors, getFormError } from '../../redux/selectors/fieldErrors';
import ActionButton from '../../components/button/ActionButton';
import ButtonRow from '../../components/button/ButtonRow';
import { isEmpty } from '../../utils/generic';

const Form = ({
    children,
    onSubmit,
    onCancel,
    omitButtons = false,
    isPosting = false,
    extraClasses = '',
    submitButtonColor = 'blue',
}) => {
    const dispatch = useDispatch();
    const fieldErrors = useSelector(getFieldErrors);
    const formError = useSelector(getFormError);

    return (
        <form className={extraClasses} onSubmit={_handleSubmit}>
            {children}
            {!!formError && (
                <p className="form-generic-error" style={omitButtons ? { marginTop: '15px' } : {}}>
                    {formError}
                </p>
            )}
            {!omitButtons && (
                <ButtonRow alignment="right">
                    <ActionButton
                        color="grey"
                        type="button"
                        onClick={_handleCancel}
                        disabled={isPosting}
                    >
                        Cancel
                    </ActionButton>
                    <ActionButton isPosting={isPosting} color={submitButtonColor}>
                        Submit
                    </ActionButton>
                </ButtonRow>
            )}
        </form>
    );

    function _handleCancel(e) {
        e.preventDefault();

        if (!isPosting) onCancel();
    }

    function _handleSubmit(e) {
        e.preventDefault();

        if (!isEmpty(fieldErrors)) {
            dispatch(showFieldErrors());
        } else if (!isPosting) {
            dispatch(clearFormError());
            onSubmit();
        }
    }
};

export default Form;
