import { useHistory } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { validateGuid } from '@actions/auth/validateGuid';
import {
    getAuthError,
    getAuthIsPosting,
    getAuthPostSuccess,
    getValidateGuidIsFetching,
    getValidateGuidSuccess,
} from '@selectors/auth';

import useForm from 'lib/src/hooks/useForm';
import usePrevious from 'lib/src/hooks/usePrevious';
import { postResetPassword } from '@actions/auth/index';

export default function useResetPassword(guid) {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(validateGuid(guid));
    }, [dispatch, guid]);

    const guidIsFetching = useSelector(getValidateGuidIsFetching);
    const guidSuccess = useSelector(getValidateGuidSuccess);

    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const [form, handleChange] = useForm({ password: '' });

    const handleSubmit = () => {
        dispatch(postResetPassword(guid, form));
    };

    const isPosting = useSelector(getAuthIsPosting);
    const postSuccess = useSelector(getAuthPostSuccess);
    const error = useSelector(getAuthError);

    const prevPostSuccess = usePrevious(postSuccess);

    const validateConfirmPassword = useCallback(() => {
        if (form.password !== form.confirmPassword) {
            return 'Passwords do not match.';
        }
    }, [form.password, form.confirmPassword]);

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            setShowSuccessModal(true);
        }
    }, [postSuccess, prevPostSuccess, history]);

    return {
        form,
        handleChange,
        validateConfirmPassword,
        handleSubmit,
        error,
        isPosting,
        guidIsFetching,
        guidSuccess,
        showSuccessModal,
    };
}
