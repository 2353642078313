import { Route, useRouteMatch } from 'react-router-dom';
import SwitchWith404 from './SwitchWith404';

import NetworkingHub from '@pages/networkingHub/main/NetworkingHub';

const networkingHubPaths = ['', '/problems', '/problems/:id', '/solutions', '/solutions/:id'];

const NetworkingRoutes = () => {
    const { path } = useRouteMatch();

    return (
        <SwitchWith404>
            {networkingHubPaths.map(hubPath => (
                <Route key={path} exact path={`${path}${hubPath}`}>
                    <NetworkingHub />
                </Route>
            ))}
        </SwitchWith404>
    );
};

export default NetworkingRoutes;
