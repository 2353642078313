import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';

export const fetchWebinarsListRequest = createAction('fetchWebinarsListRequest');
export const fetchWebinarsListSuccess = createAction('fetchWebinarsListSuccess');
export const fetchWebinarsListFailure = createAction('fetchWebinarsListFailure');

export const fetchWebinarsList = () => async dispatch => {
    dispatch(fetchWebinarsListRequest());

    try {
        const { data } = await api.get('events/online');
        return dispatch(fetchWebinarsListSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchWebinarsListFailure, e);
    }
};
